import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { SigninManager } from "../../redux/actions";
import { AesEncrypt, AesDecrypt } from 'aes';
import { Success_Message, Error_Message } from "../../shared";
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import { Row, Col, Button, ListGroupItem } from 'react-bootstrap';

export const ManagerSignin = () => {

    let history = useHistory();
    let res = {};
    const [showLoaderDiv, setShowLoaderDiv] = useState("none");
    const [loaderVisible, setLoaderVisible] = useState(false);

    const [user_data, setUser] = useState({
        email: '',
        password: '',
    });

    const [submitted, setSubmitted] = useState(false);
    const dispatch = useDispatch();

    function handleChange(e) {
        const { name, value } = e.target;
        setUser(user_data => ({ ...user_data, [name]: value }));
    }

    async function handleSubmit(e) {
        e.preventDefault();
        
        setSubmitted(true);
        if ( user_data.email && user_data.password ) {
            setShowLoaderDiv("block"); setLoaderVisible(true);
            const user_data1 = AesEncrypt(user_data, 'where do you go when you by yourself' );
            res = await dispatch(SigninManager(user_data1));

            if(res.status === "success"){
                setShowLoaderDiv("none"); setLoaderVisible(false);
                localStorage.setItem("user", res.data.email);
                localStorage.setItem("token", res.data.auth_token);
                localStorage.setItem("role", res.data.role);
                localStorage.setItem("isAuth", true);

                Success_Message("Login successful") 
                // history.push('/manager_profile')
                window.location.href = '/manager_profile';
            }else{
                setShowLoaderDiv("none"); setLoaderVisible(false);
                Error_Message("invalid username or password")
            }
        }
    }

    // const Auth = useSelector((state) => state.Auth);
    // const { auth } = Auth;

    return (
        <div className="hold-transition login-page">
            <div className="login-box">
                <div className="login-logo">
                    {/* <a href="../../index2.html"><b>DISH</b></a> */}
                    <img src="images/logo.png" alt="DISH Logo" className="brand-image elevation-3" style={{height: "45px", width: "auto"}} />
                </div>
                {/* /.login-logo */}
                <div className="card">
                    <div className="card-body login-card-body">
                        {/* // ///////////////////////////////// LOADER /////////////////////////////////////// */}
                        <div style={{position: "fixed", height:"100%", width:"100%", display: showLoaderDiv, zIndex: "1500"}}>
                            <div style={{position: "fixed", top:"50%", left:"46%",backgroundColor: "#ffffffcf",padding:"15px", borderRadius:"20px" }}>
                                <Loader
                                    type="MutatingDots"
                                    color="#183287"
                                    secondaryColor="#ff9700"
                                    height={100}
                                    width={100}
                                    visible={loaderVisible}
                                    // timeout={3000} //3 secs
                                />
                                <img src="images/logo.png" alt="DISH Logo" className="brand-image elevation-3" style={{maxHeight: "40px", width: "auto", marginBottom: "20px"}} />
                                <h6 style={{color: "#183287"}}>Loading...</h6>
                            </div>
                        </div>
                        {/* // ///////////////////////////////// LOADER /////////////////////////////////////// */}
                        <h3>Manager Sign in</h3>
                        <p>Enter you username and password</p>
                        <form onSubmit={handleSubmit}>
                            <div className="input-group mb-3">
                                <input 
                                    type="email" 
                                    name="email" 
                                    placeholder="Email address" 
                                    value={user_data.email} 
                                    onChange={handleChange} 
                                    className={'form-control' + (submitted && !user_data.email ? ' is-invalid' : '')} 
                                />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                    <span className="fas fa-envelope" />
                                    </div>
                                </div>
                                {submitted && !user_data.email &&
                                    <div className="invalid-feedback">Email address is required</div>
                                }
                            </div>
                            <div className="input-group mb-3">
                                <input 
                                    type="password" 
                                    name="password" 
                                    placeholder="Password" 
                                    value={user_data.password} 
                                    onChange={handleChange} 
                                    className={'form-control' + (submitted && !user_data.password ? ' is-invalid' : '')} 
                                />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                    <span className="fas fa-lock" />
                                    </div>
                                </div>
                                {submitted && !user_data.password &&
                                    <div className="invalid-feedback">Password is required</div>
                                }
                            </div>
                            <br></br>
                            <div className="row">
                            {/* <div className="col-8">
                                <div className="icheck-primary">
                                <input type="checkbox" id="remember" />
                                <label htmlFor="remember">
                                    Remember Me
                                </label>
                                </div>
                            </div> */}
                            {/* /.col */}
                            <div className="col-12">
                                <button type="submit" className="btn btn-primary btn-block dish-btn-primary">Sign In</button>
                            </div>
                            {/* /.col */}
                            </div>
                        </form><br></br>
                        {/* <div className="social-auth-links text-center mb-3">
                            <p>- OR -</p>
                            <a href="#" className="btn btn-block btn-primary">
                            <i className="fab fa-facebook mr-2" /> Sign in using Facebook
                            </a>
                            <a href="#" className="btn btn-block btn-danger">
                            <i className="fab fa-google-plus mr-2" /> Sign in using Google+
                            </a>
                        </div> */}
                        {/* /.social-auth-links */}
                        <p className="mb-1">
                            <Row>
                                <Col xs={6}>
                                    <a href="https://lms.kiu.ac.ug/login/forgot_password.php" style={{color: "#ff9700"}}>I forgot my password</a>
                                </Col>
                            </Row>
                        </p>
                    </div>
                    {/* /.login-card-body */}
                </div>
                </div>

        </div>
    )
}
