import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetStatistics } from "../redux/actions";
import {Header} from '../includes/Header';
import {Menu} from '../includes/Menu';
import {Footer} from '../includes/Footer';
import Loader from "react-loader-spinner";

export const Dashboard = () => {
    const dispatch = useDispatch();
    const General = useSelector((state) => state.General);
    const { total_students }  = General;
    const { total_courses }   = General;
    const total_partners      = "12";
    const total_countries     = "6";

    const [showLoaderDiv, setShowLoaderDiv] = useState("none");
    const [loaderVisible, setLoaderVisible] = useState(false);
    let user_token = localStorage.getItem('token');

    useEffect(async () => {
        setShowLoaderDiv("block"); setLoaderVisible(true);
        await dispatch(GetStatistics(user_token));
        setShowLoaderDiv("none"); setLoaderVisible(false);
    }, []);

    return (
        <div className="wrapper">
            {/* // ///////////////////////////////// LOADER /////////////////////////////////////// */}
            <div style={{position: "fixed", height:"100%", width:"100%", display: showLoaderDiv, zIndex: "1500"}}>
                <div style={{position: "fixed", top:"50%", left:"46%",backgroundColor: "#ffffffcf",padding:"15px", borderRadius:"20px" }}>
                    <Loader
                        type="MutatingDots"
                        color="#183287"
                        secondaryColor="#ff9700"
                        height={100}
                        width={100}
                        visible={loaderVisible}
                        // timeout={3000} //3 secs
                    />
                    <img src="images/logo.png" alt="DISH Logo" className="brand-image elevation-3" style={{maxHeight: "40px", width: "auto", marginBottom: "20px"}} />
                    <h6 style={{color: "#183287"}}>Loading...</h6>
                </div>
            </div>
            {/* // ///////////////////////////////// LOADER /////////////////////////////////////// */}
            <Header/>
            <Menu/>
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper">
                    {/* Content Header (Page header) */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Dashboard</h1>
                                </div>{/* /.col */}
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item">
                                            <Link to="/dashboard" style={{color: "#ff9700"}}>Home</Link>
                                        </li>
                                        <li className="breadcrumb-item active">Dashboard</li>
                                    </ol>
                                </div>{/* /.col */}
                            </div>{/* /.row */}
                        </div>{/* /.container-fluid */}
                    </div>
                    {/* /.content-header */}
                    {/* Main content */}
                    <section className="content">
                        <div className="container-fluid">
                            {/* Small boxes (Stat box) */}
                            <div className="row">
                                <div className="col-lg-3 col-6">
                                    {/* small box */}
                                    <div className="small-box bg-info">
                                        <div className="inner">
                                            <h3>{total_students}</h3>
                                            <p>Students</p>
                                        </div>
                                        <div className="icon">
                                            <i className="ion ion-person" />
                                        </div>
                                        <a className="small-box-footer">.</a>
                                    </div>
                                </div>
                                {/* ./col */}
                                <div className="col-lg-3 col-6">
                                    {/* small box */}
                                    <div className="small-box bg-success">
                                        <div className="inner">
                                            <h3>{total_courses}</h3>
                                            <p>Courses</p>
                                        </div>
                                        <div className="icon">
                                            <i className="ion ion-filing" />
                                        </div>
                                        <a href="https://codeesa.kiu.ac.ug/dish.php" target= "_blank" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                                    </div>
                                </div>
                                {/* ./col */}
                                <div className="col-lg-3 col-6">
                                    {/* small box */}
                                    <div className="small-box bg-warning">
                                        <div className="inner">
                                            <h3>{total_partners}</h3>
                                            <p>Partners</p>
                                        </div>
                                        <div className="icon">
                                            <i className="ion ion-person-stalker" />
                                        </div>
                                        <a href="https://codeesa.kiu.ac.ug/dish.php" target= "_blank" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                                    </div>
                                </div>
                                {/* ./col */}
                                <div className="col-lg-3 col-6">
                                    {/* small box */}
                                    <div className="small-box bg-danger">
                                        <div className="inner">
                                            <h3>{total_countries}</h3>
                                            <p>Countries</p>
                                        </div>
                                        <div className="icon">
                                            <i className="ion ion-earth" />
                                        </div>
                                        <a href="https://codeesa.kiu.ac.ug/dish.php" target= "_blank" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                                    </div>
                                </div>
                                {/* ./col */}
                            </div>

                            <div>
                                <div className="row" style={{marginTop: "100px"}}>
                                    <h2>Partners</h2>
                                </div>
                                <div className="row" style={{marginTop: "50px", marginBottom: "50px", textAlign: "center"}}>
                                    <div className="col-md-3">
                                        <img src="images/logos/nuffic.png" style={{width: "auto", height: "65px"}} />
                                    </div>
                                    <div className="col-md-3">
                                        <img src="images/logos/SORD.png" style={{width: "auto", height: "65px"}} />
                                    </div>
                                    <div className="col-md-3">
                                        <img src="images/logos/kiu.png" style={{width: "auto", height: "65px"}} />
                                    </div>
                                    <div className="col-md-3">
                                        <img src="images/logos/tilburg-university-logo.png" style={{width: "auto", height: "60px"}} />
                                    </div>
                                </div>
                                <div className="row" style={{marginTop: "100px" ,marginBottom: "50px", textAlign: "center"}}>
                                    <div className="col-md-3">
                                        <img src="images/logos/action-africa-help.png" style={{width: "auto", height: "75px"}} />
                                    </div>
                                    <div className="col-md-3">
                                        <img src="images/logos/eastern-college.png" style={{width: "auto", height: "90px"}} />
                                    </div>
                                    <div className="col-md-3">
                                        <img src="images/logos/cepo.png" style={{width: "auto", height: "90px"}} />
                                    </div>
                                    <div className="col-md-3">
                                        <img src="images/logos/Mekelle University.png" style={{width: "auto", height: "90px"}} />
                                    </div>
                                </div>
                                <div className="row" style={{paddingBottom: "150px", textAlign: "center"}}>
                                    <div className="col-md-3">
                                        <img src="images/logos/Addis.png" style={{width: "auto", height: "90px"}} />
                                    </div>
                                    <div className="col-md-3">
                                        <img src="images/logos/aksum.png" style={{width: "auto", height: "90px"}} />
                                    </div>
                                    <div className="col-md-3">
                                        <img src="images/logos/admas.png" style={{width: "auto", height: "90px"}} />
                                    </div>
                                    <div className="col-md-3">
                                        <img src="images/logos/east-africa-university.png" style={{width: "auto", height: "90px"}} />
                                    </div>
                                </div>
                            </div>

                            {/* /.row */}
                        </div>{/* /.container-fluid */}
                    </section>
                    {/* /.content */}
                </div>
                {/* /.content-wrapper */}
            
            <Footer/>
        </div>
    )
}

