import { ADMIN_DETAILS, GET_MANAGERS, UPDATE_MANAGER_DETAILS, GET_STUDENTS, GET_ALL_STUDENTS_DETAILS, GET_ALL_COURSE_DETAILS, GET_ALL_STUDENT_DETAILS, UPDATE_STUDENT_DETAILS } from "../ActionTypes";

const INIT_VALUES = {
    admin: [],
    managers: [],
    students: [],
    from: "",
    to: "",
    total: "",
    prev_page: "",
    next_page: "",
    all_students: [],
    student_details: [],
    all_courses: []
};

export const AdminReducer = (state = INIT_VALUES, action) => {
    
    switch (action.type) {
        case ADMIN_DETAILS:
            return { ...state, admin: action.payload.data };

        case GET_MANAGERS:
            return { 
                ...state, 
                managers: action.payload.manager_data, 
                from: action.payload.from,
                to: action.payload.to, 
                total: action.payload.total, 
                prev_page: action.payload.prev_page_url, 
                next_page: action.payload.next_page_url, 
            };
            
        case GET_STUDENTS:
            return { 
                ...state, 
                students: action.payload.student_data, 
                from: action.payload.from,
                to: action.payload.to, 
                total: action.payload.total, 
                prev_page: action.payload.prev_page_url, 
                next_page: action.payload.next_page_url, 
            };

        case GET_ALL_STUDENTS_DETAILS:
            return { 
                ...state, 
                all_students: action.payload.data, 
            };

        case GET_ALL_COURSE_DETAILS:
            return { 
                ...state, 
                all_courses: action.payload.data, 
            };

        case GET_ALL_STUDENT_DETAILS:
            return { 
                ...state, 
                student_details: action.payload, 
            };

        case UPDATE_STUDENT_DETAILS:
            return {
                ...state,
                students: action.payload.student_data, 
            }  
        
        
        default:
            return state;
    }
}


