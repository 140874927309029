export const TodoReducer = (state = { todos: [] }, action) => {
    switch (action.type) {
        case "ADD_TODO":
            return { todos: action.payload };

        case "REMOVE_TODO":
            return { todos: action.payload };

        default:
            return state;
    }
}

// const saveNotes = () => async (dispatch, getState) => {
//     const notes = getState().notes;
//     await fetch("http://localhost:4000/notes", {
//         method: "POST",
//         headers: {
//             'Accept': 'application/json',
//             'Content-type': 'application/json'
//         },
//         body: JSON.stringify(notes)
//     })
//     alert("Success")
// }

