import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetUsersDetails, GetCountries, UpdateDetails } from "../../redux/actions";
import {Header} from '../../includes/Header';
import {Menu} from '../../includes/Menu';
import {Footer} from '../../includes/Footer';
import { AesEncrypt, AesDecrypt } from 'aes';
import { Success_Message, Error_Message } from "../../shared";
import { UploadProfilePicture, UploadFile } from "../../components";
import ReactInputDateMask from 'react-input-date-mask';
import { Row, Col, Button, ListGroupItem } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";

export const Profile = () => {
    let history = useHistory();
    if(localStorage.getItem('role') != "student"){
        history.goBack();
    }
    
    let user_email = localStorage.getItem('user');
    let user_token = localStorage.getItem('token');
    let user_role  = localStorage.getItem('role');
    let res = {};
    let userDetails = [];
    const dispatch = useDispatch();
    const [showLoaderDiv, setShowLoaderDiv] = useState("none");
    const [loaderVisible, setLoaderVisible] = useState(false);

    // get details and pass into user
    // const Student   = useSelector((state) => state.Student);
    // const { user }  = Student;
    const General = useSelector((state) => state.General);
    const { countries }  = General;

    const [user_data, setUser] = useState([]);

    useEffect(async () => {
        setShowLoaderDiv("block"); setLoaderVisible(true);
        // get details
        userDetails = await dispatch(GetUsersDetails(user_email, user_token, user_role));
        await dispatch(GetCountries());
        setUser(userDetails.data.data)
        setShowLoaderDiv("none"); setLoaderVisible(false);
    }, []);

    // /////////////////////////////////////////////////////////
    function trigerProfileUpload(profile_picture){
        document.getElementById("profile_picture").click()
    }
    // /////////////////////////////////////////////////////

    const handleChange = async (e) => {
        const { name, value } = e.target;
        setUser(user_data => ({ ...user_data, [name]: value }));
    }

    const handleDateChange = async (e) => {
        setUser(user_data => ({ ...user_data, "dob": e }));
    }

    const submitPersonalDetails = async (e) => {
        e.preventDefault();
        if (
            user_data.first_name == "" || user_data.last_name == "" || user_data.zip_code == "select" || user_data.telephone == "" || user_data.gender == "" || user_data.dob == "" || user_data.nationality == "" || user_data.country_of_residence == "" || user_data.district_province_state == "" || user_data.address == "" ||
            user_data.first_name == null || user_data.last_name == null || user_data.zip_code == null || user_data.telephone == null || user_data.gender == null || user_data.dob == null || user_data.nationality == null || user_data.country_of_residence == null || user_data.district_province_state == null || user_data.address == null) 
        {
            Error_Message("Please fill all required fields")
        }else{
            setShowLoaderDiv("block"); setLoaderVisible(true);
            res = await dispatch(UpdateDetails(user_data, user_email, user_token, user_role));
            setShowLoaderDiv("none"); setLoaderVisible(false);
            alertMessage(res);
        }
    }

    const submitIdentity = async (e) => {
        e.preventDefault();
        
        if (user_data.type_of_identification == "select" || user_data.id_passport_number == "" ||
            user_data.type_of_identification == null || user_data.id_passport_number == null) 
        {
            Error_Message("Please fill all required fields")
        }else{
            setShowLoaderDiv("block"); setLoaderVisible(true);
            res = await dispatch(UpdateDetails(user_data, user_email, user_token, user_role));
            setShowLoaderDiv("none"); setLoaderVisible(false);
            alertMessage(res)
        }
    }

    const submitNextKin = async (e) => {
        e.preventDefault();
        
        if (
            user_data.next_kin_name == "" || user_data.next_kin_relationship == "" || user_data.next_kin_occupation == "" || user_data.next_kin_zip_code == "" || user_data.next_kin_phone == "" || user_data.next_kin_email == "" ||
            user_data.next_kin_name == null || user_data.next_kin_relationship == null || user_data.next_kin_occupation == null || user_data.next_kin_zip_code == null || user_data.next_kin_phone == null || user_data.next_kin_email == null) 
        {
            Error_Message("Please fill all required fields")
        }else{
            setShowLoaderDiv("block"); setLoaderVisible(true);
            res = await dispatch(UpdateDetails(user_data, user_email, user_token, user_role));
            setShowLoaderDiv("none"); setLoaderVisible(false);
            alertMessage(res)
        }
    }

    const alertMessage = async (res) => {
        if(res.status === "success"){ 
            Success_Message("Update successful") 
        }else{
            Error_Message(res.data)
        }
    }

    return (
        <div className="wrapper">
            <Header/>
            <Menu/>
                <div className="content-wrapper">
                    {/* // ///////////////////////////////// LOADER /////////////////////////////////////// */}
                    <div style={{position: "fixed", height:"100%", width:"100%", display: showLoaderDiv, zIndex: "1500"}}>
                        <div style={{position: "fixed", top:"50%", left:"46%",backgroundColor: "#ffffffcf",padding:"15px", borderRadius:"20px" }}>
                            <Loader
                                type="MutatingDots"
                                color="#183287"
                                secondaryColor="#ff9700"
                                height={100}
                                width={100}
                                visible={loaderVisible}
                                // timeout={3000} //3 secs
                            />
                            <img src="images/logo.png" alt="DISH Logo" className="brand-image elevation-3" style={{maxHeight: "40px", width: "auto", marginBottom: "20px"}} />
                            <h6 style={{color: "#183287"}}>Loading...</h6>
                        </div>
                    </div>
                    {/* // ///////////////////////////////// LOADER /////////////////////////////////////// */}
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1>Profile</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard" style={{color: "#ff9700"}}>Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active">User Profile</li>
                                    </ol>
                                </div>
                            </div>
                        </div>{/* /.container-fluid */}
                    </section>

                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                            <div className="col-md-3">
                                {/* Profile Image */}
                                <div className="card card-primary card-outline">
                                    
                                    <div className="card-body box-profile">
                                        <div className="text-center">
                                            <UploadProfilePicture profile_pic={user_data.profile_picture} />
                                        </div>
                                        <h3 className="profile-username text-center">{user_data.username}</h3>
                                        <p className="text-muted text-center">{user_data.first_name} {user_data.last_name}</p>
                                        <p className="text-center">{user_data.email}</p>
                                    </div>
                                    {/* /.card-body */}
                                    <ListGroupItem className="px-4">
                                        <Button block outline 
                                            color="success"
                                            onClick={() => trigerProfileUpload("profile_picture")}
                                            className="dish-btn-secondary"
                                        >Update Profile Photo</Button>
                                    </ListGroupItem>
                                </div>
                                
                            </div>
                            {/* /.col */}
                            <div className="col-md-9">
                                <div className="card">

                                    <div className="card-header p-2">
                                        <ul className="nav nav-pills">
                                            <li className="nav-item"><a className="nav-link active" href="#personal_details" data-toggle="tab">Personal Details</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#identity_tab" data-toggle="tab">Identity</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#next_kin" data-toggle="tab">Next of Kin</a></li>
                                        </ul>
                                    </div>{/* /.card-header */}

                                    <div className="card-body">
                                        <div className="tab-content">
                                            <div className="active tab-pane" id="personal_details">
                                                <form onSubmit={submitPersonalDetails}>

                                                    <Row style={{marginBottom: "25px"}}>
                                                        <Col md={5} xs={12}>
                                                            {/* First name */}
                                                            <div className="form-group row">
                                                                <div className="input-group input-group-sm">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">
                                                                        <span className="asterisk">*&nbsp;</span>
                                                                         First Name
                                                                        </span>
                                                                    </div>
                                                                    <input 
                                                                        type="text" 
                                                                        name="first_name" 
                                                                        className="form-control"
                                                                        defaultValue={user_data.first_name}
                                                                        onChange={handleChange} 
                                                                    />
                                                                    <div className="input-group-append">
                                                                        <div className="input-group-text"><i className="fas fa-user" /></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* Last name */}
                                                            <div className="form-group row">
                                                                <div className="input-group input-group-sm">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">
                                                                        <span className="asterisk">*&nbsp;</span>
                                                                        Last Name
                                                                        </span>
                                                                    </div>
                                                                    <input 
                                                                        type="text" 
                                                                        name="last_name" 
                                                                        className="form-control"
                                                                        defaultValue={user_data.last_name}
                                                                        onChange={handleChange} 
                                                                    />
                                                                    <div className="input-group-append">
                                                                        <div className="input-group-text"><i className="fas fa-user" /></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* Middle name */}
                                                            <div className="form-group row">
                                                                <div className="input-group input-group-sm">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">
                                                                        Middle Name
                                                                        </span>
                                                                    </div>
                                                                    <input 
                                                                        type="text" 
                                                                        name="middle_name" 
                                                                        className="form-control"
                                                                        defaultValue={user_data.middle_name}
                                                                        onChange={handleChange} 
                                                                    />
                                                                    <div className="input-group-append">
                                                                        <div className="input-group-text"><i className="fas fa-user" /></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* Phone number */}
                                                            <div className="form-group row">
                                                                <div className="input-group input-group-sm">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">
                                                                        <span className="asterisk">*&nbsp;</span>
                                                                        Phone Number
                                                                        </span>
                                                                    </div>
                                                                    <select className="form-control" value={user_data.zip_code} name="zip_code" onChange={handleChange} >
                                                                        <option>Select</option>
                                                                        {countries && countries.map((country) => (
                                                                            <option 
                                                                                key={country.id}
                                                                                value={country.zip_code}
                                                                            >{country.code} {country.zip_code}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    <input 
                                                                        type="text" 
                                                                        name="telephone" 
                                                                        className="form-control"
                                                                        defaultValue={user_data.telephone}
                                                                        onChange={handleChange} 
                                                                    />
                                                                    <div className="input-group-append">
                                                                        <div className="input-group-text"><i className="fas fa-user" /></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* Gender */}
                                                            <div className="form-group row">
                                                                <div className="input-group input-group-sm">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">
                                                                        <span className="asterisk">*&nbsp;</span>
                                                                        Gender
                                                                        </span>
                                                                    </div>
                                                                    <select className="form-control" name="gender" value={user_data.gender} onChange={handleChange}>
                                                                        <option value="select" >-- Select --</option>
                                                                        <option value="male" >Male</option>
                                                                        <option value="female" >Female</option>
                                                                        <option value="prefer_not_to_say">Prefer not to say</option>
                                                                        <option value="others" >Others</option>
                                                                    </select>
                                                                    <div className="input-group-append">
                                                                        <div className="input-group-text"><i className="fas fa-user" /></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* Date of Birth */}
                                                            <div className="form-group row">
                                                                <div className="input-group input-group-sm">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">
                                                                        <span className="asterisk">*&nbsp;</span>
                                                                        Date of Birth
                                                                        </span>
                                                                    </div>
                                                                    <ReactInputDateMask  
                                                                        mask='dd/mm/yyyy' 
                                                                        showMaskOnFocus={true}  
                                                                        className="form-control"
                                                                        name="dob" 
                                                                        value={user_data.dob} 
                                                                        onChange={handleDateChange} 
                                                                        showMaskOnHover={true} 
                                                                    />
                                                                    <div className="input-group-append">
                                                                        <div className="input-group-text"><i className="fas fa-user" /></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* Nationality */}
                                                            <div className="form-group row">
                                                                <div className="input-group input-group-sm">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">
                                                                        <span className="asterisk">*&nbsp;</span>
                                                                        Nationality
                                                                        </span>
                                                                    </div>
                                                                    <select className="form-control" value={user_data.nationality} name="nationality" onChange={handleChange} >
                                                                        <option>Select</option>
                                                                        {countries && countries.map((country) => (
                                                                            <option 
                                                                                key={country.id}
                                                                                value={country.name}
                                                                            >{country.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    <div className="input-group-append">
                                                                        <div className="input-group-text"><i className="fas fa-user" /></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </Col>
                                                        <Col xs={1}></Col>
                                                        <Col md={5} xs={12}>
                                                            {/* Country of Residence */}
                                                            <div className="form-group row">
                                                                <div className="input-group input-group-sm">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">
                                                                        <span className="asterisk">*&nbsp;</span>
                                                                        Country of Residence
                                                                        </span>
                                                                    </div>
                                                                    <select className="form-control" name="country_of_residence" value={user_data.country_of_residence} onChange={handleChange}>
                                                                        <option>Select</option>
                                                                        {countries && countries.map((country) => (
                                                                            <option 
                                                                                key={country.id}
                                                                                value={country.name}
                                                                            >{country.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    <div className="input-group-append">
                                                                        <div className="input-group-text"><i className="fas fa-user" /></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* District Province State */}
                                                            <div className="form-group row">
                                                                <div className="input-group input-group-sm">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">
                                                                        <span className="asterisk">*&nbsp;</span>
                                                                        District Province State
                                                                        </span>
                                                                    </div>
                                                                    <input 
                                                                        type="text" 
                                                                        name="district_province_state"
                                                                        className="form-control"
                                                                        defaultValue={user_data.district_province_state}
                                                                        onChange={handleChange} 
                                                                    />
                                                                    <div className="input-group-append">
                                                                        <div className="input-group-text"><i className="fas fa-user" /></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        
                                                            {/* Address */}
                                                            <div className="form-group row">
                                                                <div className="input-group input-group-sm">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">
                                                                        <span className="asterisk">*&nbsp;</span>
                                                                        Address
                                                                        </span>
                                                                    </div>
                                                                    <textarea 
                                                                        className="form-control" 
                                                                        rows={3} 
                                                                        name="address"
                                                                        value={user_data.address}
                                                                        onChange={handleChange} 
                                                                        placeholder="Enter ..." 
                                                                        // defaultValue={""} 
                                                                    />
                                                                    <div className="input-group-append">
                                                                        <div className="input-group-text"><i className="fas fa-user" /></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* Height */}
                                                            <div className="form-group row">
                                                                <div className="input-group input-group-sm">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">
                                                                        Height
                                                                        </span>
                                                                    </div>
                                                                    <input 
                                                                        type="text" 
                                                                        name="height"
                                                                        className="form-control"
                                                                        value={user_data.height}
                                                                        onChange={handleChange} 
                                                                    />
                                                                    <div className="input-group-append">
                                                                        <div className="input-group-text"><i className="fas fa-user" /></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* Weight */}
                                                            <div className="form-group row">
                                                                <div className="input-group input-group-sm">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">
                                                                        Weight
                                                                        </span>
                                                                    </div>
                                                                    <input 
                                                                        type="text" 
                                                                        name="weight"
                                                                        className="form-control"
                                                                        value={user_data.weight}
                                                                        onChange={handleChange} 
                                                                    />
                                                                    <div className="input-group-append">
                                                                        <div className="input-group-text"><i className="fas fa-user" /></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* Disabilities */}
                                                            {/* <label>Disabilities</label>
                                                            <div className="form-group row">
                                                                <div className="input-group input-group-sm">
                                                                    <Row>
                                                                        <Col style={{marginLeft: "15px"}}>
                                                                            <div className="form-check">
                                                                                <input 
                                                                                    className="form-check-input" type="checkbox" 
                                                                                    name="disability_none"
                                                                                    value={user_data.disability_none}
                                                                                    onChange={handleChange} 
                                                                                />
                                                                                <label className="form-check-label">None</label>
                                                                            </div>
                                                                            <div className="form-check">
                                                                                <input 
                                                                                    className="form-check-input" type="checkbox" 
                                                                                    name="disability_hearing"
                                                                                    value={user_data.disability_hearing}
                                                                                    onChange={handleChange} 
                                                                                />
                                                                                <label className="form-check-label">Hearing</label>
                                                                            </div>
                                                                            <div className="form-check">
                                                                                <input 
                                                                                    className="form-check-input" type="checkbox" 
                                                                                    name="disability_mobility"
                                                                                    value={user_data.disability_mobility}
                                                                                    onChange={handleChange} 
                                                                                />
                                                                                <label className="form-check-label">Mobility</label>
                                                                            </div>
                                                                        </Col>
                                                                        <Col style={{marginLeft: "15px"}}>
                                                                            <div className="form-check">
                                                                                <input 
                                                                                    className="form-check-input" type="checkbox" 
                                                                                    name="disability_sight"
                                                                                    value={user_data.disability_sight}
                                                                                    onChange={handleChange} 
                                                                                />
                                                                                <label className="form-check-label">Sight</label>
                                                                            </div>
                                                                            <div className="form-check">
                                                                                <input 
                                                                                    className="form-check-input" type="checkbox" 
                                                                                    name="disability_learning"
                                                                                    value={user_data.disability_learning}
                                                                                    onChange={handleChange} 
                                                                                />
                                                                                <label className="form-check-label">Learning</label>
                                                                            </div>
                                                                            <div className="form-check">
                                                                                <input 
                                                                                    className="form-check-input" type="checkbox" 
                                                                                    name="disability_others"
                                                                                    value={user_data.disability_others}
                                                                                    onChange={handleChange} 
                                                                                />
                                                                                <label className="form-check-label">Others</label>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </div> */}

                                                        </Col>
                                                    </Row>
                                                    <button type="submit" class="btn btn-block bg-gradient-success btn-sm dish-btn-primary">Update Personal Details</button>
                                                </form>
                                            </div>
                                            {/* /Identity tab-pane */}
                                            <div className="tab-pane" id="identity_tab">
                                                <form onSubmit={submitIdentity}>
                                                    <Row style={{marginBottom: "25px"}}>
                                                        <Col xs={1}></Col>
                                                        <Col xs={10}>
                                                            <Col xs={12}>
                                                                {/* Type of Identification */}
                                                                <div className="form-group row">
                                                                    <div className="input-group input-group-sm">
                                                                        <div className="input-group-prepend">
                                                                            <span className="input-group-text">
                                                                            <span className="asterisk">*&nbsp;</span>
                                                                            Type of Identification
                                                                            </span>
                                                                        </div>
                                                                        <select className="form-control" name="type_of_identification" value={user_data.type_of_identification} onChange={handleChange}>
                                                                            <option value="select" >Select</option>
                                                                            <option value="passport" >Passport</option>
                                                                            <option value="national_id" >National ID</option>
                                                                            <option value="driver_license" >Driver License</option>
                                                                        </select>
                                                                        <div className="input-group-append">
                                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12}>
                                                                {/* ID Passport Number */}
                                                                <div className="form-group row">
                                                                    <div className="input-group input-group-sm">
                                                                        <div className="input-group-prepend">
                                                                            <span className="input-group-text">
                                                                            <span className="asterisk">*&nbsp;</span>
                                                                            ID Passport Number
                                                                            </span>
                                                                        </div>
                                                                        <input 
                                                                            type="text" 
                                                                            name="id_passport_number" 
                                                                            className="form-control"
                                                                            value={user_data.id_passport_number}
                                                                            onChange={handleChange} 
                                                                        />
                                                                        <div className="input-group-append">
                                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12}>
                                                                {/* ID Upload */}
                                                                <p style={{fontWeight: "700", fontSize: "14px"}}>
                                                                    Upload passport, national ID or Driver license.<br></br>
                                                                    <span style={{fontWeight: "400", fontSize: "12px"}}>
                                                                        File size should not be more than 500KB.
                                                                    </span>
                                                                </p>
                                                            </Col>
                                                            <Col xs={12}>
                                                                <UploadFile file_key={"id_passport_upload"} />
                                                                {/* <div className="form-group"> */}
                                                                    {/* <label for="customFile">Custom File</label> */}
                                                                    {/* <div className="custom-file input-group-sm">
                                                                        <input 
                                                                            type="file" 
                                                                            className="custom-file-input" id="customFile" 
                                                                            // onChange={e => { onChangeProfilePicture(e) }}
                                                                        />
                                                                        <label className="custom-file-label" htmlFor="customFile">Choose file</label>
                                                                    </div> */}
                                                                {/* </div> */}
                                                            </Col>
                                                        </Col>
                                                        <Col xs={1}></Col>
                                                    </Row>
                                                    <button type="submit" class="btn btn-block bg-gradient-success btn-sm dish-btn-primary">Update Identity</button>
                                                </form>
                                            </div>
                                            {/* /Next of Kin tab-pane */}
                                            <div className="tab-pane" id="next_kin">
                                                <form onSubmit={submitNextKin}>
                                                    <Row style={{marginBottom: "25px"}}>
                                                        <Col xs={1}></Col>
                                                        <Col xs={10}>
                                                            {/* Next of Kin Name */}
                                                            <div className="form-group row">
                                                                <div className="input-group input-group-sm">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">
                                                                        <span className="asterisk">*&nbsp;</span>
                                                                        Next of Kin Name
                                                                        </span>
                                                                    </div>
                                                                    <input 
                                                                        type="text" 
                                                                        name="next_kin_name" 
                                                                        className="form-control"
                                                                        value={user_data.next_kin_name}
                                                                        onChange={handleChange} 
                                                                    />
                                                                    <div className="input-group-append">
                                                                        <div className="input-group-text"><i className="fas fa-user" /></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* Next of Kin Relationship */}
                                                            <div className="form-group row">
                                                                <div className="input-group input-group-sm">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">
                                                                        <span className="asterisk">*&nbsp;</span>
                                                                        Next of Kin Relationship
                                                                        </span>
                                                                    </div>
                                                                    <input 
                                                                        type="text" 
                                                                        name="next_kin_relationship" 
                                                                        className="form-control"
                                                                        value={user_data.next_kin_relationship}
                                                                        onChange={handleChange} 
                                                                    />
                                                                    <div className="input-group-append">
                                                                        <div className="input-group-text"><i className="fas fa-user" /></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* Next of Kin Occupation */}
                                                            <div className="form-group row">
                                                                <div className="input-group input-group-sm">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">
                                                                        Next of Kin Occupation
                                                                        </span>
                                                                    </div>
                                                                    <input 
                                                                        type="text" 
                                                                        name="next_kin_occupation" 
                                                                        className="form-control"
                                                                        value={user_data.next_kin_occupation}
                                                                        onChange={handleChange} 
                                                                    />
                                                                    <div className="input-group-append">
                                                                        <div className="input-group-text"><i className="fas fa-user" /></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* Next of Kin Phone Number */}
                                                            <div className="form-group row">
                                                                <div className="input-group input-group-sm">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">
                                                                        <span className="asterisk">*&nbsp;</span>
                                                                        Next of Kin Phone Number
                                                                        </span>
                                                                    </div>
                                                                    <select className="form-control" value={user_data.next_kin_zip_code} name="next_kin_zip_code" onChange={handleChange} >
                                                                        <option>Select</option>
                                                                        {countries && countries.map((country) => (
                                                                            <option 
                                                                                key={country.id}
                                                                                value={country.zip_code}
                                                                            >{country.code} {country.zip_code}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    <input 
                                                                        type="text" 
                                                                        name="next_kin_phone" 
                                                                        className="form-control"
                                                                        value={user_data.next_kin_phone}
                                                                        onChange={handleChange} 
                                                                    />
                                                                    <div className="input-group-append">
                                                                        <div className="input-group-text"><i className="fas fa-user" /></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* Next of Kin Email */}
                                                            <div className="form-group row">
                                                                <div className="input-group input-group-sm">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">
                                                                        <span className="asterisk">*&nbsp;</span>
                                                                        Next of Kin Email
                                                                        </span>
                                                                    </div>
                                                                    <input 
                                                                        type="text" 
                                                                        name="next_kin_email" 
                                                                        className="form-control"
                                                                        value={user_data.next_kin_email}
                                                                        onChange={handleChange} 
                                                                    />
                                                                    <div className="input-group-append">
                                                                        <div className="input-group-text"><i className="fas fa-user" /></div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </Col>
                                                        <Col xs={1}></Col>
                                                    </Row>
                                                    <button type="submit" class="btn btn-block bg-gradient-success btn-sm dish-btn-primary">Update Next of Kin</button>
                                                </form>
                                            </div>
                                            
                                            {/* /.tab-pane */}
                                        </div>
                                        {/* /.tab-content */}
                                    </div>{/* /.card-body */}
                                </div>
                                {/* /.card */}
                            </div>
                            {/* /.col */}
                            </div>
                            {/* /.row */}
                        </div>{/* /.container-fluid */}
                    </section>
                </div>
            <Footer/>
        </div>
    )
}
