import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { EnrolCourse } from "../../redux/actions";
import {Header} from '../../includes/Header';
import {Menu} from '../../includes/Menu';
import {Footer} from '../../includes/Footer';
import { AesEncrypt, AesDecrypt } from 'aes';
import { Success_Message, Error_Message } from "../../shared";
import { Row, Col } from 'react-bootstrap';

import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";

export const ViewCourses = () => {
    let user_email = localStorage.getItem('user');
    let user_token = localStorage.getItem('token');
    let user_role  = localStorage.getItem('role');
    let history    = useHistory();
    let res = {};
    const [showLoaderDiv, setShowLoaderDiv] = useState("none");
    const [loaderVisible, setLoaderVisible] = useState(false);

    useEffect(async () => {
        
    }, []);

    const enrolCourse = async (course_id) => {
        window.open('https://lms.kiu.ac.ug/enrol/index.php?id='+course_id, '_blank');
    }

    return (
        <div className="wrapper">
            <Header/>
            <Menu/>
                <div className="content-wrapper">
                    {/* // ///////////////////////////////// LOADER /////////////////////////////////////// */}
                    <div style={{position: "fixed", height:"100%", width:"100%", display: showLoaderDiv, zIndex: "1500"}}>
                        <div style={{position: "fixed", top:"50%", left:"46%",backgroundColor: "#ffffffcf",padding:"15px", borderRadius:"20px" }}>
                            <Loader
                                type="MutatingDots"
                                color="#183287"
                                secondaryColor="#ff9700"
                                height={100}
                                width={100}
                                visible={loaderVisible}
                                // timeout={3000} //3 secs
                            />
                            <img src="images/logo.png" alt="DISH Logo" className="brand-image elevation-3" style={{maxHeight: "40px", width: "auto", marginBottom: "20px"}} />
                            <h6 style={{color: "#183287"}}>Loading...</h6>
                        </div>
                    </div>
                    {/* // ///////////////////////////////// LOADER /////////////////////////////////////// */}
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Courses</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <Link to="/dashboard" style={{color: "#ff9700"}}>Home</Link>
                                </li>
                                <li className="breadcrumb-item active">Courses</li>
                                </ol>
                            </div>
                            </div>
                        </div>{/* /.container-fluid */}
                    </section>


                    <section className="content">
                        {/* Default box */}
                        <div className="card card-solid">
                            <div className="card-body pb-0">
                                <div className="row d-flex align-items-stretch">

                                    <div className="col-12 col-sm-6 col-md-6 d-flex align-items-stretch">
                                        <div className="card bg-light">
                                            <div className="card-header text-muted border-bottom-0">
                                                Business Administration
                                            </div>
                                            <div className="card-body pt-0">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <img src="../../images/courses/business-administration.jpg" alt="Business Administration" className="img-fluid" />
                                                    </div>
                                                    <div className="col-12" style={{marginTop: "15px"}}>
                                                        <p className="text-muted text-justify text-sm">
                                                            Business Administration will teach you what you need to know about working in the private sector or setting up your own business. From start to finish, the course covers economic principles and regulations, teaches you about finances and investment, tells you what you need to start up on your own, and how to keep your books and accounting in order.<br></br><br></br>

                                                            You can follow up this course by taking optional training in a new area in which you can develop yourself as a professional.<br></br>
                                                            Optional training: Hairdressing, shopkeeping, restaurant, energy, logistics, governance support, administration refugee support, primary school teaching, communication, ICT entrepreneurship, Health &#38; Mental health, accounting, customer relations, supply arrangements, textiles &#38; garments, construction materials, urban agriculture, livestock, poultry
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                            <div className="text-right">
                                                <a href="#" className="btn btn-sm bg-teal" onClick={() => enrolCourse("1562")}>
                                                    <i className="fas fa-edit" /> Enrol
                                                </a>
                                            </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 d-flex align-items-stretch">
                                        <div className="card bg-light">
                                            <div className="card-header text-muted border-bottom-0">
                                                Community and Customary Law
                                            </div>
                                            <div className="card-body pt-0">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <img src="../../images/courses/community-and-customary-law.jpg" alt="Community and Customary Law" className="img-fluid" />
                                                    </div>
                                                    <div className="col-12" style={{marginTop: "15px"}}>
                                                        <p className="text-muted text-justify text-sm">
                                                            Community and Customary Law is focused on the traditional aspects of justice and community. It covers governance, customary law, and the principles of mediation. Gender and customary law are also key aspects in this course.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                            <div className="text-right">
                                                <a href="#" className="btn btn-sm bg-teal" onClick={() => enrolCourse("1561")}>
                                                    <i className="fas fa-edit" /> Enrol
                                                </a>
                                            </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 d-flex align-items-stretch">
                                        <div className="card bg-light">
                                            <div className="card-header text-muted border-bottom-0">
                                                Community Health (CH)
                                            </div>
                                            <div className="card-body pt-0">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <img src="../../images/courses/community-health.jpg" alt="Community Health (CH)" className="img-fluid" />
                                                    </div>
                                                    <div className="col-12" style={{marginTop: "15px"}}>
                                                        <p className="text-muted text-justify text-sm">
                                                            This course trains you to become a community health worker. In three months, the course covers the basics of healthcare, including maternity and child health, immunization, first aid, hygiene and treatment of common diseases. The course will show the future of healthcare by teaching you about digital health. It will also show you how to deal with COVID-19 in your community.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                            <div className="text-right">
                                                <a href="#" className="btn btn-sm bg-teal" onClick={() => enrolCourse("1566")}>
                                                    <i className="fas fa-edit" /> Enrol
                                                </a>
                                            </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 d-flex align-items-stretch">
                                        <div className="card bg-light">
                                            <div className="card-header text-muted border-bottom-0">
                                                Community Resilience
                                            </div>
                                            <div className="card-body pt-0">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <img src="../../images/courses/community-resilience.jpg" alt="Community Resilience" className="img-fluid" />
                                                    </div>
                                                    <div className="col-12" style={{marginTop: "15px"}}>
                                                        <p className="text-muted text-justify text-sm">
                                                            This course teaches you to become a community leader and apply a short programme that reduces stress and builds community resilience. The course will teach you to recognise the challenges in communities, to roll out the resilience programme within communities, and to work with the medical services. It also teaches you to work on problems such as violence, suicide and parenting.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                            <div className="text-right">
                                                <a href="#" className="btn btn-sm bg-teal" onClick={() => enrolCourse("1564")}>
                                                    <i className="fas fa-edit" /> Enrol
                                                </a>
                                            </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 d-flex align-items-stretch">
                                        <div className="card bg-light">
                                            <div className="card-header text-muted border-bottom-0">
                                                Computer Science Level 1 - Introduction to Computer Science I
                                            </div>
                                            <div className="card-body pt-0">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <img src="../../images/courses/computer-science-level1.jpg" alt="Computer Science Level 1 - Introduction to Computer Science I" className="img-fluid" />
                                                    </div>
                                                    <div className="col-12" style={{marginTop: "15px"}}>
                                                        <p className="text-muted text-justify text-sm">
                                                            The digital world is growing fast and offers new opportunities for young professionals. Computer Science Level 1 teaches you the basic computer skills such as coding, programming and data science. It also teaches you how to put these skills to use to gain employment.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                            <div className="text-right">
                                                <a href="#" className="btn btn-sm bg-teal" onClick={() => enrolCourse("1563")}>
                                                    <i className="fas fa-edit" /> Enrol
                                                </a>
                                            </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 d-flex align-items-stretch">
                                        <div className="card bg-light">
                                            <div className="card-header text-muted border-bottom-0">
                                                Computer Science Level 2 - Introduction to Computer Science II
                                            </div>
                                            <div className="card-body pt-0">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <img src="../../images/courses/computer-science-level2.jpeg" alt="Computer Science Level 2 - Introduction to Computer Science II" className="img-fluid" />
                                                    </div>
                                                    <div className="col-12" style={{marginTop: "15px"}}>
                                                        <p className="text-muted text-justify text-sm">
                                                            Do you want to go a step further, or do you already know the basics of computer science? Try computer science level 2. This course will teach you about Big Data and how to manage them. The course will teach you about the cutting edge of data and making data Findable, Accessible, Interoperable, and Reusable (FAIR). After this training, you will be a Data Steward ready to work with the newest data developments.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                            <div className="text-right">
                                                <a href="#" className="btn btn-sm bg-teal" onClick={() => enrolCourse("1567")}>
                                                    <i className="fas fa-edit" /> Enrol
                                                </a>
                                            </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 d-flex align-items-stretch">
                                        <div className="card bg-light">
                                            <div className="card-header text-muted border-bottom-0">
                                                Computer Science Level 3 - Fair Data Management
                                            </div>
                                            <div className="card-body pt-0">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <img src="../../images/courses/computer-science-level3.jpg" alt="Computer Science Level 3 - Fair Data Management" className="img-fluid" />
                                                    </div>
                                                    <div className="col-12" style={{marginTop: "15px"}}>
                                                        <p className="text-muted text-justify text-sm">
                                                            This course will teach you about Big Data and how to manage them. The course will teach you about the cutting edge of data and making data Findable, Accessible, Interoperable, and Reusable (FAIR). After this training, you will be a Data Steward ready to work with the newest data developments.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                            <div className="text-right">
                                                <a href="#" className="btn btn-sm bg-teal" onClick={() => enrolCourse("3888")}>
                                                    <i className="fas fa-edit" /> Enrol
                                                </a>
                                            </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 d-flex align-items-stretch">
                                        <div className="card bg-light">
                                            <div className="card-header text-muted border-bottom-0">
                                                Legal Justice System (curriculum Rule of Law)
                                            </div>
                                            <div className="card-body pt-0">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <img src="../../images/courses/legal-justice-system.jpg" alt="Legal Justice System (curriculum Rule of Law)" className="img-fluid" />
                                                    </div>
                                                    <div className="col-12" style={{marginTop: "15px"}}>
                                                        <p className="text-muted text-justify text-sm">
                                                            This course will teach you basic skills and foundations of law and the justice system. From traditional justice to international justice, the course teaches you what you need to obtain a certificate showing that you master the basics of the legal justice system and the Rule of Law.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                            <div className="text-right">
                                                <a href="#" className="btn btn-sm bg-teal" onClick={() => enrolCourse("1560")}>
                                                    <i className="fas fa-edit" /> Enrol
                                                </a>
                                            </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 d-flex align-items-stretch">
                                        <div className="card bg-light">
                                            <div className="card-header text-muted border-bottom-0">
                                                Peace Building and Conflict Resolution Diplomacy
                                            </div>
                                            <div className="card-body pt-0">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <img src="../../images/courses/Peace-building-conflict-resolution.jpg" alt="Peace Building and Conflict Resolution Diplomacy" className="img-fluid" />
                                                    </div>
                                                    <div className="col-12" style={{marginTop: "15px"}}>
                                                        <p className="text-muted text-justify text-sm">
                                                            Conflicts can occur between countries, communities, within companies and families. In this course focuses on skills that students can use to resolve conflicts, such as language, communication, health and mediation.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                            <div className="text-right">
                                                <a href="#" className="btn btn-sm bg-teal" onClick={() => enrolCourse("1558")}>
                                                    <i className="fas fa-edit" /> Enrol
                                                </a>
                                            </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 d-flex align-items-stretch">
                                        <div className="card bg-light">
                                            <div className="card-header text-muted border-bottom-0">
                                                Peace Communication, ICT and Media
                                            </div>
                                            <div className="card-body pt-0">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <img src="../../images/courses/peace-communication.jpg" alt="Peace Communication, ICT and Media" className="img-fluid" />
                                                    </div>
                                                    <div className="col-12" style={{marginTop: "15px"}}>
                                                        <p className="text-muted text-justify text-sm">
                                                            This course will teach you the language of peace. It will build skills for you to communicate online and offline, like journalism, languages, and computer science. You will learn to effectively communicate your message and engage with your audience.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                            <div className="text-right">
                                                <a href="#" className="btn btn-sm bg-teal" onClick={() => enrolCourse("1559")}>
                                                    <i className="fas fa-edit" /> Enrol
                                                </a>
                                            </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 d-flex align-items-stretch">
                                        <div className="card bg-light">
                                            <div className="card-header text-muted border-bottom-0">
                                                Primary School Teaching
                                            </div>
                                            <div className="card-body pt-0">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <img src="../../images/courses/primary-school-teaching.jpg" alt="Primary School Teaching" className="img-fluid" />
                                                    </div>
                                                    <div className="col-12" style={{marginTop: "15px"}}>
                                                        <p className="text-muted text-justify text-sm">
                                                            The course on primary school teaching will show you the basics of how to be a teacher. The course will give you an overview of what information children should learn and how you can effectively communicate this to them. At the end of the course, you will be assisted to do an internship at a school to put your new skills to work.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                            <div className="text-right">
                                                <a href="#" className="btn btn-sm bg-teal" onClick={() => enrolCourse("1565")}>
                                                    <i className="fas fa-edit" /> Enrol
                                                </a>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /.card-body */}
                        </div>
                        {/* /.card */}
                    </section>

                </div>
            <Footer/>
        </div>
    )
}
