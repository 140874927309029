import { ApiEndpoints, SERVER_REQUEST } from "../../shared";
import { GET_SUPPORT_DEPTS, GET_TICKETS, GET_TICKETS_REPLY, GET_TICKETS_REPLY_BY_ID } from "../ActionTypes";

export const GetSupportDept = (user_token) => async (dispatch, getState) => {
    const response = await SERVER_REQUEST(ApiEndpoints.GET_SUPPORT_DEPT, 'get', { user_token: user_token }, 'json' );
    
    // dispatch the response from the api to the reducer
    await dispatch({
        type: GET_SUPPORT_DEPTS,
        payload: response.data,
    });
    
    if (response.status === 200) {
        return { status: "success", data: response.data };
    } else {
        return { status: "error", data: "An error occured" };
    }
}

export const GetTickets = (user_email, user_token, user_role, page_number) => async (dispatch, getState) => {
    const response = await SERVER_REQUEST(ApiEndpoints.GET_TICKETS+"/"+user_email+"/"+user_role+"?page="+page_number, 'get', { user_email: user_email, user_token: user_token, user_role: user_role }, 'json' );
    
    let ticket_data = response.data.data.data;
    let from        = response.data.data.from;
    let to          = response.data.data.to;
    let total       = response.data.data.total;
    let prev_page_url = response.data.data.prev_page_url;
    let next_page_url = response.data.data.next_page_url;

    if(prev_page_url != null){
        prev_page_url = prev_page_url.split('=')[1];
    }

    if(next_page_url != null){
        next_page_url = next_page_url.split('=')[1];
    }
    // dispatch the response from the api to the reducer. pass the data, from(number the data starts) to(where the data ends). for pagination
    await dispatch({
        type: GET_TICKETS,
        payload: {ticket_data: ticket_data, from: from, to: to, total: total, prev_page_url: prev_page_url, next_page_url: next_page_url}
    });
    
}

export const SubmitTicket = (support_data, user_email, user_token, user_role) => async (dispatch, getState) => {
    const response = await SERVER_REQUEST(ApiEndpoints.SUBMIT_TICKET+"/"+user_email+"/"+user_role, 'post', { user_email: user_email, user_token: user_token, support_data: support_data }, 'json' );

    // await dispatch({
    //     type: USER_DETAILS,
    //     payload: response.data,
    // });
    
    if (response.status === 201) {
        return { status: "success", data: response.data };
    } else {
        return { status: "error", data: "An error occured" };
    }
}

export const GetTicketsReply = (user_email, user_token, user_role, page_number) => async (dispatch, getState) => {
    const response = await SERVER_REQUEST(ApiEndpoints.GET_TICKETS_REPLY+"/"+user_email+"/"+user_role+"?page="+page_number, 'get', { user_email: user_email, user_token: user_token, user_role: user_role }, 'json' );
    
    let ticket_data = response.data.data.data;
    let from        = response.data.data.from;
    let to          = response.data.data.to;
    let total       = response.data.data.total;
    let prev_page_url = response.data.data.prev_page_url;
    let next_page_url = response.data.data.next_page_url;

    if(prev_page_url != null){
        prev_page_url = prev_page_url.split('=')[1];
    }

    if(next_page_url != null){
        next_page_url = next_page_url.split('=')[1];
    }
    // dispatch the response from the api to the reducer. pass the data, from(number the data starts) to(where the data ends). for pagination
    await dispatch({
        type: GET_TICKETS_REPLY,
        payload: {ticket_data: ticket_data, from: from, to: to, total: total, prev_page_url: prev_page_url, next_page_url: next_page_url}
    });
}

export const GetTicketsReplyById = (user_email, user_token, user_role, ticket_id) => async (dispatch, getState) => {
    const response = await SERVER_REQUEST(ApiEndpoints.GET_TICKETS_REPLY_BY_ID+"/"+user_email+"/"+user_role, 'post', { user_email: user_email, user_token: user_token, user_role: user_role, ticket_id: ticket_id }, 'json' );
    
    let ticket_data = response.data.data.ticket_data;
    let reply_data = response.data.data.reply_data;

    // dispatch the response from the api to the reducer. pass the data, from(number the data starts) to(where the data ends). for pagination
    await dispatch({
        type: GET_TICKETS_REPLY_BY_ID,
        payload: {
            ticket_data: ticket_data,
            reply_data: reply_data,
        }
    });
}

export const SubmitTicketReply = (reply_data, user_email, user_token, user_role) => async (dispatch, getState) => {
    const response = await SERVER_REQUEST(ApiEndpoints.SUBMIT_TICKET_REPLY+"/"+user_email+"/"+user_role, 'post', { user_email: user_email, user_token: user_token, reply_data: reply_data }, 'json' );

    // await dispatch({
    //     type: USER_DETAILS,
    //     payload: response.data,
    // });
    
    if (response.status === 201) {
        return { status: "success", data: response.data };
    } else {
        return { status: "error", data: "An error occured" };
    }
}