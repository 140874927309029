import React from 'react'
import { Row, Col } from 'react-bootstrap';

export const Footer = () => {
    return (
        <div>
            <footer className="main-footer">
                <Row>
                    <Col md={10} xs={12}>
                        <strong>
                            <Row>
                                <Col md={2} xs={12}>
                                    Copyright © 2021 
                                </Col>
                                <Col md={10} xs={12}>
                                    <a href="https://codeesa.kiu.ac.ug/dish.php" target=" _blank">
                                    <span className="dish-secondary">Digital Innovation and Skills Hub</span></a>.
                                </Col>
                            </Row>
                        </strong>
                    </Col>
                    <Col md={2} xs={12}>
                        All rights reserved.
                    </Col>
                    {/* <div className="float-right d-none d-sm-inline-block">
                        <b>Version</b> 3.1.0-rc
                    </div> */}
                </Row>
            </footer>
        </div>
    )
}
