import React from 'react';
import { Link } from "react-router-dom";

export const ManagerMenu = () => {
    return (
        <div>
            <nav className="mt-2">
                <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                    {/* Add icons to the links using the .nav-icon class with font-awesome or any other icon font library */}
                    
                    <li className="nav-item">
                        <Link to="/dashboard" className="nav-link">
                            <i className="nav-icon fas fa-th" />
                            <p>
                                Dashboard
                            </p>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/manager_profile" className="nav-link">
                            <i className="nav-icon fas fa-th" />
                            <p>
                                Profile
                            </p>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/view_students" className="nav-link">
                            <i className="nav-icon fas fa-th" />
                            <p>
                                Students
                            </p>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/view_courses" className="nav-link">
                            <i className="nav-icon fas fa-th" />
                            <p>
                                Courses
                            </p>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <a href="#" className="nav-link">
                            <i className="nav-icon fas fa-table" />
                            <p>
                                Live Class
                                <i className="fas fa-angle-left right" />
                            </p>
                        </a>
                        <ul className="nav nav-treeview">
                            <li className="nav-item">
                                <Link to="/live_class" className="nav-link">
                                    <i className="far fa-circle nav-icon" />
                                    <p>Schedule Class</p>
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item">
                        <a href="#" className="nav-link">
                            <i className="nav-icon fas fa-table" />
                            <p>
                                Supports
                                <i className="fas fa-angle-left right" />
                            </p>
                        </a>
                        <ul className="nav nav-treeview">
                            <li className="nav-item">
                                <Link to="/tickets" className="nav-link">
                                    <i className="far fa-circle nav-icon" />
                                    <p>View Ticket</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/tickets_reply" className="nav-link">
                                    <i className="far fa-circle nav-icon" />
                                    <p>Reply Tickets</p>
                                </Link>
                            </li>
                        </ul>
                    </li>
                    
                </ul>
            </nav>
        </div>
    )
}
