import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Success_Message, Error_Message } from "../shared";
import { uploadFile } from "../redux/actions";
import axios, { post } from 'axios';

export const UploadFile = (props) => {
  const dispatch = useDispatch();
  // const [profile_picture, setProfileImage] = useState("../../images/default-avatar.png");

  

    const onChange = async (e) => {
        // e.preventDefault() // Stop form submit
        fileUpload(e)
        .then((response)=>{

          if (response.status == "success") {
            Success_Message("Uploaded successfully")
          } else{
            Error_Message("Upload failed. Please ensure file is the in the right size and format")
          }
        })
    }

    const fileUpload = async (e) => {
        let user_email = localStorage.getItem('user');
        let user_token = localStorage.getItem('token');
        let user_role  = localStorage.getItem('role');
        const formData = new FormData();
        formData.append(props.file_key, e.target.files[0])
        
        return dispatch(uploadFile(user_email, user_token, formData, user_role, props.file_key))

    }

    return (
        <div>
            <div className="form-group">
                {/* <label for="customFile">Custom File</label> */}
                <div className="custom-file input-group-sm">
                    <input 
                        type="file" 
                        className="custom-file-input" id="customFile" 
                        onChange={e => { onChange(e) }}
                    />
                    <label className="custom-file-label" htmlFor="customFile">Choose file</label>
                </div>
            </div>
        </div>
    )
}
