import { ApiEndpoints, INSERT_AUTH } from "../../shared";
import { SIGN_UP, SIGN_IN, FORGET_PASSWORD, CHECK_PASSWORD, RESET_PASSWORD } from "../ActionTypes";

export const UserSignup = (user_data) => async (dispatch, getState) => {
    const response = await INSERT_AUTH(ApiEndpoints.SIGN_UP, 'post', { user: user_data }, 'json' );

    // console.log(response)
    await dispatch({
        type: SIGN_UP,
        payload: response.data,
    });
    
    if (response.data.success) {
        return {status: "success", data: "Registration successful"};
    } else {
        if(response.data.validatiionError) {
            const { email, password, username } = response.data.data;
            if(email){
                // email.map(emailErr=>{ 
                    return {status: "error", data: email};
                // })
            }else if(username){
                // username.map(usernameErr=>{ 
                    return {status: "error", data: username};
                // })
            }else if(password){
                // password.map(passwordErr=>{ 
                    return {status: "error", data: password};
                // })
            }else{
                return {status: "error", data: "Please fill form correctly"};
            }
        }else {
            return {status: "error", data: "An error occured. Please try again."};
        }
    }
}

export const UserSignin = (user_data) => async (dispatch, getState) => {
    const response = await INSERT_AUTH(ApiEndpoints.SIGN_IN, 'post', { user: user_data }, 'json');
    
    // console.log(response)
    if(response){
        if (response.data.success) {
            // Success_Message("Login successful")
            await dispatch({
                type: SIGN_IN,
                payload: response.data.data,
            });
            
            const {
                Auth: { auth },
            } = getState();
            
            return {status: "success", data: auth};
        } else {
            // Error_Message("invalid username or password")
            return {status: "error", data: "invalid username or password"};
        }
    }else{
        return {status: "error", data: "invalid username or password"};
    }
}

export const UserForgetPassword = (user_data) => async (dispatch, getState) => {
    const response = await INSERT_AUTH(ApiEndpoints.FORGET_PASSWORD, 'post', { user: user_data }, 'json');
    // alert("Check")
    // console.log(response)
    // console.log(response.data)
    
    if(response){
        if (response.status === 200) {
            // alert("Check Road 1")
            await dispatch({
                type: FORGET_PASSWORD,
                payload: response.data.data,
            });
            
            const {
                Auth: { auth },
            } = getState();
            
            return {status: "success", data: auth};
        } else {
            // alert("Check Road 2")
            // Error_Message("invalid username or password")
            return {status: "error", data: response.data.data};
        }
    }else{
        return {status: "error", data: "invalid username or password"};
    }
}

export const CheckPasswordCode = (user_data) => async (dispatch, getState) => {
    const response = await INSERT_AUTH(ApiEndpoints.CHECK_PASSWORD, 'post', { user: user_data }, 'json');
    
    if(response){
        if (response.status) {
            await dispatch({
                type: CHECK_PASSWORD,
                payload: response.data,
            });
            
            return {status: "success", data: response.data};
        } else {
            // Error_Message("invalid username or password")
            return {status: "error", data: "invalid code"};
        }
    }else{
        return {status: "error", data: "invalid code"};
    }
}

export const UserResetPassword = (user_data) => async (dispatch, getState) => {
    const response = await INSERT_AUTH(ApiEndpoints.RESET_PASSWORD, 'post', { user: user_data }, 'json');
    
    if(response){
        if (response.status) {
            await dispatch({
                type: RESET_PASSWORD,
                payload: response.data.data,
            });
            
            return {status: "success", data: response.data.data};
        } else {
            // Error_Message("invalid username or password")
            return {status: "error", data: "Password error"};
        }
    }else{
        return {status: "error", data: "Password error"};
    }
}

export const SigninAdmin = (user_data) => async (dispatch, getState) => {
    const response = await INSERT_AUTH(ApiEndpoints.ADMIN_SIGN_IN, 'post', { user: user_data }, 'json');
    
    if(response){
        if (response.data.success) {
            // Success_Message("Login successful")
            await dispatch({
                type: SIGN_IN,
                payload: response.data.data,
            });
            
            const {
                Auth: { auth },
            } = getState();
            
            return {status: "success", data: auth};
        } else {
            // Error_Message("invalid username or password")
            return {status: "error", data: "invalid username or password"};
        }
    }else{
        return {status: "error", data: "invalid username or password"};
    }
}

export const SigninManager = (user_data) => async (dispatch, getState) => {
    const response = await INSERT_AUTH(ApiEndpoints.MANAGER_SIGN_IN, 'post', { user: user_data }, 'json');
    
    if(response){
        if (response.data.success) {
            // Success_Message("Login successful")
            await dispatch({
                type: SIGN_IN,
                payload: response.data.data,
            });
            
            const {
                Auth: { auth },
            } = getState();
            
            return {status: "success", data: auth};
        } else {
            // Error_Message("invalid username or password")
            return {status: "error", data: "invalid username or password"};
        }
    }else{
        return {status: "error", data: "invalid username or password"};
    }
}

export const SigninTeacher = (user_data) => async (dispatch, getState) => {
    const response = await INSERT_AUTH(ApiEndpoints.TEACHER_SIGN_IN, 'post', { user: user_data }, 'json');
    
    if(response){
        if (response.data.success) {
            return {status: "success", data: response.data.data};
        } else {
            return {status: "error", data: "invalid username or password"};
        }
    }else{
        return {status: "error", data: "invalid username or password"};
    }
}