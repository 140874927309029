import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { GetStudents, GetAllStudentsDetails, GetAllCourseDetails, GetCountries, GetInstitutions, UpdateStudentDetails, GetAllStudentDetails } from "../../redux/actions";
import {Header} from '../../includes/Header';
import {Menu} from '../../includes/Menu';
import {Footer} from '../../includes/Footer';
import { AesEncrypt, AesDecrypt } from 'aes';
import { Success_Message, Error_Message } from "../../shared";
import { UploadProfilePicture, UploadFile } from "../../components";
import ReactInputDateMask from 'react-input-date-mask';
import { Row, Col, Button, ListGroupItem } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import { CSVLink, CSVDownload } from "react-csv";

export const ViewStudents = () => {
    let history = useHistory();
    if(localStorage.getItem('role') == "teacher" || localStorage.getItem('role') == "manager" || localStorage.getItem('role') == "superadministrator"){
       
    }else {
        history.goBack();
    }
    
    let user_email = localStorage.getItem('user');
    let user_token = localStorage.getItem('token');
    let user_role  = localStorage.getItem('role');
    let res = {};
    let x = 0;
    let y = 0;
    const [showLoaderDiv, setShowLoaderDiv] = useState("none");
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState("");

    const dispatch = useDispatch();
    const Admin = useSelector((state) => state.Admin);

    const General = useSelector((state) => state.General);
    const { countries }     = General;
    const { institutions }  = General;

    const { students }   = Admin;
    const { from }      = Admin;
    const { to }        = Admin;
    const { total }     = Admin;
    const { prev_page } = Admin;
    const { next_page } = Admin;
    const { all_students }   = Admin;
    const { all_courses }   = Admin;

    const [studentDetails, setStudentDetails] = useState([]);

    const [student_data, setStudent] = useState({
        first_name: '',
        last_name: '',
        middle_name: '',
        email: '',
        zip_code: '',
        telephone: '',
        gender: '',
        dob: '',
        nationality: '',
        country_of_residence: '',
        district_province_state: '',
        address: '',
        height: '',
        weight: '',
        disabilities: '',
        disability_none: '',
        disability_hearing: '',
        disability_mobility: '',
        disability_sight: '',
        disability_learning: '',
        disability_others: '',
        next_kin_name: '',
        next_kin_relationship: '',
        next_kin_occupation	: '',
        next_kin_zip_code: '',
        next_kin_phone: '',
        next_kin_email: '',
        type_of_identification: '',
        id_passport_number: '',
        id_passport_upload: '',
        profile_picture: '',
        status: '',
    });

    useEffect(async () => {
        setShowLoaderDiv("block"); setLoaderVisible(true);
        // get students the number 1 is the page number to fetch and nul for fiter. if not filterred null, if filtered the counntry
        await dispatch(GetStudents(user_email, user_token, user_role, "1", "null"));
        await dispatch(GetCountries(user_token));
        await dispatch(GetInstitutions(user_token));
        setShowLoaderDiv("none"); setLoaderVisible(false);
    }, []);
    
    const viewMStudent = async (username, first_name, last_name, email, institution, zip_code, telephone, gender, nationality, country_of_residence, district_province_state, address, height, weight, disabilities, next_kin_name, next_kin_relationship, next_kin_occupation, next_kin_zip_code, next_kin_phone, next_kin_email, type_of_identification, id_passport_number, id_passport_upload, profile_picture, created_at) => {
        setShowLoaderDiv("block"); setLoaderVisible(true);

        setStudent(student_data => (
            { 
                ...student_data, first_name: first_name, last_name: last_name, email: email, institution: institution, zip_code: zip_code, telephone: telephone, gender: gender, nationality: nationality, country_of_residence: country_of_residence, district_province_state: district_province_state, address: address, height: height, weight: weight, next_kin_name: next_kin_name, next_kin_relationship: next_kin_relationship, next_kin_occupation: next_kin_occupation, next_kin_zip_code: next_kin_zip_code, next_kin_phone: next_kin_phone, next_kin_email: next_kin_email, type_of_identification: type_of_identification, id_passport_number: id_passport_number, id_passport_upload: id_passport_upload, profile_picture: profile_picture 
            }
        ));

        let disabilities1 = disabilities.replace(/{|}|\[|\]|\"/g, '').split(',');

        disabilities1[0].split(':')[1] == "true" ? setStudent(student_data => ({ ...student_data, disability_none: true })) : setStudent(student_data => ({ ...student_data, disability_none: false }));
        disabilities1[1].split(':')[1] == "true" ? setStudent(student_data => ({ ...student_data, disability_hearing: true })) : setStudent(student_data => ({ ...student_data, disability_hearing: false }));
        disabilities1[2].split(':')[1] == "true" ? setStudent(student_data => ({ ...student_data, disability_mobility: true })) : setStudent(student_data => ({ ...student_data, disability_mobility: false }));
        disabilities1[3].split(':')[1] == "true" ? setStudent(student_data => ({ ...student_data, disability_sight: true })) : setStudent(student_data => ({ ...student_data, disability_sight: false }));
        disabilities1[4].split(':')[1] == "true" ? setStudent(student_data => ({ ...student_data, disability_learning: true })) : setStudent(student_data => ({ ...student_data, disability_learning: false }));
        disabilities1[5].split(':')[1] == "true" ? setStudent(student_data => ({ ...student_data, disability_others: true })) : setStudent(student_data => ({ ...student_data, disability_others: false }));

        let resp = await dispatch(GetAllStudentDetails(username));
        if(resp.status === 'success') {
            document.getElementById('registeredCourse').style.display = 'none';
            setStudentDetails(resp.data);
        }else {
            document.getElementById('registeredCourse').style.display = 'block';
        }
        setShowLoaderDiv("none"); setLoaderVisible(false);
    }

    const handleChange = async (e) => {
        const { name, value } = e.target;
        setStudent(student_data => ({ ...student_data, [name]: value }));
    }

    const UpdateDetails = async (e) => {
        e.preventDefault();
        if (
            student_data.first_name == "" || student_data.last_name == "" ||  student_data.institution == "select" ||
            student_data.first_name == null || student_data.last_name == null || student_data.institution == null) 
        {
            Error_Message("Please fill all required fields")
        }else{
            setShowLoaderDiv("block"); setLoaderVisible(true);
            // const student_data1 = AesEncrypt(student_data, 'where do you go when you by yourself' );
            res = await dispatch(UpdateStudentDetails(student_data, user_email, user_token, user_role));
            alertMessage(res);
            // history.push('/add_student')
            if(selectedCountry == ""){
                await dispatch(GetStudents(user_email, user_token, user_role, "1", "null"));
            }else {
                await dispatch(GetStudents(user_email, user_token, user_role, "1", selectedCountry));
            }
            setShowLoaderDiv("none"); setLoaderVisible(false);
        }
    }

    const alertMessage = async (res) => {
        if(res.status === "success"){ 
            Success_Message("Update successful") 
        }else{
            Error_Message(res.data)
        }
    }

    const handlePageChange = async (page_number) => {
        setShowLoaderDiv("block"); setLoaderVisible(true);
        if(selectedCountry == ""){
            await dispatch(GetStudents(user_email, user_token, user_role, page_number, "null"));
        }else {
            await dispatch(GetStudents(user_email, user_token, user_role, page_number, selectedCountry));
        }
        setShowLoaderDiv("none"); setLoaderVisible(false);
    }

    const filterByCountry = async (e) => {
        const { value } = e.target;
        setSelectedCountry(value)
        await dispatch(GetStudents(user_email, user_token, user_role, "1", value));
    }

    const downloadPersonalDetails = async (e) => {
        setShowLoaderDiv("block"); setLoaderVisible(true);
        if(selectedCountry == ""){
            await dispatch(GetAllStudentsDetails(user_email, user_token, user_role, "null"));
        }else {
            await dispatch(GetAllStudentsDetails(user_email, user_token, user_role, selectedCountry));
        }
        setShowLoaderDiv("none"); setLoaderVisible(false);
        
        document.getElementById('downloadPersonalDetails').click();
    }

    const downloadCourseDetails = async (e) => {
        setShowLoaderDiv("block"); setLoaderVisible(true);
        if(selectedCountry == ""){
            await dispatch(GetAllCourseDetails(user_email, user_token, user_role, "null"));
        }else {
            await dispatch(GetAllCourseDetails(user_email, user_token, user_role, selectedCountry));
        }
        setShowLoaderDiv("none"); setLoaderVisible(false);
        
        document.getElementById('downloadCourseDetails').click();
    }

    return (
        <div className="wrapper">
            <Header/>
            <Menu/>
                <div className="content-wrapper">
                    {/* // ///////////////////////////////// LOADER /////////////////////////////////////// */}
                    <div style={{position: "fixed", height:"100%", width:"100%", display: showLoaderDiv, zIndex: "1500"}}>
                        <div style={{position: "fixed", top:"50%", left:"46%",backgroundColor: "#ffffffcf",padding:"15px", borderRadius:"20px" }}>
                            <Loader
                                type="MutatingDots"
                                color="#183287"
                                secondaryColor="#ff9700"
                                height={100}
                                width={100}
                                visible={loaderVisible}
                                // timeout={3000} //3 secs
                            />
                            <img src="images/logo.png" alt="DISH Logo" className="brand-image elevation-3" style={{maxHeight: "40px", width: "auto", marginBottom: "20px"}} />
                            <h6 style={{color: "#183287"}}>Loading...</h6>
                        </div>
                    </div>
                    {/* // ///////////////////////////////// LOADER /////////////////////////////////////// */}
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Students</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <Link to="/dashboard" style={{color: "#ff9700"}}>Home</Link>
                                </li>
                                <li className="breadcrumb-item active">Students</li>
                                </ol>
                            </div>
                            </div>
                        </div>{/* /.container-fluid */}
                    </section>

                    <section className="content">
                        <div className="row">
                            {/* /.col */}
                            <div className="col-md-12">
                                <div className="card card-primary card-outline">
                                    <div className="card-header">
                                        <h3 className="card-title">List of Students</h3>
                                    </div>
                                    {/* /.card-header */}
                                    <div className="card-body p-0">
                                        <div className="mailbox-controls">
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            Filter by Country
                                                            </span>
                                                        </div>
                                                        <select className="form-control" name="country_of_residence" value={selectedCountry} onChange={filterByCountry}>
                                                            <option>Select Country</option>
                                                            {countries && countries.map((country, index) => (
                                                                <option 
                                                                    key={index}
                                                                    value={country.name}
                                                                >{country.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <button 
                                                        type="submit" 
                                                        className="btn bg-gradient-success btn-sm"
                                                        style={{float: 'right'}}
                                                        onClick={() => downloadPersonalDetails()}
                                                    >Download Personal Data</button>
                                                    <CSVLink 
                                                        data={all_students}
                                                        filename={"Students Personal Data.csv"}
                                                        className="btn btn-primary"
                                                        id="downloadPersonalDetails"
                                                        style={{background: "#023A7D", border: "none", display: "none"}}
                                                    >Download Personal Details</CSVLink>
                                                </div>
                                                <div className="col-md-2">
                                                    <button 
                                                        type="submit" 
                                                        className="btn bg-gradient-success btn-sm"
                                                        onClick={() => downloadCourseDetails()}
                                                    >Download Course Data</button>
                                                    <CSVLink 
                                                        data={all_courses}
                                                        filename={"Student Course Data.csv"}
                                                        className="btn btn-primary"
                                                        id="downloadCourseDetails"
                                                        style={{background: "#023A7D", border: "none", display: "none"}}
                                                    >Download Course Details</CSVLink>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="float-right">
                                                    {from}-{to}/{total} 
                                                        <div className="btn-group"  style={{padding: "10px"}}>
                                                            <button type="button" className="btn btn-default btn-sm" onClick={() => handlePageChange(prev_page)}>
                                                                <i className="fas fa-chevron-left" />
                                                            </button>
                                                            <button type="button" className="btn btn-default btn-sm" onClick={() => handlePageChange(next_page)}>
                                                                <i className="fas fa-chevron-right" />
                                                            </button>
                                                        </div>
                                                        {/* /.btn-group */}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* /.float-right */}
                                        </div>
                                        <div className="table-responsive mailbox-messages">
                                            <table id="example1" className="table table-hover table-bordered table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>S/N</th>
                                                        <th>First Name</th>
                                                        <th>Last Name</th>
                                                        <th>Email</th>
                                                        <th>Country of Residence</th>
                                                        <th>Date Added</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    
                                                    {students && students.map((student, index) => {
                                                        if(student.created_at){
                                                            student.created_at = student.created_at.split('T')[0]
                                                        }
                                                        
                                                        return(
                                                            <tr  
                                                                key={index}
                                                                data-toggle="modal" 
                                                                data-target="#view-student" 
                                                                style={{cursor: "pointer", fontSize: "14px"}} 
                                                                onClick={() => viewMStudent(student.username, student.first_name, student.last_name, student.email, student.institution, student.zip_code, student.telephone, student.gender, student.nationality, student.country_of_residence, student.district_province_state, student.address, student.height, student.weight, student.disabilities, student.next_kin_name, student.next_kin_relationship, student.next_kin_occupation, student.next_kin_zip_code, student.next_kin_phone, student.next_kin_email, student.type_of_identification, student.id_passport_number, student.id_passport_upload, student.profile_picture, student.created_at)}
                                                            >
                                                                <td className="mailbox-name">{++x}</td>
                                                                <td className="mailbox-name">{student.first_name}</td>
                                                                <td className="mailbox-name">
                                                                    {student.last_name}
                                                                </td>
                                                                <td className="mailbox-name">
                                                                    {student.email}
                                                                </td>
                                                                <td className="mailbox-name">
                                                                    {student.country_of_residence}
                                                                </td>
                                                                <td className="mailbox-date">{student.created_at}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                    
                                                </tbody>
                                            </table>
                                            {/* /.table */}
                                        </div>
                                        {/* /.mail-box-messages */}
                                    </div>
                                    {/* /.card-body */}

                                    <div className="card-footer p-0">
                                        <div className="mailbox-controls">
                                            <div className="float-right">
                                            {from}-{to}/{total}
                                            <div className="btn-group" style={{padding: "10px"}}>
                                                <button type="button" className="btn btn-default btn-sm" onClick={() => handlePageChange(prev_page)}>
                                                <i className="fas fa-chevron-left" />
                                                </button>
                                                <button type="button" className="btn btn-default btn-sm" onClick={() => handlePageChange(next_page)}>
                                                <i className="fas fa-chevron-right" />
                                                </button>
                                            </div>
                                            {/* /.btn-group */}
                                            </div>
                                            {/* /.float-right */}
                                        </div>
                                    </div>
                                </div>
                                {/* /.card */}
                            </div>
                            {/* /.col */}
                        </div>

                        {/* View Student modal */}
                        <div className="modal fade" id="view-student">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="support_subject"></h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                    </button>
                                    
                                </div>
                                <div className="modal-body">
                                    <form onSubmit={UpdateDetails}>
                                        <label>Personal Details</label><hr></hr>
                                        <Row style={{marginBottom: "25px"}}>
                                            <Col md={5} xs={12} >
                                                {/* First name */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            <span className="asterisk">*&nbsp;</span>
                                                            First Name
                                                            </span>
                                                        </div>
                                                        <input 
                                                            type="text" 
                                                            name="first_name" 
                                                            className="form-control"
                                                            value={student_data.first_name} 
                                                            onChange={handleChange} 
                                                        />
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Last name */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            <span className="asterisk">*&nbsp;</span>
                                                            Last Name
                                                            </span>
                                                        </div>
                                                        <input 
                                                            type="text" 
                                                            name="last_name" 
                                                            className="form-control"
                                                            value={student_data.last_name} 
                                                            onChange={handleChange} 
                                                        />
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Email */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            <span className="asterisk">*&nbsp;</span>
                                                            Email
                                                            </span>
                                                        </div>
                                                        <input 
                                                            type="email" 
                                                            name="email" 
                                                            className="form-control"
                                                            value={student_data.email} 
                                                            onChange={handleChange} 
                                                            disabled
                                                        />
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Institution */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            <span className="asterisk">*&nbsp;</span>
                                                            Institution
                                                            </span>
                                                        </div>
                                                        <select 
                                                            className="form-control" 
                                                            value={student_data.institution}
                                                            name="institution" 
                                                            onChange={handleChange} 
                                                        >
                                                            <option value="select">Select</option>
                                                            {institutions && institutions.map((institution, index) => (
                                                                <option 
                                                                    key={index}
                                                                    value={institution.name}
                                                                >{institution.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Phone number */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            Phone Number
                                                            </span>
                                                        </div>
                                                        <select 
                                                            className="form-control" 
                                                            value={student_data.zip_code} 
                                                            name="zip_code" 
                                                            onChange={handleChange} 
                                                        >
                                                            <option value="select">Select</option>
                                                            {countries && countries.map((country, index) => (
                                                                <option 
                                                                    key={index}
                                                                    value={country.zip_code}
                                                                >{country.code} {country.zip_code}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <input 
                                                            type="text" 
                                                            name="telephone" 
                                                            className="form-control"
                                                            value={student_data.telephone}
                                                            onChange={handleChange} 
                                                        />
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Gender */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            Gender
                                                            </span>
                                                        </div>
                                                        <select 
                                                            className="form-control" 
                                                            name="gender" 
                                                            value={student_data.gender}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="select" >-- Select --</option>
                                                            <option value="male" >Male</option>
                                                            <option value="female" >Female</option>
                                                            <option value="prefer_not_to_say">Prefer not to say</option>
                                                            <option value="others" >Others</option>
                                                        </select>
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Nationality */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            Nationality
                                                            </span>
                                                        </div>
                                                        <select 
                                                            className="form-control" 
                                                            value={student_data.nationality}
                                                            name="nationality" 
                                                            onChange={handleChange} 
                                                        >
                                                            {countries && countries.map((country, index) => (
                                                                <option 
                                                                    key={index}
                                                                    value={country.name}
                                                                >{country.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Country of Residence */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            Country of Residence
                                                            </span>
                                                        </div>
                                                        <select 
                                                            className="form-control" name="country_of_residence" 
                                                            value={student_data.country_of_residence} 
                                                            onChange={handleChange}
                                                        >
                                                            {countries && countries.map((country, index) => (
                                                                <option 
                                                                    key={index}
                                                                    value={country.name}
                                                                >{country.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </Col>
                                            <Col md={1} xs={12}></Col>
                                            <Col md={5} xs={12}>
                                                
                                                {/* District Province State */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            District Province State
                                                            </span>
                                                        </div>
                                                        <input 
                                                            type="text" 
                                                            name="district_province_state"
                                                            className="form-control"
                                                            value={student_data.district_province_state}
                                                            onChange={handleChange} 
                                                        />
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                                {/* Address */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            Address
                                                            </span>
                                                        </div>
                                                        <textarea 
                                                            className="form-control" 
                                                            rows={3} 
                                                            name="address"
                                                            value={student_data.address}
                                                            onChange={handleChange} 
                                                            placeholder="Enter ..." 
                                                            // defaultValue={""} 
                                                        />
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Height */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            Height
                                                            </span>
                                                        </div>
                                                        <input 
                                                            type="number" 
                                                            name="height"
                                                            className="form-control"
                                                            value={student_data.height}
                                                            onChange={handleChange} 
                                                        />
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Weight */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            weight
                                                            </span>
                                                        </div>
                                                        <input 
                                                            type="number" 
                                                            name="weight"
                                                            className="form-control"
                                                            value={student_data.weight}
                                                            onChange={handleChange} 
                                                        />
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Disabilities */}
                                                <label>Disabilities</label>
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <Row>
                                                            <Col style={{marginLeft: "15px"}}>
                                                                <div className="form-check">
                                                                    <input 
                                                                        className="form-check-input" type="checkbox" 
                                                                        name="disability_none"
                                                                        value={student_data.disability_none}
                                                                        checked={student_data.disability_none}
                                                                        onChange={handleChange} 
                                                                    />
                                                                    <label className="form-check-label">None</label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input 
                                                                        className="form-check-input" type="checkbox" 
                                                                        name="disability_hearing"
                                                                        value={student_data.disability_hearing}
                                                                        checked={student_data.disability_hearing}
                                                                        onChange={handleChange} 
                                                                    />
                                                                    <label className="form-check-label">Hearing</label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input 
                                                                        className="form-check-input" type="checkbox" 
                                                                        name="disability_mobility"
                                                                        value={student_data.disability_mobility}
                                                                        checked={student_data.disability_mobility}
                                                                        onChange={handleChange} 
                                                                    />
                                                                    <label className="form-check-label">Mobility</label>
                                                                </div>
                                                            </Col>
                                                            <Col style={{marginLeft: "15px"}}>
                                                                <div className="form-check">
                                                                    <input 
                                                                        className="form-check-input" type="checkbox" 
                                                                        name="disability_sight"
                                                                        value={student_data.disability_sight}
                                                                        checked={student_data.disability_sight}
                                                                        onChange={handleChange} 
                                                                    />
                                                                    <label className="form-check-label">Sight</label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input 
                                                                        className="form-check-input" type="checkbox" 
                                                                        name="disability_learning"
                                                                        value={student_data.disability_learning}
                                                                        checked={student_data.disability_learning}
                                                                        onChange={handleChange} 
                                                                    />
                                                                    <label className="form-check-label">Learning</label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input 
                                                                        className="form-check-input" type="checkbox" 
                                                                        name="disability_others"
                                                                        value={student_data.disability_others}
                                                                        checked={student_data.disability_others}
                                                                        onChange={handleChange} 
                                                                    />
                                                                    <label className="form-check-label">Others</label>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <label>Next of Kin Details</label><hr></hr>
                                        <Row style={{marginBottom: "25px"}}>
                                            <Col xs={12}>
                                                {/* next_kin_name */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            Next kin name
                                                            </span>
                                                        </div>
                                                        <input 
                                                            type="text" 
                                                            name="next_kin_name"
                                                            className="form-control"
                                                            value={student_data.next_kin_name}
                                                            onChange={handleChange} 
                                                        />
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* next_kin_relationship */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            Next kin relationship
                                                            </span>
                                                        </div>
                                                        <input 
                                                            type="text" 
                                                            name="next_kin_relationship"
                                                            className="form-control"
                                                            value={student_data.next_kin_relationship}
                                                            onChange={handleChange} 
                                                        />
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* next_kin_occupation */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            Next kin occupation
                                                            </span>
                                                        </div>
                                                        <input 
                                                            type="text" 
                                                            name="next_kin_occupation"
                                                            className="form-control"
                                                            value={student_data.next_kin_occupation}
                                                            onChange={handleChange} 
                                                        />
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* next_kin_zip_code */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            Next kin zip code
                                                            </span>
                                                        </div>
                                                        <input 
                                                            type="text" 
                                                            name="next_kin_zip_code"
                                                            className="form-control"
                                                            value={student_data.next_kin_zip_code}
                                                            onChange={handleChange} 
                                                        />
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* next_kin_phone */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            Next kin phone
                                                            </span>
                                                        </div>
                                                        <input 
                                                            type="text" 
                                                            name="next_kin_phone"
                                                            className="form-control"
                                                            value={student_data.next_kin_phone}
                                                            onChange={handleChange} 
                                                        />
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* next_kin_email */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            Next kin email
                                                            </span>
                                                        </div>
                                                        <input 
                                                            type="text" 
                                                            name="next_kin_email"
                                                            className="form-control"
                                                            value={student_data.next_kin_email}
                                                            onChange={handleChange} 
                                                        />
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <label>Identity Details</label><hr></hr>
                                        <Row style={{marginBottom: "25px"}}>
                                            <Col xs={12}>
                                                {/* type_of_identification */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            Type of identification
                                                            </span>
                                                        </div>
                                                        <select className="form-control" name="type_of_identification" value={student_data.type_of_identification} onChange={handleChange}>
                                                            <option value="select" >Select</option>
                                                            <option value="passport" >Passport</option>
                                                            <option value="national_id" >National ID</option>
                                                            <option value="driver_license" >Driver License</option>
                                                        </select>
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* id_passport_number */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            ID Passport Number
                                                            </span>
                                                        </div>
                                                        <input 
                                                            type="text" 
                                                            name="id_passport_number"
                                                            className="form-control"
                                                            value={student_data.id_passport_number}
                                                            onChange={handleChange} 
                                                        />
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <button type="submit" class="btn btn-block bg-gradient-success btn-sm">Update Student</button>
                                    </form>
                                    <br></br>
                                    <hr></hr>
                                    <br></br>
                                    <div style={{ width: '100%', textAlign: 'center', fontSize: '16px', margin: '15px'}}>
                                        <strong>Enrolled Course(s)</strong>
                                    </div>                        
                                    <div className="table-responsive mailbox-messages">
                                        <table id="example1" className="table table-hover table-bordered table-striped">
                                            <thead>
                                                <tr>
                                                    <th>S/N</th>
                                                    <th>Short Name</th>
                                                    <th>Full Name</th>
                                                    <th>Date Added</th>
                                                </tr>
                                            </thead>
                                            
                                            <tbody>
                                                
                                                {studentDetails && studentDetails.map((student, index) => {
                                                    if(student.created_at){
                                                        student.created_at = student.created_at.split('T')[0]
                                                    }
                                                    
                                                    return(
                                                        <tr  
                                                            key={index}
                                                            style={{fontSize: "14px"}} 
                                                        >
                                                            <td className="mailbox-name">{++y}</td>
                                                            <td className="mailbox-name">{student.shortname}</td>
                                                            <td className="mailbox-name">
                                                                {student.fullname}
                                                            </td>
                                                            <td className="mailbox-name">
                                                                {student.timecreated}
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                                
                                            </tbody>
                                        </table>
                                        <span style={{ width: '100%', textAlign: 'center'}}
                                            id='registeredCourse'
                                        >
                                            <strong>No Enrolled Course</strong>
                                        </span>
                                        {/* /.table */}
                                    </div>

                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                    {/* <button type="button" className="btn btn-primary">Save changes</button> */}
                                </div>
                                </div>
                                {/* /.modal-content */}
                            </div>
                            {/* /.modal-dialog */}
                        </div>
                        {/* View Student modal */}

                    </section>

                </div>
            <Footer/>
        </div>
    )
}
