import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetUsersDetails } from "../../redux/actions";
import { Warning_Message } from "../../shared";

export const StudentMenu = () => {
    let user_email = localStorage.getItem('user');
    let user_token = localStorage.getItem('token');
    let user_role  = localStorage.getItem('role');
    let res = {};
    let userDetails = [];
    const dispatch = useDispatch();
    let history = useHistory();

    useEffect(async () => {
        userDetails = await dispatch(GetUsersDetails(user_email, user_token, user_role));
        if(userDetails.data.data.country_of_residence == '') {
            Warning_Message("Please complete profile update") 
            history.push('/profile')
        }
    }, []);
    return (
        <div>
            <nav className="mt-2">
                <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                    {/* Add icons to the links using the .nav-icon class with font-awesome or any other icon font library */}
                    
                    <li className="nav-item">
                        <Link to="/dashboard" className="nav-link">
                            <i className="nav-icon fas fa-th" />
                            <p>
                                Dashboard
                            </p>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/profile" className="nav-link">
                            <i className="nav-icon fas fa-th" />
                            <p>
                                Profile
                            </p>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <a href="#" className="nav-link">
                            <i className="nav-icon fas fa-table" />
                            <p>
                                Courses
                                <i className="fas fa-angle-left right" />
                            </p>
                        </a>
                        <ul className="nav nav-treeview">
                            <li className="nav-item">
                                <Link to="/view_courses" className="nav-link">
                                    <i className="far fa-circle nav-icon" />
                                    <p>All Courses</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/my_courses" className="nav-link">
                                    <i className="far fa-circle nav-icon" />
                                    <p>My Courses</p>
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item">
                        <a href="#" className="nav-link">
                            <i className="nav-icon fas fa-table" />
                            <p>
                                Live Class
                                <i className="fas fa-angle-left right" />
                            </p>
                        </a>
                        <ul className="nav nav-treeview">
                            <li className="nav-item">
                                <Link to="/live_class" className="nav-link">
                                    <i className="far fa-circle nav-icon" />
                                    <p>Join Class</p>
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item">
                        <a href="#" className="nav-link">
                            <i className="nav-icon fas fa-table" />
                            <p>
                                Supports
                                <i className="fas fa-angle-left right" />
                            </p>
                        </a>
                        <ul className="nav nav-treeview">
                            <li className="nav-item">
                                <Link to="/add_ticket" className="nav-link">
                                    <i className="far fa-circle nav-icon" />
                                    <p>Create Ticket</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/tickets_sent" className="nav-link">
                                    <i className="far fa-circle nav-icon" />
                                    <p>Sent Tickets</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/tickets_reply" className="nav-link">
                                    <i className="far fa-circle nav-icon" />
                                    <p>Ticket Replies</p>
                                </Link>
                            </li>
                        </ul>
                    </li>
                    
                </ul>
            </nav>
        </div>
    )
}
