import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetAdminDetails, GetCountries } from "../../redux/actions";
import {Header} from '../../includes/Header';
import {Menu} from '../../includes/Menu';
import {Footer} from '../../includes/Footer';
import { AesEncrypt, AesDecrypt } from 'aes';
import { Success_Message, Error_Message } from "../../shared";
import { UploadProfilePicture, UploadFile } from "../../components";
import ReactInputDateMask from 'react-input-date-mask';
import { Row, Col, Button, ListGroupItem } from 'react-bootstrap';
import Loader from "react-loader-spinner";

export const AdminProfile = () => {
    let user_email = localStorage.getItem('user');
    let user_token = localStorage.getItem('token');
    let user_role  = localStorage.getItem('role');
    let res = {};
    let userDetails = [];
    const dispatch = useDispatch();
    const [showLoaderDiv, setShowLoaderDiv] = useState("none");
    const [loaderVisible, setLoaderVisible] = useState(false);

    // get details and pass into user
    // const Student   = useSelector((state) => state.Student);
    // const { user }  = Student;
    const General = useSelector((state) => state.General);
    const { countries }  = General;

    const [user_data, setUser] = useState([]);

    useEffect(async () => {
        setShowLoaderDiv("block"); setLoaderVisible(true);
        // get details
        userDetails = await dispatch(GetAdminDetails(user_email, user_token, user_role));
        await dispatch(GetCountries(user_token));
        setUser(userDetails.data);
        setShowLoaderDiv("none"); setLoaderVisible(false);
    }, []);


    const alertMessage = async (res) => {
        if(res.status === "success"){ 
            Success_Message("Update successful") 
        }else{
            Error_Message(res.data)
        }
    }

    return (
        <div className="wrapper">
            <Header/>
            <Menu/>
                <div className="content-wrapper">
                    {/* // ///////////////////////////////// LOADER /////////////////////////////////////// */}
                    <div style={{position: "fixed", height:"100%", width:"100%", display: showLoaderDiv, zIndex: "1500"}}>
                        <div style={{position: "fixed", top:"50%", left:"46%",backgroundColor: "#ffffffcf",padding:"15px", borderRadius:"20px" }}>
                            <Loader
                                type="MutatingDots"
                                color="#183287"
                                secondaryColor="#ff9700"
                                height={100}
                                width={100}
                                visible={loaderVisible}
                                // timeout={3000} //3 secs
                            />
                            <img src="images/logo.png" alt="DISH Logo" className="brand-image elevation-3" style={{maxHeight: "40px", width: "auto", marginBottom: "20px"}} />
                            <h6 style={{color: "#183287"}}>Loading...</h6>
                        </div>
                    </div>
                    {/* // ///////////////////////////////// LOADER /////////////////////////////////////// */}
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Profile</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <Link to="/dashboard" style={{color: "#ff9700"}}>Home</Link>
                                </li>
                                <li className="breadcrumb-item active">User Profile</li>
                                </ol>
                            </div>
                            </div>
                        </div>{/* /.container-fluid */}
                    </section>

                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                            <div className="col-md-3">
                                {/* Profile Image */}
                                <div className="card card-primary card-outline">
                                    
                                    <div className="card-body box-profile">
                                        {/* <div className="text-center">
                                            <UploadProfilePicture profile_pic={user_data.profile_picture} />
                                        </div> */}
                                        <h3 className="profile-username text-center">{user_data.username}</h3>
                                        <p className="text-muted text-center">{user_data.first_name} {user_data.last_name}</p>
                                        <p className="text-center">{user_data.email}</p>
                                    </div>
                                </div>
                                
                            </div>
                            {/* /.col */}
                            <div className="col-md-9">
                                <div className="card">

                                    <div className="card-header p-2">
                                        <ul className="nav nav-pills">
                                            <li className="nav-item"><a className="nav-link active" href="#personal_details" data-toggle="tab">Personal Details</a></li>
                                        </ul>
                                    </div>{/* /.card-header */}

                                    <div className="card-body">
                                        <div className="tab-content">
                                            <div className="active tab-pane" id="personal_details">
                                                <form>

                                                    <Row style={{marginBottom: "25px"}}>
                                                        <Col md={5} xs={12}>
                                                            {/* First name */}
                                                            <div className="form-group row">
                                                                <div className="input-group input-group-sm">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">
                                                                        <span className="asterisk">*&nbsp;</span>
                                                                         First Name
                                                                        </span>
                                                                    </div>
                                                                    <input 
                                                                        type="text" 
                                                                        name="first_name" 
                                                                        className="form-control"
                                                                        defaultValue={user_data.first_name}
                                                                        disabled
                                                                    />
                                                                    <div className="input-group-append">
                                                                        <div className="input-group-text"><i className="fas fa-user" /></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* Last name */}
                                                            <div className="form-group row">
                                                                <div className="input-group input-group-sm">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">
                                                                        <span className="asterisk">*&nbsp;</span>
                                                                        Last Name
                                                                        </span>
                                                                    </div>
                                                                    <input 
                                                                        type="text" 
                                                                        name="last_name" 
                                                                        className="form-control"
                                                                        defaultValue={user_data.last_name}
                                                                        disabled
                                                                    />
                                                                    <div className="input-group-append">
                                                                        <div className="input-group-text"><i className="fas fa-user" /></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* Phone number */}
                                                            <div className="form-group row">
                                                                <div className="input-group input-group-sm">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">
                                                                        <span className="asterisk">*&nbsp;</span>
                                                                        Phone Number
                                                                        </span>
                                                                    </div>
                                                                    <select 
                                                                        className="form-control" 
                                                                        value={user_data.zip_code} 
                                                                        name="zip_code" 
                                                                        disabled
                                                                    >
                                                                        {countries && countries.map((country) => (
                                                                            <option 
                                                                                key={country.id}
                                                                                value={country.zip_code}
                                                                                disabled
                                                                            >{country.code} {country.zip_code}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    <input 
                                                                        type="text" 
                                                                        name="telephone" 
                                                                        className="form-control"
                                                                        defaultValue={user_data.telephone}
                                                                        disabled
                                                                    />
                                                                    <div className="input-group-append">
                                                                        <div className="input-group-text"><i className="fas fa-user" /></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* Gender */}
                                                            <div className="form-group row">
                                                                <div className="input-group input-group-sm">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">
                                                                        <span className="asterisk">*&nbsp;</span>
                                                                        Gender
                                                                        </span>
                                                                    </div>
                                                                    <select 
                                                                        className="form-control" 
                                                                        name="gender" 
                                                                        value={user_data.gender} 
                                                                        disabled
                                                                    >
                                                                        <option value="select" >-- Select --</option>
                                                                        <option value="male" >Male</option>
                                                                        <option value="female" >Female</option>
                                                                        <option value="prefer_not_to_say">Prefer not to say</option>
                                                                        <option value="others" >Others</option>
                                                                    </select>
                                                                    <div className="input-group-append">
                                                                        <div className="input-group-text"><i className="fas fa-user" /></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* Nationality */}
                                                            <div className="form-group row">
                                                                <div className="input-group input-group-sm">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">
                                                                        <span className="asterisk">*&nbsp;</span>
                                                                        Nationality
                                                                        </span>
                                                                    </div>
                                                                    <select 
                                                                        className="form-control" 
                                                                        value={user_data.nationality} 
                                                                        name="nationality" 
                                                                        disabled
                                                                    >
                                                                        {countries && countries.map((country) => (
                                                                            <option 
                                                                                key={country.id}
                                                                                value={country.name}
                                                                            >{country.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    <div className="input-group-append">
                                                                        <div className="input-group-text"><i className="fas fa-user" /></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </Col>
                                                        <Col xs={1}></Col>
                                                        <Col md={5} xs={12}>
                                                            {/* Country of Residence */}
                                                            <div className="form-group row">
                                                                <div className="input-group input-group-sm">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">
                                                                        <span className="asterisk">*&nbsp;</span>
                                                                        Country of Residence
                                                                        </span>
                                                                    </div>
                                                                    <select 
                                                                        className="form-control"
                                                                        name="country_of_residence" 
                                                                        value={user_data.country_of_residence} 
                                                                        disabled
                                                                    >
                                                                        {countries && countries.map((country) => (
                                                                            <option 
                                                                                key={country.id}
                                                                                value={country.name}
                                                                            >{country.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    <div className="input-group-append">
                                                                        <div className="input-group-text"><i className="fas fa-user" /></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* District Province State */}
                                                            <div className="form-group row">
                                                                <div className="input-group input-group-sm">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">
                                                                        <span className="asterisk">*&nbsp;</span>
                                                                        District Province State
                                                                        </span>
                                                                    </div>
                                                                    <input 
                                                                        type="text" 
                                                                        name="district_province_state"
                                                                        className="form-control"
                                                                        defaultValue={user_data.district_province_state}
                                                                        disabled
                                                                    />
                                                                    <div className="input-group-append">
                                                                        <div className="input-group-text"><i className="fas fa-user" /></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        
                                                            {/* Address */}
                                                            <div className="form-group row">
                                                                <div className="input-group input-group-sm">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">
                                                                        <span className="asterisk">*&nbsp;</span>
                                                                        Address
                                                                        </span>
                                                                    </div>
                                                                    <textarea 
                                                                        className="form-control" 
                                                                        rows={3} 
                                                                        name="address"
                                                                        value={user_data.address}
                                                                        placeholder="Enter ..." 
                                                                        disabled
                                                                        // defaultValue={""} 
                                                                    />
                                                                    <div className="input-group-append">
                                                                        <div className="input-group-text"><i className="fas fa-user" /></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            

                                                        </Col>
                                                    </Row>
                                                    <button type="submit" class="btn btn-block bg-gradient-success btn-sm" disabled>Update Personal Details</button>
                                                </form>
                                            </div>
                                        </div>
                                        {/* /.tab-content */}
                                    </div>{/* /.card-body */}
                                </div>
                                {/* /.card */}
                            </div>
                            {/* /.col */}
                            </div>
                            {/* /.row */}
                        </div>{/* /.container-fluid */}
                    </section>
                </div>
            <Footer/>
        </div>
    )
}
