export const GET_COUNTRIES = 'get_countries';
export const GET_INSTITUTIONS = 'get_institutions';
export const GET_STATISTICS = 'get_statistics';

export const SIGN_UP = 'user_sign_up';
export const SIGN_IN = 'user_sign_in';
export const FORGET_PASSWORD    = 'user_forget_password';
export const CHECK_PASSWORD     = 'check_password';
export const RESET_PASSWORD     = 'reset_password';
export const USER_DETAILS           = 'user_details';
export const UPDATE_PROFILE_PICTURE = 'update_profile_picture';
export const GET_SUPPORT_DEPTS      = 'get_support_depts';
export const GET_TICKETS                = 'get_tickets';
export const GET_TICKETS_REPLY          = 'get_tickets_reply';
export const GET_TICKETS_REPLY_BY_ID    = 'get_tickets_reply_by_id';

export const ADMIN_DETAILS  = 'admin_details';
export const ADD_MANAGER    = 'add_manager';
export const GET_MANAGERS   = 'get_managers';
export const UPDATE_MANAGER_DETAILS     = 'update_manager_details';
export const GET_STUDENTS               = 'get_students';
export const GET_ALL_STUDENTS_DETAILS   = 'get_all_students';
export const GET_ALL_COURSE_DETAILS     = 'get_all_courses';
export const GET_ALL_STUDENT_DETAILS    = 'get_student_details';
export const UPDATE_STUDENT_DETAILS     = 'update_student_details';

export const MANAGER_DETAILS  = 'manager_details';

export const GET_COURSES = 'get_courses';
export const SAVE_CLASS_SCHEDULE = 'save_class_schedule';
export const GET_CLASS_HISTORY = 'get_class_history';
export const GET_MY_COURSES = 'get_my_courses';

export const GET_TEACHER_DETAILS = 'get_teacher_details';
export const UPDATE_TEACHER_DETAILS = 'update_teacher_details';