import { GET_TEACHER_DETAILS, UPDATE_TEACHER_DETAILS, GET_STUDENTS, UPDATE_STUDENT_DETAILS } from "../ActionTypes";

const INIT_VALUES = {
    teacher: [],
    from: "",
    to: "",
    total: "",
    prev_page: "",
    next_page: "",
};

export const TeacherReducer = (state = INIT_VALUES, action) => {
    
    switch (action.type) {
        case GET_TEACHER_DETAILS:
            return { ...state, teacher: action.payload };
        
        case UPDATE_TEACHER_DETAILS:
            return {
                ...state,
                teacher: action.payload.manager_data, 
            }  

        default:
            return state;
    }
}


