import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { UserSignup } from "../redux/actions/AuthActions";
import { UserSignup, GetCountries } from "../../redux/actions";
import { AesEncrypt, AesDecrypt } from 'aes';
import { Success_Message, Error_Message } from "../../shared";
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";

export const Signup = () => {

    let history = useHistory();
    let res = {};
    const [showLoaderDiv, setShowLoaderDiv] = useState("none");
    const [loaderVisible, setLoaderVisible] = useState(false);

    const General = useSelector((state) => state.General);
    const { countries }  = General;

    useEffect(async () => {
        setShowLoaderDiv("block"); setLoaderVisible(true);
        await dispatch(GetCountries());
        setShowLoaderDiv("none"); setLoaderVisible(false);
    }, []);

    const [user_data, setUser] = useState({
        first_name: '',
        last_name: '',
        username: '',
        email: '',
        password: '',
        confirm_password: '',
        nationality: '',
        country_of_residence: '',
        district_province_state: '',
        address: '',
    });

    const [submitted, setSubmitted] = useState(false);
    const dispatch = useDispatch();

    function handleChange(e) {
        const { name, value } = e.target;
        setUser(user_data => ({ ...user_data, [name]: value }));
    }

    async function handleSubmit(e) {
        e.preventDefault();
        
        setSubmitted(true);
        if (user_data.first_name && user_data.last_name && user_data.username && user_data.email && user_data.password && user_data.confirm_password && user_data.nationality && user_data.country_of_residence && user_data.district_province_state && user_data.confirm_password) {
            setShowLoaderDiv("block"); setLoaderVisible(true);
            const user_data1 = AesEncrypt(user_data, 'where do you go when you by yourself' );
            res = await dispatch(UserSignup(user_data1));

            if(res.status === "success"){ 
                setShowLoaderDiv("none"); setLoaderVisible(false);
                Success_Message("Registration successful") 
                history.push('/signin')
            }else{
                setShowLoaderDiv("none"); setLoaderVisible(false);
                Error_Message(res.data)
            }
        }
    }

    return (
        <div className="hold-transition register-page" style={{height: "100%"}}>
            <div className="register-box" style={{marginTop: "50px", marginBottom: "50px"}}>
                <div className="register-logo">
                    {/* <a href="../../index2.html"><b>DISH</b></a> */}
                    <img src="images/logo.png" alt="DISH Logo" className="brand-image elevation-3" style={{height: "45px", width: "auto"}} />
                </div>
                <div className="card">
                    <div className="card-body register-card-body">
                        {/* // ///////////////////////////////// LOADER /////////////////////////////////////// */}
                        <div style={{position: "fixed", height:"100%", width:"100%", display: showLoaderDiv, zIndex: "1500"}}>
                            <div style={{position: "fixed", top:"50%", left:"46%",backgroundColor: "#ffffffcf",padding:"15px", borderRadius:"20px" }}>
                                <Loader
                                    type="MutatingDots"
                                    color="#183287"
                                    secondaryColor="#ff9700"
                                    height={100}
                                    width={100}
                                    visible={loaderVisible}
                                    // timeout={3000} //3 secs
                                />
                                <img src="images/logo.png" alt="DISH Logo" className="brand-image elevation-3" style={{maxHeight: "40px", width: "auto", marginBottom: "20px"}} />
                                <h6 style={{color: "#183287"}}>Loading...</h6>
                            </div>
                        </div>
                        {/* // ///////////////////////////////// LOADER /////////////////////////////////////// */}
                        <h3>Sign up</h3>
                        <p>Sign up for free</p>
                        <form onSubmit={handleSubmit}>
                            {/* FIRST NAME */}
                            <div className="input-group mb-3">
                                <input 
                                    type="text" 
                                    name="first_name" 
                                    placeholder="First name" 
                                    value={user_data.first_name} 
                                    onChange={handleChange} 
                                    className={'form-control' + (submitted && !user_data.first_name ? ' is-invalid' : '')} 
                                />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                    <span className="fas fa-user" />
                                    </div>
                                </div>
                                {submitted && !user_data.first_name &&
                                    <div className="invalid-feedback">First Name is required</div>
                                }
                            </div>
                            {/* LAST NAME */}
                            <div className="input-group mb-3">
                                <input 
                                    type="text" 
                                    name="last_name" 
                                    placeholder="Last name" 
                                    value={user_data.last_name} 
                                    onChange={handleChange} 
                                    className={'form-control' + (submitted && !user_data.last_name ? ' is-invalid' : '')} 
                                />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                    <span className="fas fa-user" />
                                    </div>
                                </div>
                                {submitted && !user_data.last_name &&
                                    <div className="invalid-feedback">Last Name is required</div>
                                }
                            </div>
                            {/* USERNAME */}
                            <div className="input-group mb-3">
                                <input 
                                    type="text" 
                                    name="username" 
                                    placeholder="Username" 
                                    value={user_data.username} 
                                    onChange={handleChange} 
                                    className={'form-control' + (submitted && !user_data.username ? ' is-invalid' : '')} 
                                />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                    <span className="fas fa-user" />
                                    </div>
                                </div>
                                {submitted && !user_data.username &&
                                    <div className="invalid-feedback">Username is required</div>
                                }
                            </div>
                            {/* EMAIL */}
                            <div className="input-group mb-3">
                                <input 
                                    type="email" 
                                    name="email" 
                                    placeholder="Email address" 
                                    value={user_data.email} 
                                    onChange={handleChange} 
                                    className={'form-control' + (submitted && !user_data.email ? ' is-invalid' : '')} 
                                />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                    <span className="fas fa-envelope" />
                                    </div>
                                </div>
                                {submitted && !user_data.email &&
                                    <div className="invalid-feedback">Email address is required</div>
                                }
                            </div>
                            <div className="input-group mb-3">
                                <input 
                                    type="password" 
                                    name="password" 
                                    placeholder="Password" 
                                    value={user_data.password} 
                                    onChange={handleChange} 
                                    className={'form-control' + (submitted && !user_data.password ? ' is-invalid' : '')} 
                                />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                    <span className="fas fa-lock" />
                                    </div>
                                </div>
                                {submitted && !user_data.password &&
                                    <div className="invalid-feedback">Password is required</div>
                                }
                            </div>
                            <div className="input-group mb-3">
                                <input 
                                    type="password" 
                                    name="confirm_password" 
                                    placeholder="Confirm Password" 
                                    value={user_data.confirm_password} 
                                    onChange={handleChange} 
                                    className={'form-control' + (submitted && !user_data.confirm_password ? ' is-invalid' : '')} 
                                />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                    <span className="fas fa-lock" />
                                    </div>
                                </div>
                                {submitted && !user_data.confirm_password &&
                                    <div className="invalid-feedback">Confirm Password is required</div>
                                }
                            </div>
                            <hr></hr>
                            {/* Nationality */}
                            <div className="form-group mb-3">
                                <span>Nationality</span>
                                <div className="input-group">
                                    <select 
                                        className={'form-control' + (submitted && !user_data.nationality ? ' is-invalid' : '')} 
                                        value={user_data.nationality} name="nationality" onChange={handleChange} 
                                    >
                                        {countries && countries.map((country) => (
                                            <option 
                                                key={country.id}
                                                value={country.name}
                                            >{country.name}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="input-group-append">
                                        <div className="input-group-text"><i className="fas fa-user" /></div>
                                    </div>
                                    {submitted && !user_data.nationality &&
                                        <div className="invalid-feedback">Nationality is required</div>
                                    }
                                </div>
                            </div>
                            {/* Country of Residence */}
                            <div className="form-group mb-3">
                                <span>Country of Residence</span>
                                <div className="input-group">
                                    <select 
                                        className={'form-control' + (submitted && !user_data.country_of_residence ? ' is-invalid' : '')}
                                        name="country_of_residence" 
                                        value={user_data.country_of_residence} 
                                        onChange={handleChange}
                                    >
                                        {countries && countries.map((country) => (
                                            <option 
                                                key={country.id}
                                                value={country.name}
                                            >{country.name}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="input-group-append">
                                        <div className="input-group-text"><i className="fas fa-user" /></div>
                                    </div>
                                    {submitted && !user_data.country_of_residence &&
                                        <div className="invalid-feedback">Country of Residence is required</div>
                                    }
                                </div>
                            </div>
                            {/* District Province State */}
                            <div className="form-group mb-3">
                                <span>District/Province/State</span>
                                <div className="input-group">
                                    <input 
                                        type="text" 
                                        name="district_province_state"
                                        className={'form-control' + (submitted && !user_data.district_province_state ? ' is-invalid' : '')} 
                                        defaultValue={user_data.district_province_state}
                                        onChange={handleChange} 
                                    />
                                    <div className="input-group-append">
                                        <div className="input-group-text"><i className="fas fa-user" /></div>
                                    </div>
                                    {submitted && !user_data.district_province_state &&
                                        <div className="invalid-feedback">District/Province/State is required</div>
                                    }
                                </div>
                            </div>
                        
                            {/* Address */}
                            <div className="form-group mb-3">
                                <span>Address</span>
                                <div className="input-group">
                                    <textarea 
                                        className={'form-control' + (submitted && !user_data.address ? ' is-invalid' : '')}
                                        rows={3} 
                                        name="address"
                                        value={user_data.address}
                                        onChange={handleChange} 
                                        placeholder="Enter ..." 
                                        // defaultValue={""} 
                                    />
                                    <div className="input-group-append">
                                        <div className="input-group-text"><i className="fas fa-user" /></div>
                                    </div>
                                    {submitted && !user_data.address &&
                                        <div className="invalid-feedback">Address is required</div>
                                    }
                                </div>
                            </div>
                            <br></br>
                            <div className="row">
                            <div className="col-8">
                                <div className="icheck-primary">
                                <input type="checkbox" id="agreeTerms" name="terms" defaultValue="agree" required />
                                <label htmlFor="agreeTerms">
                                    I agree to the <a href="#" style={{color: "#183287"}}>terms</a>
                                </label>
                                </div>
                            </div>
                            {/* /.col */}
                            <div className="col-4">
                                <button type="submit" className="btn btn-primary btn-block dish-btn-primary">Sign up</button>
                            </div>
                            {/* /.col */}
                            </div>
                        </form><br></br>

                        {/* <div className="social-auth-links text-center">
                            <p>- OR -</p>
                            <a href="#" className="btn btn-block btn-primary">
                            <i className="fab fa-facebook mr-2" />
                            Sign up using Facebook
                            </a>
                            <a href="#" className="btn btn-block btn-danger">
                            <i className="fab fa-google-plus mr-2" />
                            Sign up using Google+
                            </a>
                        </div> */}
                        <Link to="/signin" style={{color: "#ff9700"}}>
                            I am already a member
                        </Link>
                    </div>
                    {/* /.form-box */}
                </div>{/* /.card */}
                </div>

        </div>
    )
}
