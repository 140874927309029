import { SIGN_UP, SIGN_IN, FORGET_PASSWORD } from "../ActionTypes";

const INIT_VALUES = {
    auth: [],
};

export const AuthReducer = (state = INIT_VALUES, action) => {
    switch (action.type) {
        case SIGN_UP:
            return { ...state, auth: action.payload };

        case SIGN_IN:
            return { ...state, auth: {...action.payload}}
        
        default:
            return state;
    }
}


