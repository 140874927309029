import { ApiEndpoints, SERVER_REQUEST } from "../../shared";
import { GET_COURSES, SAVE_CLASS_SCHEDULE, GET_CLASS_HISTORY, GET_MY_COURSES } from "../ActionTypes";

export const GetCourses = (user_email, user_token, user_role, page_number) => async (dispatch, getState) => {
    const response = await SERVER_REQUEST(ApiEndpoints.GET_COURSES+"/"+user_email+"/"+user_role+"?page="+page_number, 'get', { user_email: user_email, user_token: user_token }, 'json' );
    
    if(response.status != 500){
        let course_data = response.data.data.data;
        let from        = response.data.data.from;
        let to          = response.data.data.to;
        let total       = response.data.data.total;
        let prev_page_url = response.data.data.prev_page_url;
        let next_page_url = response.data.data.next_page_url;

        if(prev_page_url != null){
            prev_page_url = prev_page_url.split('=')[1];
        }

        if(next_page_url != null){
            next_page_url = next_page_url.split('=')[1];
        }
        
        await dispatch({
            type: GET_COURSES,
            payload: {course_data: course_data, from: from, to: to, total: total, prev_page_url: prev_page_url, next_page_url: next_page_url}
        });
    }
}

export const SaveClassSchedule = (course_data, user_email, user_token, user_role) => async (dispatch, getState) => {
    const response = await SERVER_REQUEST(ApiEndpoints.SAVE_CLASS_SCHEDULE+"/"+user_email+"/"+user_role, 'post', { user_email: user_email, user_token: user_token, course_data: course_data }, 'json' );

    await dispatch({
        type: SAVE_CLASS_SCHEDULE,
        payload: response.data,
    });
    
    if (response.status === 201) {
        return { status: "success", data: response.data };
    } else {
        return { status: "error", data: "An error occured" };
    }
}

export const GetClassHistory = (course_id, user_email, user_token, user_role) => async (dispatch, getState) => {
    const response = await SERVER_REQUEST(ApiEndpoints.GET_CLASS_HISTORY+"/"+user_email+"/"+user_role, 'post', { user_token: user_token, course_id: course_id }, 'json' );

    if(response.status != 500){
        let class_history_data = response.data.data.data;
        let from            = response.data.data.from;
        let to              = response.data.data.to;
        let total           = response.data.data.total;
        let prev_page_url   = response.data.data.prev_page_url;
        let next_page_url   = response.data.data.next_page_url;

        if(prev_page_url != null){
            prev_page_url = prev_page_url.split('=')[1];
        }

        if(next_page_url != null){
            next_page_url = next_page_url.split('=')[1];
        }
        
        await dispatch({
            type: GET_CLASS_HISTORY,
            payload: {class_history_data: class_history_data, from: from, to: to, total: total, prev_page_url: prev_page_url, next_page_url: next_page_url}
        });
    }
}

export const GetMyCourses = (user_email, user_token, user_role, page_number) => async (dispatch, getState) => {
    const response = await SERVER_REQUEST(ApiEndpoints.GET_MY_COURSES+"/"+user_email+"/"+user_role+"?page="+page_number, 'get', { user_email: user_email, user_token: user_token }, 'json' );
    
    if(response.status != 500){
        let course_data = response.data.data;

        await dispatch({
            type: GET_MY_COURSES,
            payload: {course_data: course_data}
        });
    }
}