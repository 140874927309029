import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Success_Message, Error_Message } from "../shared";
import { uploadFile } from "../redux/actions";
import axios, { post } from 'axios';

export const UploadProfilePicture = (props) => {
  const dispatch = useDispatch();
  const [profile_picture, setProfileImage] = useState("../../images/default-avatar.png");

    useEffect(async () => {
      if(props.profile_pic == null || props.profile_pic == ""){
      }else{
        setProfileImage(props.profile_pic)
      }
    }, [props.profile_pic]);

    const onChangeProfilePicture = async (e) => {
        await onSubmitProfilePicture(e)
        await setProfileImage(e.target.files[0])
        // setProfileImage({profile_picture:e.target.files[0]}, onSubmitProfilePicture);
    }

    // display the uploaded image
    function imageHandlerProfilePicture (e) {
        const reader = new FileReader();
        reader.onload = () =>{
          if(reader.readyState === 2){
            setProfileImage( reader.result );
          }
        }
        reader.readAsDataURL(e.target.files[0])
    };

    function onSubmitProfilePicture(e){
        // e.preventDefault() // Stop form submit
        fileUploadProfilePicture(e)
        .then((response)=>{

          if (response.status == "success") {
            Success_Message("Uploaded successfully")
          } else{
            Error_Message("Upload failed")
          }
        })
      }

      function fileUploadProfilePicture(e){
        let user_email = localStorage.getItem('user');
        let user_token = localStorage.getItem('token');
        let user_role  = localStorage.getItem('role');
        const formData = new FormData();
        formData.append('profile_picture', e.target.files[0])
        
        return dispatch(uploadFile(user_email, user_token, formData, user_role, "profile_picture"))
    }

    return (
        <div>
            <img 
              className="profile-user-img img-fluid img-circle" 
              src={profile_picture} 
              alt="User profile picture" 
              style={{width: "150px"}}
            />
            <input 
                type="file" 
                color="primary"
                id="profile_picture"
                style={{display: "none"}}
                // onChange={this.onChangeProfilePicture}
                onChange={e => { onChangeProfilePicture(e); imageHandlerProfilePicture(e) }}
            />
        </div>
    )
}
