import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { GetTicketsReply, GetTicketsReplyById } from "../../redux/actions";
import {Header} from '../../includes/Header';
import {Menu} from '../../includes/Menu';
import {Footer} from '../../includes/Footer';
import { AesEncrypt, AesDecrypt } from 'aes';
import { Success_Message, Error_Message } from "../../shared";
import { UploadProfilePicture, UploadFile } from "../../components";
import ReactInputDateMask from 'react-input-date-mask';
import { Row, Col, Button, ListGroupItem } from 'react-bootstrap';
import Loader from "react-loader-spinner";

export const TicketsReply = () => {
    let history = useHistory();
    if(localStorage.getItem('role') != "student"){
        history.goBack();
    }
    
    const user_email = localStorage.getItem('user');
    const user_token = localStorage.getItem('token');
    const user_role  = localStorage.getItem('role');
    let date         = "";
    let time         = "";
    let date1        = "";
    let time1        = "";
    const [showLoaderDiv, setShowLoaderDiv] = useState("none");
    const [loaderVisible, setLoaderVisible] = useState(false);

    const dispatch = useDispatch();
    const Supports = useSelector((state) => state.Supports);
    const { tickets }   = Supports;
    const { from }      = Supports;
    const { to }        = Supports;
    const { total }     = Supports;
    const { prev_page } = Supports;
    const { next_page } = Supports;
    const { tickets_sent_by_id }    = Supports;
    const { tickets_reply_by_id }   = Supports;
    

    useEffect(async () => {
        setShowLoaderDiv("block"); setLoaderVisible(true);
        // get tickets the number 1 is the page number to fetch
        await dispatch(GetTicketsReply(user_email, user_token, user_role, "1"));
        setShowLoaderDiv("none"); setLoaderVisible(false);
    }, []);

    const handlePageChange = async (page_number) => {
        setShowLoaderDiv("block"); setLoaderVisible(true);
        await dispatch(GetTicketsReply(user_email, user_token, user_role, page_number));
        setShowLoaderDiv("none"); setLoaderVisible(false);
    }

    const viewTicket = async (ticket_id) => {
        setShowLoaderDiv("block"); setLoaderVisible(true);
        await dispatch(GetTicketsReplyById(user_email, user_token, user_role, ticket_id));
        setShowLoaderDiv("none"); setLoaderVisible(false);
    }

    return (
        <div className="wrapper">
            <Header/>
            <Menu/>
                <div className="content-wrapper">
                    {/* // ///////////////////////////////// LOADER /////////////////////////////////////// */}
                    <div style={{position: "fixed", height:"100%", width:"100%", display: showLoaderDiv, zIndex: "1500"}}>
                        <div style={{position: "fixed", top:"50%", left:"46%",backgroundColor: "#ffffffcf",padding:"15px", borderRadius:"20px" }}>
                            <Loader
                                type="MutatingDots"
                                color="#183287"
                                secondaryColor="#ff9700"
                                height={100}
                                width={100}
                                visible={loaderVisible}
                                // timeout={3000} //3 secs
                            />
                            <img src="images/logo.png" alt="DISH Logo" className="brand-image elevation-3" style={{maxHeight: "40px", width: "auto", marginBottom: "20px"}} />
                            <h6 style={{color: "#183287"}}>Loading...</h6>
                        </div>
                    </div>
                    {/* // ///////////////////////////////// LOADER /////////////////////////////////////// */}
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Tickets</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <Link to="/dashboard" style={{color: "#ff9700"}}>Home</Link>
                                </li>
                                <li className="breadcrumb-item active">Tickets</li>
                                </ol>
                            </div>
                            </div>
                        </div>{/* /.container-fluid */}
                    </section>

                    <section className="content">
                        <div className="row">
                            <div className="col-md-3">
                            <Link to="/add_ticket" className="btn btn-primary btn-block mb-3">Create Ticket</Link>
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Folders</h3>
                                    <div className="card-tools">
                                        <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                        <i className="fas fa-minus" />
                                        </button>
                                    </div>
                                </div>
                                <div className="card-body p-0">
                                    <ul className="nav nav-pills flex-column">
                                        <li className="nav-item active">
                                            <Link to="/tickets_reply" className="nav-link">
                                                <i className="fas fa-inbox" /> Reply
                                                {/* <span className="badge bg-primary float-right">12</span> */}
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/tickets_sent" className="nav-link">
                                                <i className="far fa-envelope" /> Sent
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                {/* /.card-body */}
                            </div>
                            
                            </div>
                            {/* /.col */}
                            <div className="col-md-9">
                                <div className="card card-primary card-outline">
                                    <div className="card-header">
                                    <h3 className="card-title">Replies</h3>
                                    {/* <div className="card-tools">
                                        <div className="input-group input-group-sm">
                                        <input type="text" className="form-control" placeholder="Search Mail" />
                                        <div className="input-group-append">
                                            <div className="btn btn-primary">
                                            <i className="fas fa-search" />
                                            </div>
                                        </div>
                                        </div>
                                    </div> */}
                                    {/* /.card-tools */}
                                    </div>
                                    {/* /.card-header */}
                                    <div className="card-body p-0">
                                    <div className="mailbox-controls">

                                        <div className="float-right">
                                        {from}-{to}/{total} 
                                            <div className="btn-group"  style={{padding: "10px"}}>
                                                <button type="button" className="btn btn-default btn-sm" onClick={() => handlePageChange(prev_page)}>
                                                    <i className="fas fa-chevron-left" />
                                                </button>
                                                <button type="button" className="btn btn-default btn-sm" onClick={() => handlePageChange(next_page)}>
                                                    <i className="fas fa-chevron-right" />
                                                </button>
                                            </div>
                                            {/* /.btn-group */}
                                        </div>
                                        {/* /.float-right */}
                                    </div>
                                    <div className="table-responsive mailbox-messages">
                                        <table className="table table-hover table-striped">
                                        <thead>
                                            <tr>
                                                <th>Department</th>
                                                <th>Subject</th>
                                                <th>Reply</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tickets && tickets.map((ticket) => {
                                                if(ticket.created_at){
                                                    ticket.created_at = ticket.created_at.split('T')[0]
                                                }
                                                
                                            return(
                                                <tr  
                                                    data-toggle="modal" 
                                                    data-target="#view-ticket" 
                                                    style={{cursor: "pointer", fontSize: "14px"}} 
                                                    onClick={() => viewTicket(ticket.id)}
                                                >
                                                    <td>{ticket.support_dept}</td>
                                                    <td>{ticket.support_subject} </td>
                                                    <td>{ticket.support_reply}</td>
                                                    <td className="mailbox-date">{ticket.reply_date}</td>
                                                </tr>
                                            )})}
                                            
                                        </tbody>
                                        </table>
                                        {/* /.table */}
                                    </div>
                                    {/* /.mail-box-messages */}
                                    </div>
                                    {/* /.card-body */}

                                    <div className="card-footer p-0">
                                        <div className="mailbox-controls">
                                            
                                            <div className="float-right">
                                            {from}-{to}/{total}
                                            <div className="btn-group" style={{padding: "10px"}}>
                                                <button type="button" className="btn btn-default btn-sm" onClick={() => handlePageChange(prev_page)}>
                                                <i className="fas fa-chevron-left" />
                                                </button>
                                                <button type="button" className="btn btn-default btn-sm" onClick={() => handlePageChange(next_page)}>
                                                <i className="fas fa-chevron-right" />
                                                </button>
                                            </div>
                                            {/* /.btn-group */}
                                            </div>
                                            {/* /.float-right */}
                                        </div>
                                    </div>
                                </div>
                                {/* /.card */}
                            </div>
                            {/* /.col */}
                        </div>

                        {/* View Ticket modal */}
                        <div className="modal fade" id="view-ticket">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="support_subject">Tickets</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    
                                </div>
                                <div className="modal-body">
                                    <p id="support_reply"></p>
                                    {/* <span style={{float: "left", bottom: "0", fontSize: "12px", color: "#183287"}} id="support_dept"></span>
                                    <span style={{float: "right", bottom: "0", fontSize: "12px"}} id="created_at"></span> */}

                                    <div className="row">
                                        <div className="col-md-12">
                                            {/* The time line */}
                                            <div className="timeline">
                                                {tickets_sent_by_id && tickets_sent_by_id.map((ticket) => {
                                                    if(ticket.created_at){
                                                        let created_at  = ticket.created_at.split('T')[0]
                                                        date        = ticket.created_at.split('T')[0]
                                                        time        = ticket.created_at.split('T')[1].split('.')[0]
                                                    }

                                                    return(
                                                        <div className="timeline">
                                                            <div className="time-label">
                                                                <span className="bg-green">{date}</span>
                                                            </div>
                                                            <div>
                                                                <i className="fas fa-envelope bg-blue" />
                                                                <div className="timeline-item">
                                                                    <span className="time"><i className="fas fa-clock" />{time}</span>
                                                                    <h3 className="timeline-header">
                                                                        <div className="row">
                                                                            <div className="col-md-6">
                                                                                <span style={{fontSize: "14px", color: "#183287", marginRight: "10px"}}>{ticket.support_dept}</span>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <span>{ticket.support_subject}</span>
                                                                            </div>
                                                                        </div>
                                                                    </h3>
                                                                    <div className="timeline-body">
                                                                        {ticket.support_message}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}

                                                {tickets_reply_by_id && tickets_reply_by_id.map((ticket) => {
                                                    if(ticket.reply_date){
                                                        let reply_date  = ticket.reply_date.split('T')[0]
                                                        date1        = reply_date.split(' ')[0]
                                                        time1        = reply_date.split(' ')[1]
                                                    }

                                                    return(
                                                        <div className="timeline">
                                                            <div className="time-label">
                                                                <span className="bg-red">{date1}</span>
                                                            </div>
                                                            <div>
                                                                <i className="fas fa-envelope bg-blue" />
                                                                <div className="timeline-item">
                                                                    <span className="time"><i className="fas fa-clock" />{time1}</span>
                                                                    <h3 className="timeline-header">
                                                                        <div className="row">
                                                                            <div className="col-md-6">
                                                                                <span style={{fontSize: "14px", color: "#183287", marginRight: "10px"}}>{ticket.support_dept}</span>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <span>{ticket.support_subject}</span>
                                                                            </div>
                                                                        </div>
                                                                    </h3>
                                                                    <div className="timeline-body">
                                                                        {ticket.support_reply}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}

                                            </div>
                                        </div>
                                        {/* /.col */}
                                    </div>


                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                    {/* <button type="button" className="btn btn-primary">Save changes</button> */}
                                </div>
                                </div>
                                {/* /.modal-content */}
                            </div>
                            {/* /.modal-dialog */}
                        </div>
                        {/* View Ticket modal */}

                    </section>

                </div>
            <Footer/>
        </div>
    )
}
