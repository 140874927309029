import { ApiEndpoints, INSERT_UPDATE_OR_DELETE_REQUEST } from "../../shared";
import { UPDATE_PROFILE_PICTURE } from "../ActionTypes";

export const uploadFile = (user_email, user_token, formData, user_role, file_key) => async (dispatch, getState) => {
    
    const response = await INSERT_UPDATE_OR_DELETE_REQUEST(ApiEndpoints.UPDATE_FILE+"/"+user_email+"/"+user_role+"/"+file_key, 'post', { user_email: user_email, user_token: user_token, file: formData }, 'file' );
    
    // await dispatch({
    //     type: UPDATE_PROFILE_PICTURE,
    //     payload: response.data,
    // });
    
    if (response.status === 201) {
        return { status: "success", data: "successful" };
    } else {
        return { status: "error", data: "An error occured" };
    }
}
