import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { GetMyCourses } from "../../redux/actions";
import {Header} from '../../includes/Header';
import {Menu} from '../../includes/Menu';
import {Footer} from '../../includes/Footer';
import { AesEncrypt, AesDecrypt } from 'aes';
import { Success_Message, Error_Message } from "../../shared";
import { UploadProfilePicture, UploadFile } from "../../components";
import ReactInputDateMask from 'react-input-date-mask';
import { Row, Col, Button, ListGroupItem } from 'react-bootstrap';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";

export const MyCourses = () => {
    let history = useHistory();
    if(localStorage.getItem('role') != "student"){
        history.goBack();
    }
    
    let user_email = localStorage.getItem('user');
    let user_token = localStorage.getItem('token');
    let user_role  = localStorage.getItem('role');
    let res = {};
    let x = 0;
    let y = 0;
    const [showLoaderDiv, setShowLoaderDiv] = useState("none");
    const [loaderVisible, setLoaderVisible] = useState(false);

    const dispatch = useDispatch();
    const Course = useSelector((state) => state.Course);

    const { my_courses }           = Course;

    useEffect(async () => {
        setShowLoaderDiv("block"); setLoaderVisible(true);
        // get courses the number 1 is the page number to fetch
        await dispatch(GetMyCourses(user_email, user_token, user_role, "1"));
        setShowLoaderDiv("none"); setLoaderVisible(false);
    }, []);

    const viewCourse = async (course_id) => {
        window.open('https://lms.kiu.ac.ug/course/view.php?id='+course_id, '_blank');
    }

    return (
        <div className="wrapper">
            <Header/>
            <Menu/>
                <div className="content-wrapper">
                    {/* // ///////////////////////////////// LOADER /////////////////////////////////////// */}
                    <div style={{position: "fixed", height:"100%", width:"100%", display: showLoaderDiv, zIndex: "1500"}}>
                        <div style={{position: "fixed", top:"50%", left:"46%",backgroundColor: "#ffffffcf",padding:"15px", borderRadius:"20px" }}>
                            <Loader
                                type="MutatingDots"
                                color="#183287"
                                secondaryColor="#ff9700"
                                height={100}
                                width={100}
                                visible={loaderVisible}
                                // timeout={3000} //3 secs
                            />
                            <img src="images/logo.png" alt="DISH Logo" className="brand-image elevation-3" style={{maxHeight: "40px", width: "auto", marginBottom: "20px"}} />
                            <h6 style={{color: "#183287"}}>Loading...</h6>
                        </div>
                    </div>
                    {/* // ///////////////////////////////// LOADER /////////////////////////////////////// */}
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>My Courses</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <Link to="/dashboard" style={{color: "#ff9700"}}>Home</Link>
                                </li>
                                <li className="breadcrumb-item active">My Courses</li>
                                </ol>
                            </div>
                            </div>
                        </div>{/* /.container-fluid */}
                    </section>

                    <section className="content">
                        <div className="row">
                            {/* /.col */}
                            <div className="col-md-12">
                                <div className="card card-primary card-outline">
                                    <div className="card-header">
                                        <h3 className="card-title">List of My Courses</h3>
                                    </div>
                                    {/* /.card-header */}
                                    <div className="card-body p-0">
                                        
                                        <div className="table-responsive mailbox-messages">
                                            <table id="example1" className="table table-hover table-bordered table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>S/N</th>
                                                        <th>Short Name</th>
                                                        <th>Full Name</th>
                                                        <th>View</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    
                                                    {my_courses && my_courses.map((course) => {
                                                        
                                                        return(
                                                            <tr  
                                                                style={{fontSize: "14px"}}
                                                            >
                                                                <td className="mailbox-name">
                                                                    {++x}
                                                                </td>
                                                                <td className="mailbox-name">
                                                                    {course.shortname}</td>
                                                                <td className="mailbox-name">
                                                                    {course.fullname}
                                                                </td>
                                                                <td className="mailbox-name">
                                                                    <a href="#" className="btn btn-sm bg-teal" onClick={() => viewCourse(course.id)}>
                                                                        <i className="fas fa-eye" />
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                    
                                                </tbody>
                                            </table>
                                            {/* /.table */}
                                        </div>
                                        {/* /.mail-box-messages */}
                                    </div>
                                    {/* /.card-body */}
                                </div>
                                {/* /.card */}
                            </div>
                            {/* /.col */}
                        </div>



                    </section>

                </div>
            <Footer/>
        </div>
    )
}
