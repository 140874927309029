import { ApiEndpoints, SERVER_REQUEST } from "../../shared";
import { USER_DETAILS } from "../ActionTypes";

export const GetUsersDetails = (user_email, user_token, user_role) => async (dispatch, getState) => {
    const response = await SERVER_REQUEST(ApiEndpoints.GET_STUDENT_DETAILS+"/"+user_email+"/"+user_role, 'get', { user_email: user_email, user_token: user_token }, 'json' );

    if(response){
        await dispatch({
            type: USER_DETAILS,
            payload: response.data,
        });
        
        if (response.status === 200) {
            return { status: "success", data: response.data };
        } else {
            return { status: "error", data: "An error occured" };
        }
    }
}
