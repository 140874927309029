import logo from './logo.svg';
import './App.css';
import {Dashboard} from './pages/Dashboard';
import { Courses } from "./pages/Courses";
import { Table } from "./pages/Table";
import { Register1 } from "./pages/Register1";
import  { PrivateRoute }  from "./components/PrivateRoute";
import { PublicRoute } from "./components/PublicRoute";
import { StudentRoute } from "./components/StudentRoute";
import { TeacherRoute } from "./components/TeacherRoute";
import { ManagerRoute } from "./components/ManagerRoute";
import { AdminRoute } from "./components/AdminRoute";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// to animate router transition
import { AnimatedSwitch } from 'react-router-transition';
import { UserType } from "./pages/UserType";

import { Profile } from "./pages/student/Profile";
import { Signup } from "./pages/student/Signup";
import { Signin } from "./pages/student/Signin";
import { ForgetPassword } from "./pages/student/ForgetPassword";
import { ResetPassword } from './pages/student/ResetPassword';

import { TicketsSent } from './pages/support/TicketsSent';
import { AddTicket } from './pages/support/AddTicket';
import { TicketsReply } from './pages/support/TicketsReply';

import { Tickets } from './pages/support/Admin_Manager/Tickets';
import { TicketsReplyAdmin } from './pages/support/Admin_Manager/TicketsReplyAdmin';

import { AdminSignin } from "./pages/admin/AdminSignin";
import { AdminProfile } from "./pages/admin/AdminProfile";
import { AddManager } from "./pages/admin/AddManager";
import { ViewManagers } from "./pages/admin/ViewManagers";
import { ViewStudents } from "./pages/student/ViewStudents";

import { ManagerSignin } from "./pages/manager/ManagerSignin";
import { ManagerProfile } from "./pages/manager/ManagerProfile";

import { TeacherSignin } from "./pages/teacher/TeacherSignin";
import { TeacherProfile } from "./pages/teacher/TeacherProfile";

import { ViewCourses } from "./pages/course/ViewCourses";
import { LiveClass } from "./pages/course/LiveClass";
import { JoinLiveClass } from "./pages/course/JoinLiveClass";
import { MyCourses } from "./pages/course/MyCourses";
import { Offline, Online } from "react-detect-offline";
import Loader from "react-loader-spinner";

function App() {

  return (
    <div>
      {/* <Online> */}
        <Router>
          {/* <Switch> */}
          <AnimatedSwitch
            atEnter={{ opacity: 0 }}
            atLeave={{ opacity: 0 }}
            atActive={{ opacity: 1 }}
            className="switch-wrapper"
          > 

              {/* //////////////////////////////////// STUDENT //////////////////////////////////////// */}
              
              <PublicRoute path="/signup" exact component={Signup} />
              <PublicRoute path="/signin" exact component={Signin} />
              <PublicRoute path="/" exact component={UserType} />
              <PublicRoute path="/forget-password" exact component={ForgetPassword} />
              <PublicRoute path="/reset-password" exact component={ResetPassword} />
              <PrivateRoute exact path="/dashboard">
                <Dashboard />
              </PrivateRoute>
              <PrivateRoute exact path="/profile">
                <Profile />
              </PrivateRoute>
              <PrivateRoute exact path="/view_courses">
                <ViewCourses />
              </PrivateRoute>
              <PrivateRoute exact path="/my_courses">
                <MyCourses />
              </PrivateRoute>
              <PrivateRoute exact path="/live_class">
                <LiveClass />
              </PrivateRoute>
              <PrivateRoute exact path="/join_live_class">
                <JoinLiveClass />
              </PrivateRoute>
              <PrivateRoute exact path="/tickets_sent">
                <TicketsSent />
              </PrivateRoute>
              <PrivateRoute exact path="/add_ticket">
                <AddTicket />
              </PrivateRoute>
              <PrivateRoute exact path="/tickets_reply">
                <TicketsReply />
              </PrivateRoute>
              {/* //////////////////////////////////// STUDENT //////////////////////////////////////// */}

              {/* //////////////////////////////////// TEACHER //////////////////////////////////////// */}
              <PublicRoute exact path="/teacher_signin"  component={TeacherSignin} />
              <PrivateRoute exact path="/teacher_profile">
                <TeacherProfile />
              </PrivateRoute>
              <PrivateRoute exact path="/view_students">
                <ViewStudents />
              </PrivateRoute>
              <PrivateRoute exact path="/view_courses">
                <ViewCourses />
              </PrivateRoute>
              <PrivateRoute exact path="/live_class">
                <LiveClass />
              </PrivateRoute>
              <PrivateRoute exact path="/join_live_class">
                <JoinLiveClass />
              </PrivateRoute>
              {/* //////////////////////////////////// TEACHER //////////////////////////////////////// */}

              {/* //////////////////////////////////// MANAGER //////////////////////////////////////// */}
              <PublicRoute exact path="/manager_signin"  component={ManagerSignin} />
              <PrivateRoute exact path="/manager_profile">
                <ManagerProfile />
              </PrivateRoute>
              <PrivateRoute exact path="/view_students">
                <ViewStudents />
              </PrivateRoute>
              <PrivateRoute exact path="/view_courses">
                <ViewCourses />
              </PrivateRoute>
              <PrivateRoute exact path="/live_class">
                <LiveClass />
              </PrivateRoute>
              <PrivateRoute exact path="/join_live_class">
                <JoinLiveClass />
              </PrivateRoute>
              <PrivateRoute exact path="/tickets">
                <Tickets />
              </PrivateRoute>
              <PrivateRoute exact path="/tickets_reply">
                <TicketsReplyAdmin />
              </PrivateRoute>
              {/* //////////////////////////////////// MANAGER //////////////////////////////////////// */}

              {/* //////////////////////////////////// ADMIN //////////////////////////////////////// */}
              <PublicRoute exact path="/admin_signin"  component={AdminSignin} />
              <PublicRoute exact path="/admin_profile"  component={AdminProfile} />
              {/* <PrivateRoute exact path="/add_manager">
                <AddManager />
              </PrivateRoute> */}
              <PrivateRoute exact path="/view_managers">
                <ViewManagers />
              </PrivateRoute>
              <PrivateRoute exact path="/view_students">
                <ViewStudents />
              </PrivateRoute>
              <PrivateRoute exact path="/view_courses">
                <ViewCourses />
              </PrivateRoute>
              <PrivateRoute exact path="/live_class">
                <LiveClass />
              </PrivateRoute>
              <PrivateRoute exact path="/join_live_class">
                <JoinLiveClass />
              </PrivateRoute>
              <PrivateRoute exact path="/tickets">
                <Tickets />
              </PrivateRoute>
              <PrivateRoute exact path="/tickets_reply">
                <TicketsReplyAdmin />
              </PrivateRoute>
              {/* //////////////////////////////////// ADMIN //////////////////////////////////////// */}

              

              {/* <PublicRoute exact path="/view_courses" component={ViewCourses} /> */}
              {/* <PublicRoute exact path="/live_class" component={LiveClass} /> */}
              {/* <PublicRoute exact path="/join_live_class" component={JoinLiveClass} /> */}
              {/* <PublicRoute exact path="/my_courses" component={MyCourses} /> */}
              {/* <PublicRoute path="/courses" exact component={Courses} /> */}

              
              
              {/* <PublicRoute exact path="/profile"  component={Profile} /> */}
              {/* <PublicRoute exact path="/courses"  component={Courses} /> */}
              {/* <PublicRoute exact path="/table"  component={Table} /> */}
              {/* <PublicRoute exact path="/register1"  component={Register1} /> */}
              {/* <PublicRoute exact path="/tickets_sent"  component={TicketsSent} /> */}
              {/* <PublicRoute exact path="/add_ticket"  component={AddTicket} /> */}
              {/* <PublicRoute exact path="/tickets_reply"  component={TicketsReply} /> */}


              {/* <PublicRoute exact path="/tickets"  component={Tickets} /> */}
              {/* <PublicRoute exact path="/tickets_reply_admin" component={TicketsReplyAdmin} /> */}


              {/* <PrivateRoute exact path="/signin">
                <Profile />
              </PrivateRoute> */}
          </AnimatedSwitch>
          {/* </Switch> */}
        </Router>
      {/* </Online> */}
      <Offline>
        <div style={{position: "fixed", height:"100%", width:"100%"}}>
            <div style={{position: "fixed", top:"73%", left:"75%",backgroundColor: "#ffffffcf",padding:"15px", borderRadius:"20px", textAlign: "center" }}>
                <Loader
                    type="MutatingDots"
                    color="#183287"
                    secondaryColor="#ff9700"
                    height={100}
                    width={100}
                    visible="true"
                    // timeout={3000} //3 secs
                />
                <img src="images/logo.png" alt="DISH Logo" className="brand-image elevation-3" style={{maxHeight: "40px", width: "auto", marginBottom: "20px"}} />
                <h6 style={{color: "#ff9700"}}>Please check your internet connection</h6>
            </div>
        </div>
      </Offline>

      
    </div>
    
  );
}

export default App;
