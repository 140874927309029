import axios from 'axios';
import Cookies from 'js-cookie';
import { AppConstants } from './Constants';

// export const BASE_API_URL = 'http://localhost:8000/api/';
export const BASE_API_URL = 'https://dish-api.kiu.ac.ug/api/';
// export const BASE_API_URL = 'https://167.71.161.151/api/';

export const GET_TOKEN = (name) => {
    return Cookies.get(name);
};

const GET_HEADER = (token) => {
    if (token) {
        return {
            'Content-Type': 'application/json',
            'auth-token': token,
            'sessionToken': token
        }
    }
    return { 'Content-Type': 'application/json' };
};

const GET_FILE_HEADER = (token) => {
    if (token) {
        return {
            'content-type': 'multipart/form-data',
            'auth-token': token,
            'sessionToken': token
        }
    }
    return { 'Content-Type': 'multipart/form-data' };
};

export const INSERT_AUTH = async (url, type, body, file_type) => {
    try {
        const response = type.toLowerCase() === 'post'
        ? await axios.post(`${BASE_API_URL}${url}`, body)
        : "";
        // console.info(response)
        return response;

    } catch (error) {
        return error.response;
    }
};

export const SERVER_REQUEST = async (url, type, body, file_type) => {
    try {
        if(file_type === 'json'){
            const response = type.toLowerCase() === 'post'
            ? await axios.post(`${BASE_API_URL}${url}`, body, { headers: GET_HEADER(body.user_token) })
            : await axios.get(`${BASE_API_URL}${url}`, { headers: GET_HEADER(body.user_token) });

            return response;
        }else{

        }
    } catch (error) {

        return error.response;
    }
};

export const INSERT_UPDATE_OR_DELETE_REQUEST = async (url, type, body, file_type) => {
    try {
        if(file_type === 'json'){
            const response = type.toLowerCase() === 'put'
            ? await axios.put(`${BASE_API_URL}${url}`, body, { headers: GET_HEADER(body.user_token) })
            : await axios.delete(`${BASE_API_URL}${url}`, { headers: GET_HEADER(body.user_token) });

            return response;
        }else{
            const response = type.toLowerCase() === 'post'
            ? await axios.post(`${BASE_API_URL}${url}`, body.file, { headers: GET_FILE_HEADER(body.user_token) })
            : await axios.delete(`${BASE_API_URL}${url}`, { headers: GET_FILE_HEADER(body.user_token) });

            return response;
        }

    } catch (error) {
        return error.response;
    }
};
