import { ApiEndpoints, INSERT_UPDATE_OR_DELETE_REQUEST } from "../../shared";
import { USER_DETAILS } from "../ActionTypes";

export const UpdateDetails = (user_data, user_email, user_token, user_role) => async (dispatch, getState) => {
    const response = await INSERT_UPDATE_OR_DELETE_REQUEST(ApiEndpoints.UPDATE_DETAILS+"/"+user_email+"/"+user_role, 'put', { user_email: user_email, user_token: user_token, user_data: user_data }, 'json' );

    await dispatch({
        type: USER_DETAILS,
        payload: response.data,
    });
    
    if (response.status === 201) {
        return { status: "success", data: response.data };
    } else {
        return { status: "error", data: "An error occured" };
    }
}
