import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetSupportDept, SubmitTicket } from "../../redux/actions";
import {Header} from '../../includes/Header';
import {Menu} from '../../includes/Menu';
import {Footer} from '../../includes/Footer';
import { AesEncrypt, AesDecrypt } from 'aes';
import { Success_Message, Error_Message } from "../../shared";
import { UploadProfilePicture, UploadFile } from "../../components";
import ReactInputDateMask from 'react-input-date-mask';
import { Row, Col, Button, ListGroupItem } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";

export const AddTicket = () => {
    let history = useHistory();
    if(localStorage.getItem('role') != "student"){
        history.goBack();
    }
    

    let user_email = localStorage.getItem('user');
    let user_token = localStorage.getItem('token');
    let user_role  = localStorage.getItem('role');
    let res = {};
    const [showLoaderDiv, setShowLoaderDiv] = useState("none");
    const [loaderVisible, setLoaderVisible] = useState(false);

    const [support_data, setSupport] = useState({
        support_dept: '',
        support_subject: '',
        support_message: '',
    });
    
    const dispatch = useDispatch();
    const Supports = useSelector((state) => state.Supports);
    const { support_depts }  = Supports;

    useEffect(async () => {
        setShowLoaderDiv("block"); setLoaderVisible(true);
        // get details
        await dispatch(GetSupportDept(user_token));
        setShowLoaderDiv("none"); setLoaderVisible(false);
    }, []);

    const handleChange = async (e) => {
        const { name, value } = e.target;
        setSupport(support_data => ({ ...support_data, [name]: value }));
    }

    const submitTicket = async (e) => {
        e.preventDefault();
        
        if (support_data.support_dept == "select" || support_data.support_subject == "" || support_data.support_message == "" ||
        support_data.support_dept == null || support_data.support_subject == null || support_data.support_message == null) 
        {
            Error_Message("Please fill all required fields")
        }else{
            setShowLoaderDiv("block"); setLoaderVisible(true);
            res = await dispatch(SubmitTicket(support_data, user_email, user_token, user_role));
            setShowLoaderDiv("none"); setLoaderVisible(false);
            alertMessage(res)
        }
    }

    const alertMessage = async (res) => {
        if(res.status === "success"){ 
            Success_Message("Ticket successfully submited");
            history.push('/tickets_sent')
        }else{
            Error_Message(res.data)
        }
    }

    return (
        <div className="wrapper">
            <Header/>
            <Menu/>
                <div className="content-wrapper">
                    {/* // ///////////////////////////////// LOADER /////////////////////////////////////// */}
                    <div style={{position: "fixed", height:"100%", width:"100%", display: showLoaderDiv, zIndex: "1500"}}>
                        <div style={{position: "fixed", top:"50%", left:"46%",backgroundColor: "#ffffffcf",padding:"15px", borderRadius:"20px" }}>
                            <Loader
                                type="MutatingDots"
                                color="#183287"
                                secondaryColor="#ff9700"
                                height={100}
                                width={100}
                                visible={loaderVisible}
                                // timeout={3000} //3 secs
                            />
                            <img src="images/logo.png" alt="DISH Logo" className="brand-image elevation-3" style={{maxHeight: "40px", width: "auto", marginBottom: "20px"}} />
                            <h6 style={{color: "#183287"}}>Loading...</h6>
                        </div>
                    </div>
                    {/* // ///////////////////////////////// LOADER /////////////////////////////////////// */}
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Add Tickets</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <Link to="/dashboard" style={{color: "#ff9700"}}>Home</Link>
                                </li>
                                <li className="breadcrumb-item active">Add Tickets</li>
                                </ol>
                            </div>
                            </div>
                        </div>{/* /.container-fluid */}
                    </section>

                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                            <div className="col-md-3">
                                <Link to="/tickets" className="btn btn-primary btn-block mb-3">Back to Tickets</Link>
                                <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Folders</h3>
                                    <div className="card-tools">
                                    <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                        <i className="fas fa-minus" />
                                    </button>
                                    </div>
                                </div>
                                <div className="card-body p-0">
                                    <ul className="nav nav-pills flex-column">
                                        <li className="nav-item active">
                                            <Link to="/tickets_reply" className="nav-link">
                                                <i className="fas fa-inbox" /> Reply
                                                {/* <span className="badge bg-primary float-right">12</span> */}
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/tickets_sent" className="nav-link">
                                                <i className="far fa-envelope" /> Sent
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                {/* /.card-body */}
                                </div>
                            </div>
                            {/* /.col */}
                            <div className="col-md-9">
                                <div className="card card-primary card-outline">
                                    <div className="card-header">
                                        <h3 className="card-title">New Ticket</h3>
                                    </div>
                                {/* /.card-header */}
                                    <form onSubmit={submitTicket}>
                                        <div className="card-body">
                                            <div className="form-group">
                                                <label>Department</label>
                                                {/* <input className="form-control" placeholder="To:" /> */}
                                                <select className="form-control" name="support_dept" value={support_data.support_dept}  onChange={handleChange} >
                                                    <option value="select">Select</option>
                                                    {support_depts && support_depts.map((support_dept) => (
                                                        <option 
                                                            key={support_dept.id}
                                                            value={support_dept.name}
                                                        >{support_dept.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label>Subject</label>
                                                <input 
                                                    className="form-control" 
                                                    name="support_subject" 
                                                    onChange={handleChange} 
                                                    value={support_data.support_subject} 
                                                    placeholder="Subject:" 
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Message</label>
                                                <textarea 
                                                    id="compose-textarea" 
                                                    className="form-control" 
                                                    name="support_message" 
                                                    onChange={handleChange} 
                                                    value={support_data.support_message} 
                                                    style={{height: 300}}  
                                                />
                                            </div>
                                            {/* <div className="form-group">
                                                <div className="btn btn-default btn-file">
                                                    <i className="fas fa-paperclip" /> Attachment
                                                    <input type="file" name="attachment" />
                                                </div>
                                                <p className="help-block">Max. 32MB</p>
                                            </div> */}
                                        </div>
                                        <div className="card-footer">
                                            <div className="float-right">
                                                <button type="submit" className="btn btn-primary"><i className="far fa-envelope" /> Send</button>
                                            </div>
                                            {/* <button type="reset" className="btn btn-default"><i className="fas fa-times" /> Discard</button> */}
                                        </div>
                                    </form>
                                </div>
                                {/* /.card */}
                            </div>
                            {/* /.col */}
                            </div>
                            {/* /.row */}
                        </div>{/* /.container-fluid */}
                    </section>


                </div>
            <Footer/>
        </div>
    )
}
