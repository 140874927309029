import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { UserSignin } from "../redux/actions/AuthActions";
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import { Row, Col, Button, ListGroupItem } from 'react-bootstrap';

export const UserType = () => {

    let history = useHistory();


    return (
        <div>
            {/* <div className="login-box"> */}
                <div className="login-logo">
                    {/* <a href="../../index2.html"><b>DISH</b></a> */}
                    <img src="images/logo.png" alt="DISH Logo" className="brand-image elevation-3" style={{height: "45px", width: "auto"}} />
                </div>
                {/* /.login-logo */}
                {/* <div className="card"> */}
                <div className="container-fluid">
                    <Row style={{marginTop: "15%", marginBottom: "auto"}}>
                        <Col md={2} xs={12}></Col>
                        <Col md={8} xs={12}>
                            <div className="container-fluid">
                                <Row>
                                    <Col md={4} xs={10} style={{margin: "auto"}}>
                                        <Link to="/signin">
                                            <div className="card" style={{height: "250px", backgroundColor: "#e8115b", color: "#ffffff"}}>
                                                <div className="card-body">
                                                    <h4>Student</h4>
                                                </div>
                                            </div>
                                        </Link>
                                    </Col>
                                    <Col md={4} xs={10} style={{margin: "auto"}}>
                                        <Link to="/teacher_signin">
                                            <div className="card" style={{height: "250px", backgroundColor: "#1e3264", color: "#ffffff"}}>
                                                <div className="card-body">
                                                    <h4>Teacher</h4>
                                                </div>
                                            </div>
                                        </Link>
                                    </Col>
                                    <Col md={4} xs={10} style={{margin: "auto"}}>
                                        <Link to="/manager_signin">
                                            <div className="card" style={{height: "250px", backgroundColor: "#f59b23", color: "#ffffff"}}>
                                                <div className="card-body">
                                                    <h4>Manager</h4>
                                                </div>
                                            </div>
                                        </Link>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col md={2} xs={12}></Col>
                    </Row>
                </div>
                {/* </div> */}
            {/* </div> */}

        </div>
    )
}
