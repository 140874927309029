import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetCourses, SaveClassSchedule, GetClassHistory } from "../../redux/actions";
import {Header} from '../../includes/Header';
import {Menu} from '../../includes/Menu';
import {Footer} from '../../includes/Footer';
import { AesEncrypt, AesDecrypt } from 'aes';
import { Success_Message, Error_Message, Toast_Message } from "../../shared";
import { UploadProfilePicture, UploadFile } from "../../components";
import ReactInputDateMask from 'react-input-date-mask';
import { Row, Col, Button, ListGroupItem } from 'react-bootstrap';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import Loader from "react-loader-spinner";
import {CopyToClipboard} from 'react-copy-to-clipboard';

export const LiveClass = () => {
    let history = useHistory();
    if(localStorage.getItem('role') == "student" || localStorage.getItem('role') == "teacher" || localStorage.getItem('role') == "manager" || localStorage.getItem('role') == "superadministrator"){
        
    }else {
        history.goBack();
    }
    
    let user_email = localStorage.getItem('user');
    let user_token = localStorage.getItem('token');
    let user_role  = localStorage.getItem('role');
    let res = {};
    let x = 0;
    let y = 0;
    const [showLoaderDiv, setShowLoaderDiv] = useState("none");
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [copied, setCopied]   = useState(false);

    const dispatch = useDispatch();
    const Course = useSelector((state) => state.Course);

    const { courses }   = Course;
    const { from }      = Course;
    const { to }        = Course;
    const { total }     = Course;
    const { prev_page } = Course;
    const { next_page } = Course;

    const { class_history_data }            = Course;
    const { class_history_from }            = Course;
    const { class_history_to }              = Course;
    const { class_history_total }           = Course;
    const { class_history_prev_page_url }   = Course;
    const { class_history_next_page_url }   = Course;

    const [course_id, setCourseId]      = useState("");
    const [class_topic, setClassTopic]  = useState("");
    const [startDate, setStartDate]     = useState(new Date());
    const [endDate, setEndDate]         = useState(new Date());
    const [active_course_id, setActiveCourseId]   = useState("");
    const [active_shortname, setActiveShortname]  = useState("");
    const [link, setLink] = useState("");
    let scheduleStartDate = "";
    let scheduleStartTime = "";
    let scheduleEndDate = "";
    let scheduleEndTime = "";
    let scheduleClassStatus = "";

    const hours = [
        "00", "01", "02", "03", "04", "05", "06", "07", 
        "08", "09", "10", "11", "12", "13", "14", "15", 
        "16", "17", "18", "19", "20", "21", "22", "23",
    ]

    const secs = [
        "00", "05", "10", "15", 
        "20", "25", "30", "35", 
        "40", "45",  "50", "55",
    ]

    useEffect(async () => {
        setShowLoaderDiv("block"); setLoaderVisible(true);
        // get courses the number 1 is the page number to fetch
        await dispatch(GetCourses(user_email, user_token, user_role, "1"));
        setShowLoaderDiv("none"); setLoaderVisible(false);
    }, []);

    const ScheduleClass = async (course_id, shortname, fullname) => {
        setCourseId(course_id)
        document.getElementById("shortname").innerHTML  = shortname;
        document.getElementById("fullname").innerHTML   = fullname;
    }

    const ViewClassHistory = async (course_id, shortname) => {
        setShowLoaderDiv("block"); setLoaderVisible(true);
        setActiveCourseId(course_id);
        setActiveShortname(shortname)
        await dispatch(GetClassHistory(course_id, user_email, user_token, user_role));
        setShowLoaderDiv("none"); setLoaderVisible(false);
    }

    const joinClass = async (class_topic) => {
        // history.push('/live_class')
        window.open('join_live_class?'+active_shortname+'='+class_topic,'_blank');
    }

    const handleChange = async (e) => {
        const { value } = e.target;
        setClassTopic( value );
    }

    const saveClass = async (e) => {
        e.preventDefault();
        let topic    = document.getElementById("class_topic").value;
        let date1    = document.getElementsByClassName("date")[0].value;
        let fromHour = document.getElementById("fromHour").value;
        let fromMin  = document.getElementById("fromMin").value;
        let toHour   = document.getElementById("toHour").value;
        let toMin    = document.getElementById("toMin").value;

        // alert(date1)
        let dd = date1.split('/')[0];
        let mm = date1.split('/')[1];
        let yy = date1.split('/')[2];
        
        let date = dd+"-"+mm+"-"+yy;
        // date.replaceAll(/\//g, '-');
        // date.toString()
        // await date.replace(/\//gi, "-");
        // alert(date)
        
        if ( course_id == "" || topic == "" || date == "" || course_id == null || topic == null || date == null ) 
        {
            Error_Message("Please fill all required fields")
        }else{
            setShowLoaderDiv("block"); setLoaderVisible(true);

            const course_data = {course_id: course_id, topic: topic, date: date, fromHour: fromHour, fromMin: fromMin, toHour: toHour, toMin: toMin }
            res = await dispatch(SaveClassSchedule(course_data, user_email, user_token, user_role));
            setShowLoaderDiv("none"); setLoaderVisible(false);
            alertMessage(res)
        }
    }

    const alertMessage = async (res) => {
        if(res.status === "success"){ 
            Success_Message("Successful") 
            document.getElementById("class_topic").value     = "" ;
            document.getElementsByClassName("date")[0].value = "" ;
            document.getElementById("fromHour").value        = "" ;
            document.getElementById("fromMin").value         = "" ;
            document.getElementById("toHour").value          = "" ;
            document.getElementById("toMin").value           = "" ;
        }else{
            Error_Message(res.data)
        }
    }

    const handlePageChange = async (page_number) => {
        setShowLoaderDiv("block"); setLoaderVisible(true);
        await dispatch(GetCourses(user_email, user_token, user_role, page_number));
        setShowLoaderDiv("none"); setLoaderVisible(false);
    }

    const copyLink = async (topic) => {
        let link1 = "https://dish.kiu.ac.ug/join_live_class?"+active_shortname+"="+topic;
        let link2 = link1.replace(/ /gi, "-");
        await setLink(link2);
        await document.getElementById("copy_link").click();
        Toast_Message("success", "Link copied");
    }

    return (
        <div className="wrapper">
            <Header/>
            <Menu/>
                <div className="content-wrapper">
                    {/* // ///////////////////////////////// LOADER /////////////////////////////////////// */}
                    <div style={{position: "fixed", height:"100%", width:"100%", display: showLoaderDiv, zIndex: "1500"}}>
                        <div style={{position: "fixed", top:"50%", left:"46%",backgroundColor: "#ffffffcf",padding:"15px", borderRadius:"20px" }}>
                            <Loader
                                type="MutatingDots"
                                color="#183287"
                                secondaryColor="#ff9700"
                                height={100}
                                width={100}
                                visible={loaderVisible}
                                // timeout={3000} //3 secs
                            />
                            <img src="images/logo.png" alt="DISH Logo" className="brand-image elevation-3" style={{maxHeight: "40px", width: "auto", marginBottom: "20px"}} />
                            <h6 style={{color: "#183287"}}>Loading...</h6>
                        </div>
                    </div>
                    {/* // ///////////////////////////////// LOADER /////////////////////////////////////// */}
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Live Class</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <Link to="/dashboard" style={{color: "#ff9700"}}>Home</Link>
                                </li>
                                <li className="breadcrumb-item active">Live Class</li>
                                </ol>
                            </div>
                            </div>
                        </div>{/* /.container-fluid */}
                    </section>

                    <section className="content">
                        <div className="row">
                            {/* /.col */}
                            <div className="col-md-12">
                                <div className="card card-primary card-outline">
                                    <div className="card-header">
                                        <h3 className="card-title">List of Live Classes</h3>
                                    </div>
                                    {/* /.card-header */}
                                    <div className="card-body p-0">
                                        <div className="mailbox-controls">

                                            <div className="float-right">
                                            {from}-{to}/{total} 
                                                <div className="btn-group"  style={{padding: "10px"}}>
                                                    <button type="button" className="btn btn-default btn-sm" onClick={() => handlePageChange(prev_page)}>
                                                        <i className="fas fa-chevron-left" />
                                                    </button>
                                                    <button type="button" className="btn btn-default btn-sm" onClick={() => handlePageChange(next_page)}>
                                                        <i className="fas fa-chevron-right" />
                                                    </button>
                                                </div>
                                                {/* /.btn-group */}
                                            </div>
                                            {/* /.float-right */}
                                        </div>
                                        <div className="table-responsive mailbox-messages">
                                            <table id="example1" className="table table-hover table-bordered table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>S/N</th>
                                                        <th>Short Name</th>
                                                        <th>Full Name</th>
                                                        { 
                                                            user_role == "student" ?
                                                            "" : 
                                                            <th>Schedule Class</th>
                                                        }
                                                        <th>View Schedule</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    
                                                    {courses && courses.map((course) => {
                                                        if(course.created_at){
                                                            course.created_at = course.created_at.split('T')[0]
                                                        }
                                                        
                                                        return(
                                                            <tr  
                                                                style={{fontSize: "14px"}}
                                                            >
                                                                <td className="mailbox-name">{++x}</td>
                                                                <td className="mailbox-name">{course.shortname}</td>
                                                                <td className="mailbox-name">
                                                                    {course.fullname}
                                                                </td>
                                                                { 
                                                                    user_role == "student" ?
                                                                    "" : 
                                                                    <td className="mailbox-name">
                                                                        <button 
                                                                            type="button" 
                                                                            class="btn btn-primary btn-block"
                                                                            data-toggle="modal" 
                                                                            data-target="#schedule-class" 
                                                                            onClick={() => ScheduleClass(course.id, course.shortname, course.fullname)}
                                                                        >
                                                                            <i class="fa fa-save"></i>
                                                                        </button>
                                                                    </td>
                                                                }
                                                                <td className="mailbox-name">
                                                                    <button 
                                                                        type="button" 
                                                                        class="btn btn-primary btn-block"
                                                                        data-toggle="modal" 
                                                                        data-target="#view-class-history" 
                                                                        onClick={() => ViewClassHistory(course.id, course.shortname)}
                                                                    >
                                                                        <i class="fa fa-binoculars"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                    
                                                </tbody>
                                            </table>
                                            {/* /.table */}
                                        </div>
                                        {/* /.mail-box-messages */}
                                    </div>
                                    {/* /.card-body */}

                                    <div className="card-footer p-0">
                                        <div className="mailbox-controls">
                                            <div className="float-right">
                                            {from}-{to}/{total}
                                            <div className="btn-group" style={{padding: "10px"}}>
                                                <button type="button" className="btn btn-default btn-sm" onClick={() => handlePageChange(prev_page)}>
                                                <i className="fas fa-chevron-left" />
                                                </button>
                                                <button type="button" className="btn btn-default btn-sm" onClick={() => handlePageChange(next_page)}>
                                                <i className="fas fa-chevron-right" />
                                                </button>
                                            </div>
                                            {/* /.btn-group */}
                                            </div>
                                            {/* /.float-right */}
                                        </div>
                                    </div>
                                </div>
                                {/* /.card */}
                            </div>
                            {/* /.col */}
                        </div>

                        {/* Shedule Class Modal */}
                        <div className="modal fade" id="schedule-class" >
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Schedule Live Class</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                    </button>
                                    
                                </div>
                                <div className="modal-body">
                                    <form onSubmit={saveClass}>
                                        <label><span id="fullname"></span> (<span id="shortname"></span>)</label><hr></hr>
                                        <Row style={{marginBottom: "25px"}}>
                                            <Col xs={12} style={{paddingRight: "20px"}}>
                                                {/* Topic */}
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Topic</label>
                                                    <input 
                                                        type="topic" 
                                                        className="form-control" 
                                                        id="class_topic" 
                                                        placeholder="Enter topic" 
                                                        // value=""
                                                        // onChange={handleChange} 
                                                    />
                                                </div>
                                                
                                                <Row>
                                                    <Col md={6} xs={12}>
                                                        <div className="form-group">
                                                            <label>Date</label>
                                                            <ReactInputDateMask  
                                                                mask='dd/mm/yyyy' 
                                                                showMaskOnFocus={false}  
                                                                // className="form-control"
                                                                className = {"form-control date"}
                                                                name="startTime"
                                                                value="" 
                                                                // onChange={ handleDateChange } 
                                                                showMaskOnHover={true} 
                                                                placeholder="enter date"
                                                                // id={"startTime-"+i}
                                                            />
                                                        </div>
                                                    </Col>

                                                    <Col md={6} xs={12} style={{display: "flex"}}>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>From</label>
                                                                <div style={{display: "flex"}}>
                                                                    <select className = {"form-control select2bs4 fromHour"} style={{width: '100%'}} id={"fromHour"}>
                                                                        {hours.map((item, index) => (
                                                                            <option key={{index}}>{ item }</option>
                                                                        ))}
                                                                    </select>
                                                                    <select className = {"form-control select2bs4 fromMin"} style={{width: '100%'}} id={"fromMin"}>
                                                                        {secs.map((item, index) => (
                                                                            <option key={{index}}>{ item }</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>To</label>
                                                                <div style={{display: "flex"}}>
                                                                    <select className = {"form-control select2bs4 toHour"} style={{width: '100%'}} id={"toHour"}>
                                                                        {hours.map((item, index) => (
                                                                            <option key={{index}}>{ item }</option>
                                                                        ))}
                                                                    </select>
                                                                    <select className = {"form-control select2bs4 toMin"} style={{width: '100%'}} id={"toMin"}>
                                                                        {secs.map((item, index) => (
                                                                            <option key={{index}}>{ item }</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <p
                                                    style={{fontSize: "12px", float: "right"}}
                                                >
                                                    Time Zone: East Africa (GMT+03:00)
                                                </p>
                                            </Col>
                                        </Row>

                                        <button type="submit" class="btn btn-block bg-gradient-success btn-sm">Save</button>
                                    </form>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                    {/* <button type="button" className="btn btn-primary">Save changes</button> */}
                                </div>
                                </div>
                                {/* /.modal-content */}
                            </div>
                            {/* /.modal-dialog */}
                        </div>
                        {/* Shedule Class Modal */}

                        {/* View Sheduled Class Modal */}
                        <div className="modal fade" id="view-class-history">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">View Live Class History</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                    </button>
                                    
                                </div>
                                <div className="modal-body">
                                    <div className="card card-primary card-outline">
                                        <div className="card-header">
                                            <h3 className="card-title">List of Class History</h3>
                                        </div>
                                        {/* /.card-header */}
                                        <div className="card-body p-0">
                                            <div className="mailbox-controls">

                                                <div className="float-right">
                                                {class_history_from}-{class_history_to}/{class_history_total} 
                                                    <div className="btn-group"  style={{padding: "10px"}}>
                                                        <button type="button" className="btn btn-default btn-sm" onClick={() => handlePageChange(class_history_prev_page_url)}>
                                                            <i className="fas fa-chevron-left" />
                                                        </button>
                                                        <button type="button" className="btn btn-default btn-sm" onClick={() => handlePageChange(class_history_next_page_url)}>
                                                            <i className="fas fa-chevron-right" />
                                                        </button>
                                                    </div>
                                                    {/* /.btn-group */}
                                                </div>
                                                {/* /.float-right */}
                                            </div>
                                            <div className="table-responsive mailbox-messages">
                                                <table id="example1" className="table table-hover table-bordered table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>S/N</th>
                                                            <th>Topic</th>
                                                            <th>Date</th>
                                                            <th>From</th>
                                                            <th>To</th>
                                                            <th>Status</th>
                                                            <th>Join Class</th>
                                                            <th>Copy Link</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        
                                                        {class_history_data && class_history_data.map((class_data) => {
                                                            let status = "";
                                                            if(class_data.status === 1) {
                                                                status = <span className="badge bg-primary" style={{padding: "5px"}}>Not time yet</span>
                                                            }else if(class_data.status === 2) {
                                                                status = <span className="badge bg-success" style={{padding: "5px"}}>Class is on</span>
                                                            }else if(class_data.status === 3) {
                                                                status = <span className="badge bg-danger" style={{padding: "5px"}}>Class closed</span>
                                                            }

                                                            // if(class_data.status == 1){
                                                            //     scheduleClassStatus = "Active" 
                                                            // }else if(class_data.status == 2){
                                                            //     scheduleClassStatus = "Inactive" 
                                                            // }
                                                            
                                                            return(
                                                                <tr  
                                                                    style={{fontSize: "14px"}}
                                                                >
                                                                    <td className="mailbox-name">
                                                                        {++y}
                                                                    </td>
                                                                    <td className="mailbox-name">
                                                                        {class_data.class_topic}
                                                                    </td>
                                                                    <td className="mailbox-name">
                                                                        {class_data.class_date}
                                                                    </td>
                                                                    <td className="mailbox-name">
                                                                        {class_data.time_from}
                                                                    </td>
                                                                    <td className="mailbox-name">
                                                                        {class_data.time_to}
                                                                    </td>
                                                                    <td className="mailbox-name">
                                                                        {status}
                                                                    </td>
                                                                    <td className="mailbox-name">
                                                                        {
                                                                            // class_data.status == 2 ?
                                                                            <button 
                                                                                type="button" 
                                                                                class="btn btn-primary btn-block btn-sm" 
                                                                                onClick={() => joinClass(class_data.class_topic)}
                                                                            >
                                                                                <i class="fa fa-video"></i>
                                                                            </button> 
                                                                            // :
                                                                            // <button 
                                                                            //     type="button" 
                                                                            //     class="btn btn-primary btn-block btn-sm"
                                                                            //     disabled
                                                                            // >
                                                                            //     <i class="fa fa-video"></i>
                                                                            // </button>
                                                                        }
                                                                        
                                                                    </td>
                                                                    <td className="mailbox-name">
                                                                        <button type="button" class="btn btn-primary btn-block btn-sm" onClick={() => copyLink(class_data.class_topic)} >
                                                                            <i class="fa fa-copy"></i>
                                                                        </button> 
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                        <CopyToClipboard text={link} onCopy={() => setCopied(true)}>
                                                            <button type="button" id="copy_link" style={{display: "none"}}> </button> 
                                                        </CopyToClipboard>
                                                    </tbody>
                                                </table>
                                                {/* /.table */}
                                            </div>
                                            {/* /.mail-box-messages */}
                                        </div>
                                        {/* /.card-body */}

                                        <div className="card-footer p-0">
                                            <div className="mailbox-controls">
                                                <div className="float-right">
                                                {class_history_from}-{class_history_to}/{class_history_total}
                                                <div className="btn-group" style={{padding: "10px"}}>
                                                    <button type="button" className="btn btn-default btn-sm" onClick={() => handlePageChange(class_history_prev_page_url)}>
                                                    <i className="fas fa-chevron-left" />
                                                    </button>
                                                    <button type="button" className="btn btn-default btn-sm" onClick={() => handlePageChange(class_history_next_page_url)}>
                                                    <i className="fas fa-chevron-right" />
                                                    </button>
                                                </div>
                                                {/* /.btn-group */}
                                                </div>
                                                {/* /.float-right */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                    {/* <button type="button" className="btn btn-primary">Save changes</button> */}
                                </div>
                                </div>
                                {/* /.modal-content */}
                            </div>
                            {/* /.modal-dialog */}
                        </div>
                        {/* View Sheduled Class Modal */}

                    </section>

                </div>
            <Footer/>
        </div>
    )
}
