import React, { useEffect } from 'react';
import { useLocation, useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";

export const JoinLiveClass = () => {
    let history = useHistory();
    if(localStorage.getItem('role') == "student" || localStorage.getItem('role') == "teacher" || localStorage.getItem('role') == "manager" || localStorage.getItem('role') == "superadministrator"){
        
    }else {
        history.goBack();
    }
    
    useEffect(() => {
        const handler = event => {
            // close page if homepage is openned 
            if(event.data == 'it is homepage'){
                window.close();
            }
        }
    
        window.addEventListener("message", handler)
    
        // clean up
        return () => window.removeEventListener("message", handler)
    }, []) // empty array => run only once

    const search = useLocation().search;
    const topic  = new URLSearchParams(search).get('topic');
    const link  = "https://dish.brickpine.com/"+topic;
    // alert(topic)
    if(topic == "" || topic == null) {
        history.goBack();
    }

    return (
        <div className="hold-transition login-page">
            <iframe id="lms_iframe" src={link} frameBorder={0} style={{height: '100%', width: '100%', marginTop: '-21px'}} height="100%" width="100%" allow="camera *;microphone *">
            </iframe>
        </div>
    )
}
