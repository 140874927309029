import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetManagerDetails, GetCountries, UpdateManagerDetails } from "../../redux/actions";
import {Header} from '../../includes/Header';
import {Menu} from '../../includes/Menu';
import {Footer} from '../../includes/Footer';
import { AesEncrypt, AesDecrypt } from 'aes';
import { Success_Message, Error_Message } from "../../shared";
import { UploadProfilePicture, UploadFile } from "../../components";
import ReactInputDateMask from 'react-input-date-mask';
import { Row, Col, Button, ListGroupItem } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";

export const ManagerProfile = () => {
    let history = useHistory();
    if(localStorage.getItem('role') != "manager"){
        history.goBack();
    }
    
    let user_email = localStorage.getItem('user');
    let user_token = localStorage.getItem('token');
    let user_role  = localStorage.getItem('role');
    let res = {};
    let userDetails = [];
    const dispatch = useDispatch();
    const [showLoaderDiv, setShowLoaderDiv] = useState("none");
    const [loaderVisible, setLoaderVisible] = useState(false);

    // get details and pass into user
    // const Student   = useSelector((state) => state.Student);
    // const { user }  = Student;
    const General = useSelector((state) => state.General);
    const { countries }  = General;

    const [user_data, setUser] = useState([]);

    useEffect(async () => {
        setShowLoaderDiv("block"); setLoaderVisible(true);
        // get details
        userDetails = await dispatch(GetManagerDetails(user_email, user_token, user_role));
        await dispatch(GetCountries(user_token));
        setUser(userDetails.data)
        setShowLoaderDiv("none"); setLoaderVisible(false);
    }, []);

    // /////////////////////////////////////////////////////////
    function trigerProfileUpload(profile_picture){
        document.getElementById("profile_picture").click()
    }
    // /////////////////////////////////////////////////////

    const handleChange = async (e) => {
        const { name, value } = e.target;
        setUser(user_data => ({ ...user_data, [name]: value }));
    }

    const handleDateChange = async (e) => {
        setUser(user_data => ({ ...user_data, "dob": e }));
    }

    const submitPersonalDetails = async (e) => {
        e.preventDefault();
        
        if (
        
            user_data.first_name == "" || user_data.last_name == "" || user_data.telephone == "" || user_data.gender == "" || user_data.country_of_residence == "" || user_data.city == "" || user_data.address == "" || user_data.institution == "" || user_data.department == "" ||
            user_data.first_name == null || user_data.last_name == null || user_data.telephone == null || user_data.gender == null || user_data.country_of_residence == null || user_data.city == null || user_data.address == null || user_data.institution == null || user_data.department == null) 
        {
            Error_Message("Please fill all required fields")
        }else{
            setShowLoaderDiv("block"); setLoaderVisible(true);
            // const user_data1 = AesEncrypt(user_data, 'where do you go when you by yourself' );
            res = await dispatch(UpdateManagerDetails(user_data, user_email, user_token, user_role));
            setShowLoaderDiv("none"); setLoaderVisible(false);
            alertMessage(res);
        }
    }

    const alertMessage = async (res) => {
        if(res.status === "success"){ 
            Success_Message("Update successful") 
        }else{
            Error_Message(res.data)
        }
    }

    return (
        <div className="wrapper">
            <Header/>
            <Menu/>
                <div className="content-wrapper">
                    {/* // ///////////////////////////////// LOADER /////////////////////////////////////// */}
                    <div style={{position: "fixed", height:"100%", width:"100%", display: showLoaderDiv, zIndex: "1500"}}>
                        <div style={{position: "fixed", top:"50%", left:"46%",backgroundColor: "#ffffffcf",padding:"15px", borderRadius:"20px" }}>
                            <Loader
                                type="MutatingDots"
                                color="#183287"
                                secondaryColor="#ff9700"
                                height={100}
                                width={100}
                                visible={loaderVisible}
                                // timeout={3000} //3 secs
                            />
                            <img src="images/logo.png" alt="DISH Logo" className="brand-image elevation-3" style={{maxHeight: "40px", width: "auto", marginBottom: "20px"}} />
                            <h6 style={{color: "#183287"}}>Loading...</h6>
                        </div>
                    </div>
                    {/* // ///////////////////////////////// LOADER /////////////////////////////////////// */}
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Profile</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <Link to="/dashboard" style={{color: "#ff9700"}}>Home</Link>
                                </li>
                                <li className="breadcrumb-item active">Manager Profile</li>
                                </ol>
                            </div>
                            </div>
                        </div>{/* /.container-fluid */}
                    </section>

                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                {/* /.col */}
                                <div className="col-md-1"></div>
                                <div className="col-md-10">
                                    <form onSubmit={submitPersonalDetails}>
                                        <div className="card">
                                            <label style={{fontSize: "18px", marginLeft: "15px", marginTop: "10px"}}>Personal Details</label>

                                            <div className="card-body">
                                                <div className="tab-content">
                                                    <div className="active tab-pane" id="personal_details">
                                                        <Row style={{marginBottom: "25px"}}>
                                                            <Col md={5} xs={12}>
                                                                {/* Username */}
                                                                <div className="form-group row">
                                                                    <div className="input-group input-group-sm">
                                                                        <div className="input-group-prepend">
                                                                            <span className="input-group-text">
                                                                            <span className="asterisk">*&nbsp;</span>
                                                                            Username
                                                                            </span>
                                                                        </div>
                                                                        <input 
                                                                            type="text" 
                                                                            name="username" 
                                                                            className="form-control"
                                                                            defaultValue={user_data.username}
                                                                            onChange={handleChange} 
                                                                            disabled
                                                                        />
                                                                        <div className="input-group-append">
                                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* First name */}
                                                                <div className="form-group row">
                                                                    <div className="input-group input-group-sm">
                                                                        <div className="input-group-prepend">
                                                                            <span className="input-group-text">
                                                                            <span className="asterisk">*&nbsp;</span>
                                                                            First Name
                                                                            </span>
                                                                        </div>
                                                                        <input 
                                                                            type="text" 
                                                                            name="first_name" 
                                                                            className="form-control"
                                                                            defaultValue={user_data.first_name}
                                                                            onChange={handleChange} 
                                                                        />
                                                                        <div className="input-group-append">
                                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* Last name */}
                                                                <div className="form-group row">
                                                                    <div className="input-group input-group-sm">
                                                                        <div className="input-group-prepend">
                                                                            <span className="input-group-text">
                                                                            <span className="asterisk">*&nbsp;</span>
                                                                            Last Name
                                                                            </span>
                                                                        </div>
                                                                        <input 
                                                                            type="text" 
                                                                            name="last_name" 
                                                                            className="form-control"
                                                                            defaultValue={user_data.last_name}
                                                                            onChange={handleChange} 
                                                                        />
                                                                        <div className="input-group-append">
                                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* Middle name */}
                                                                <div className="form-group row">
                                                                    <div className="input-group input-group-sm">
                                                                        <div className="input-group-prepend">
                                                                            <span className="input-group-text">
                                                                            Middle Name
                                                                            </span>
                                                                        </div>
                                                                        <input 
                                                                            type="text" 
                                                                            name="middle_name" 
                                                                            className="form-control"
                                                                            defaultValue={user_data.middle_name}
                                                                            onChange={handleChange} 
                                                                        />
                                                                        <div className="input-group-append">
                                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* Email */}
                                                                <div className="form-group row">
                                                                    <div className="input-group input-group-sm">
                                                                        <div className="input-group-prepend">
                                                                            <span className="input-group-text">
                                                                            <span className="asterisk">*&nbsp;</span>
                                                                            Email
                                                                            </span>
                                                                        </div>
                                                                        <input 
                                                                            type="text" 
                                                                            name="email" 
                                                                            className="form-control"
                                                                            defaultValue={user_data.email}
                                                                            onChange={handleChange} 
                                                                            disabled
                                                                        />
                                                                        <div className="input-group-append">
                                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* Phone number */}
                                                                <div className="form-group row">
                                                                    <div className="input-group input-group-sm">
                                                                        <div className="input-group-prepend">
                                                                            <span className="input-group-text">
                                                                            <span className="asterisk">*&nbsp;</span>
                                                                            Phone Number
                                                                            </span>
                                                                        </div>
                                                                        <input 
                                                                            type="text" 
                                                                            name="telephone" 
                                                                            className="form-control"
                                                                            defaultValue={user_data.telephone}
                                                                            onChange={handleChange} 
                                                                        />
                                                                        <div className="input-group-append">
                                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* Gender */}
                                                                <div className="form-group row">
                                                                    <div className="input-group input-group-sm">
                                                                        <div className="input-group-prepend">
                                                                            <span className="input-group-text">
                                                                            <span className="asterisk">*&nbsp;</span>
                                                                            Gender
                                                                            </span>
                                                                        </div>
                                                                        <select className="form-control" name="gender" value={user_data.gender} onChange={handleChange}>
                                                                            <option value="select" >-- Select --</option>
                                                                            <option value="male" >Male</option>
                                                                            <option value="female" >Female</option>
                                                                            <option value="prefer_not_to_say">Prefer not to say</option>
                                                                            <option value="others" >Others</option>
                                                                        </select>
                                                                        <div className="input-group-append">
                                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={1}></Col>
                                                            <Col md={5} xs={12}>
                                                                {/* Country of Residence */}
                                                                <div className="form-group row">
                                                                    <div className="input-group input-group-sm">
                                                                        <div className="input-group-prepend">
                                                                            <span className="input-group-text">
                                                                            <span className="asterisk">*&nbsp;</span>
                                                                            Country
                                                                            </span>
                                                                        </div>
                                                                        <select className="form-control" name="country_of_residence" value={user_data.country_of_residence} onChange={handleChange}>
                                                                            {countries && countries.map((country) => (
                                                                                <option 
                                                                                    key={country.id}
                                                                                    value={country.code}
                                                                                >{country.name}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                        <div className="input-group-append">
                                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* City */}
                                                                <div className="form-group row">
                                                                    <div className="input-group input-group-sm">
                                                                        <div className="input-group-prepend">
                                                                            <span className="input-group-text">
                                                                            <span className="asterisk">*&nbsp;</span>
                                                                            City
                                                                            </span>
                                                                        </div>
                                                                        <input 
                                                                            type="text" 
                                                                            name="city"
                                                                            className="form-control"
                                                                            defaultValue={user_data.city}
                                                                            onChange={handleChange} 
                                                                        />
                                                                        <div className="input-group-append">
                                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            
                                                                {/* Address */}
                                                                <div className="form-group row">
                                                                    <div className="input-group input-group-sm">
                                                                        <div className="input-group-prepend">
                                                                            <span className="input-group-text">
                                                                            <span className="asterisk">*&nbsp;</span>
                                                                            Address
                                                                            </span>
                                                                        </div>
                                                                        <textarea 
                                                                            className="form-control" 
                                                                            rows={3} 
                                                                            name="address"
                                                                            value={user_data.address}
                                                                            onChange={handleChange} 
                                                                            placeholder="Enter ..." 
                                                                            // defaultValue={""} 
                                                                        />
                                                                        <div className="input-group-append">
                                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* Institution */}
                                                                <div className="form-group row">
                                                                    <div className="input-group input-group-sm">
                                                                        <div className="input-group-prepend">
                                                                            <span className="input-group-text">
                                                                            Institution
                                                                            </span>
                                                                        </div>
                                                                        <input 
                                                                            type="text" 
                                                                            name="institution"
                                                                            className="form-control"
                                                                            value={user_data.institution}
                                                                            onChange={handleChange} 
                                                                        />
                                                                        <div className="input-group-append">
                                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* Department */}
                                                                <div className="form-group row">
                                                                    <div className="input-group input-group-sm">
                                                                        <div className="input-group-prepend">
                                                                            <span className="input-group-text">
                                                                            Department
                                                                            </span>
                                                                        </div>
                                                                        <input 
                                                                            type="text" 
                                                                            name="department"
                                                                            className="form-control"
                                                                            value={user_data.department}
                                                                            onChange={handleChange} 
                                                                        />
                                                                        <div className="input-group-append">
                                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </div>{/* /.card-body */}
                                            <div className="card-footer">
                                                <Row>
                                                    <Col xs={11}>
                                                        <button type="submit" class="btn btn-sm bg-gradient-success dish-btn-primary">Update Personal Details</button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </form>
                                    {/* /.card */}
                                </div>
                                <div className="col-md-1"></div>
                                {/* /.col */}
                            </div>
                            {/* /.row */}
                        </div>{/* /.container-fluid */}
                    </section>
                </div>
            <Footer/>
        </div>
    )
}
