import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { UserSignin } from "../redux/actions/AuthActions";
import { UserForgetPassword } from "../../redux/actions";
import { AesEncrypt, AesDecrypt } from 'aes';
import { Success_Message, Error_Message } from "../../shared";
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";

export const ForgetPassword = () => {

    let history = useHistory();
    let res = {};
    const [showLoaderDiv, setShowLoaderDiv] = useState("none");
    const [loaderVisible, setLoaderVisible] = useState(false);

    const [user_data, setUser] = useState({
        email: '',
    });

    const [submitted, setSubmitted] = useState(false);
    const dispatch = useDispatch();

    function handleChange(e) {
        const { name, value } = e.target;
        setUser(user_data => ({ ...user_data, [name]: value }));
    }

    async function handleSubmit(e) {
        e.preventDefault();
        
        setSubmitted(true);
        if ( user_data.email ) {
            setShowLoaderDiv("block"); setLoaderVisible(true);
            const user_data1 = AesEncrypt(user_data, 'where do you go when you by yourself' );
            res = await dispatch(UserForgetPassword(user_data1));

            if(res.status === "success"){ 
                setShowLoaderDiv("none"); setLoaderVisible(false);
                Success_Message("Kindly check your email address")
            }else{
                setShowLoaderDiv("none"); setLoaderVisible(false);
                Error_Message("Email not sent. Please be sure your emaill address is correct.")
            }
        }
    }

    return (
        <div className="hold-transition login-page">
            <div className="login-box">
                <div className="login-logo">
                    {/* <a href="../../index2.html"><b>DISH</b></a> */}
                    <img src="images/logo.png" alt="DISH Logo" className="brand-image elevation-3" style={{height: "45px", width: "auto"}} />
                </div>
                {/* /.login-logo */}
                <div className="card">
                    <div className="card-body login-card-body">
                    
                        {/* // ///////////////////////////////// LOADER /////////////////////////////////////// */}
                        <div style={{position: "fixed", height:"100%", width:"100%", display: showLoaderDiv, zIndex: "1500"}}>
                            <div style={{position: "fixed", top:"50%", left:"46%",backgroundColor: "#ffffffcf",padding:"15px", borderRadius:"20px" }}>
                                <Loader
                                    type="MutatingDots"
                                    color="#183287"
                                    secondaryColor="#ff9700"
                                    height={100}
                                    width={100}
                                    visible={loaderVisible}
                                    // timeout={3000} //3 secs
                                />
                                <img src="images/logo.png" alt="DISH Logo" className="brand-image elevation-3" style={{maxHeight: "40px", width: "auto", marginBottom: "20px"}} />
                                <h6 style={{color: "#183287"}}>Loading...</h6>
                            </div>
                        </div>
                        {/* // ///////////////////////////////// LOADER /////////////////////////////////////// */}

                        <h3>Forget Password</h3>
                        <p>Enter your email address</p>
                        <form onSubmit={handleSubmit}>
                            <div className="input-group mb-3">
                                <input 
                                    type="email" 
                                    name="email" 
                                    placeholder="Email address" 
                                    value={user_data.email} 
                                    onChange={handleChange} 
                                    className={'form-control' + (submitted && !user_data.email ? ' is-invalid' : '')} 
                                />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                    <span className="fas fa-envelope" />
                                    </div>
                                </div>
                                {submitted && !user_data.email &&
                                    <div className="invalid-feedback">Email address is required</div>
                                }
                            </div>
                            <br></br>
                            <div className="row">
                                {/* /.col */}
                                {/* <div className="col-4"> */}
                                    <button type="submit" className="btn btn-primary btn-block dish-btn-primary">Forget Password</button>
                                {/* </div> */}
                                {/* /.col */}
                            </div>
                        </form><br></br>
                        <p className="mb-1" style={{float: "right"}}>
                            <Link to="/signin" style={{color: "#ff9700"}}>
                                Sign in
                            </Link>
                        </p>
                    </div>
                    {/* /.login-card-body */}
                </div>
                </div>

        </div>
    )
}
