export const ApiEndpoints = {
    GET_COUNTRIES: 'get/countries',
    GET_INSTITUTIONS: 'get/institutions',
    GET_STATISTICS: 'get/statistics',

    SIGN_UP: 'student/sign_up',
    SIGN_IN: 'student/sign_in',
    FORGET_PASSWORD: 'student/forget_password',
    CHECK_PASSWORD: 'student/check',
    RESET_PASSWORD: 'student/reset_password',
    GET_STUDENT_DETAILS: 'student/get',
    GET_ALL_STUDENT_DETAILS: 'all/student/details',
    UPDATE_FILE: 'update_file',
    UPDATE_DETAILS: 'update_details',
    GET_SUPPORT_DEPT: 'get/support/dept',
    SUBMIT_TICKET: 'submit/ticket',
    GET_TICKETS: 'get/tickets',
    GET_TICKETS_REPLY: 'get/tickets/reply',
    GET_TICKETS_REPLY_BY_ID: 'get/tickets/reply/by_id',

    SUBMIT_TICKET_REPLY: 'submit/ticket/reply',

    ADMIN_SIGN_IN: 'admin/sign_in',
    GET_ADMIN_DETAILS: 'admin/get',
    ADD_MANAGER: 'add/manager',
    GET_MANAGERS: 'get/managers',
    UPDATE_MANAGER_DETAILS: 'update/manager',
    GET_STUDENTS: 'get/students',
    GET_ALL_STUDENTS_DETAILS: 'get/all/students/details',
    GET_ALL_COURSE_DETAILS: 'get/all/courses/details',
    UPDATE_STUDENT_DETAILS: 'update/student',

    MANAGER_SIGN_IN: 'manager/sign_in',
    GET_MANAGER_DETAILS: 'manager/get',

    TEACHER_SIGN_IN: 'teacher/sign_in',
    GET_TEACHER_DETAILS: 'get/teacher',
    UPDATE_TEACHER_DETAILS: 'update/teacher',

    GET_COURSES: 'get/courses',
    SAVE_CLASS_SCHEDULE: 'save/class/schedule',
    GET_CLASS_HISTORY: 'get/class/history',
    GET_MY_COURSES: 'get/my_courses'
}

export const AppEndpoints = {
    
}

