import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { GetTickets } from "../../redux/actions";
import {Header} from '../../includes/Header';
import {Menu} from '../../includes/Menu';
import {Footer} from '../../includes/Footer';
import { AesEncrypt, AesDecrypt } from 'aes';
import { Success_Message, Error_Message } from "../../shared";
import { UploadProfilePicture, UploadFile } from "../../components";
import ReactInputDateMask from 'react-input-date-mask';
import { Row, Col, Button, ListGroupItem } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";

export const TicketsSent = () => {
    let history = useHistory();
    if(localStorage.getItem('role') != "student"){
        history.goBack();
    }
    
    let user_email = localStorage.getItem('user');
    let user_token = localStorage.getItem('token');
    let user_role  = localStorage.getItem('role');
    const [showLoaderDiv, setShowLoaderDiv] = useState("none");
    const [loaderVisible, setLoaderVisible] = useState(false);

    const dispatch = useDispatch();
    const Supports = useSelector((state) => state.Supports);
    const { tickets }   = Supports;
    const { from }      = Supports;
    const { to }        = Supports;
    const { total }     = Supports;
    const { prev_page } = Supports;
    const { next_page } = Supports;

    useEffect(async () => {
        setShowLoaderDiv("block"); setLoaderVisible(true);
        // get tickets the number 1 is the page number to fetch
        await dispatch(GetTickets(user_email, user_token, user_role, "1"));
        setShowLoaderDiv("none"); setLoaderVisible(false);
    }, []);
    
    const viewTicket = async (support_dept, support_subject, support_message, sender_email, created_at) => {
        document.getElementById("support_dept").innerHTML       = support_dept;
        document.getElementById("support_subject").innerHTML    = support_subject;
        document.getElementById("support_message").innerHTML    = support_message;
        document.getElementById("sender_email").innerHTML       = sender_email;
        document.getElementById("created_at").innerHTML         = created_at;
    }

    const handlePageChange = async (page_number) => {
        setShowLoaderDiv("block"); setLoaderVisible(true);
        await dispatch(GetTickets(user_email, user_token, user_role, page_number));
        setShowLoaderDiv("none"); setLoaderVisible(false);
    }

    return (
        <div className="wrapper">
            <Header/>
            <Menu/>
                <div className="content-wrapper">
                    {/* // ///////////////////////////////// LOADER /////////////////////////////////////// */}
                    <div style={{position: "fixed", height:"100%", width:"100%", display: showLoaderDiv, zIndex: "1500"}}>
                        <div style={{position: "fixed", top:"50%", left:"46%",backgroundColor: "#ffffffcf",padding:"15px", borderRadius:"20px" }}>
                            <Loader
                                type="MutatingDots"
                                color="#183287"
                                secondaryColor="#ff9700"
                                height={100}
                                width={100}
                                visible={loaderVisible}
                                // timeout={3000} //3 secs
                            />
                            <img src="images/logo.png" alt="DISH Logo" className="brand-image elevation-3" style={{maxHeight: "40px", width: "auto", marginBottom: "20px"}} />
                            <h6 style={{color: "#183287"}}>Loading...</h6>
                        </div>
                    </div>
                    {/* // ///////////////////////////////// LOADER /////////////////////////////////////// */}
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Tickets</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <Link to="/dashboard" style={{color: "#ff9700"}}>Home</Link>
                                </li>
                                <li className="breadcrumb-item active">Tickets</li>
                                </ol>
                            </div>
                            </div>
                        </div>{/* /.container-fluid */}
                    </section>

                    <section className="content">
                        <div className="row">
                            <div className="col-md-3">
                            <Link to="/add_ticket" className="btn btn-primary btn-block mb-3">Create Ticket</Link>
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Folders</h3>
                                    <div className="card-tools">
                                        <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                        <i className="fas fa-minus" />
                                        </button>
                                    </div>
                                </div>
                                <div className="card-body p-0">
                                    <ul className="nav nav-pills flex-column">
                                        <li className="nav-item active">
                                            <Link to="/tickets_reply" className="nav-link">
                                                <i className="fas fa-inbox" /> Reply
                                                {/* <span className="badge bg-primary float-right">12</span> */}
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/tickets_sent" className="nav-link">
                                                <i className="far fa-envelope" /> Sent
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                {/* /.card-body */}
                            </div>
                            
                            </div>
                            {/* /.col */}
                            <div className="col-md-9">
                                <div className="card card-primary card-outline">
                                    <div className="card-header">
                                    <h3 className="card-title">Sent</h3>
                                    {/* <div className="card-tools">
                                        <div className="input-group input-group-sm">
                                        <input type="text" className="form-control" placeholder="Search Mail" />
                                        <div className="input-group-append">
                                            <div className="btn btn-primary">
                                            <i className="fas fa-search" />
                                            </div>
                                        </div>
                                        </div>
                                    </div> */}
                                    {/* /.card-tools */}
                                    </div>
                                    {/* /.card-header */}
                                    <div className="card-body p-0">
                                    <div className="mailbox-controls">

                                        <div className="float-right">
                                        {from}-{to}/{total} 
                                            <div className="btn-group"  style={{padding: "10px"}}>
                                                <button type="button" className="btn btn-default btn-sm" onClick={() => handlePageChange(prev_page)}>
                                                    <i className="fas fa-chevron-left" />
                                                </button>
                                                <button type="button" className="btn btn-default btn-sm" onClick={() => handlePageChange(next_page)}>
                                                    <i className="fas fa-chevron-right" />
                                                </button>
                                            </div>
                                            {/* /.btn-group */}
                                        </div>
                                        {/* /.float-right */}
                                    </div>
                                    <div className="table-responsive mailbox-messages">
                                        <table className="table table-hover table-striped">
                                        <tbody>
                                            {tickets && tickets.map((ticket) => {
                                                if(ticket.created_at){
                                                    ticket.created_at = ticket.created_at.split('T')[0]
                                                }
                                                
                                                return(
                                                    <tr  
                                                        data-toggle="modal" 
                                                        data-target="#view-ticket" 
                                                        style={{cursor: "pointer"}} 
                                                        onClick={() => viewTicket(ticket.support_dept, ticket.support_subject, ticket.support_message, ticket.email, ticket.created_at)}
                                                    >
                                                        <td className="mailbox-name">{ticket.support_dept}</td>
                                                        <td className="mailbox-subject">
                                                            {ticket.support_subject}
                                                        </td>
                                                        <td style={{fontSize: "12px"}}>{ticket.support_message}</td>
                                                        <td className="mailbox-date">{ticket.created_at}</td>
                                                    </tr>
                                                )
                                            })}
                                            
                                        </tbody>
                                        </table>
                                        {/* /.table */}
                                    </div>
                                    {/* /.mail-box-messages */}
                                    </div>
                                    {/* /.card-body */}

                                    <div className="card-footer p-0">
                                        <div className="mailbox-controls">
                                            
                                            <div className="float-right">
                                            {from}-{to}/{total}
                                            <div className="btn-group" style={{padding: "10px"}}>
                                                <button type="button" className="btn btn-default btn-sm" onClick={() => handlePageChange(prev_page)}>
                                                <i className="fas fa-chevron-left" />
                                                </button>
                                                <button type="button" className="btn btn-default btn-sm" onClick={() => handlePageChange(next_page)}>
                                                <i className="fas fa-chevron-right" />
                                                </button>
                                            </div>
                                            {/* /.btn-group */}
                                            </div>
                                            {/* /.float-right */}
                                        </div>
                                    </div>
                                </div>
                                {/* /.card */}
                            </div>
                            {/* /.col */}
                        </div>

                        {/* View Ticket modal */}
                        <div className="modal fade" id="view-ticket">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="support_subject"></h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                    </button>
                                    
                                </div>
                                <div className="modal-body">
                                    <p id="support_message"></p>
                                    {/* <span style={{float: "left", bottom: "0", fontSize: "12px", color: "#183287"}} id="support_dept"></span>
                                    <span style={{float: "right", bottom: "0", fontSize: "12px"}} id="created_at"></span> */}
                                    <div className="row modal-body-bottom">
                                        <div className="col-md-4">
                                            <span style={{float: "left"}} id="support_dept"></span>
                                        </div>
                                        <div className="col-md-4">
                                            <span id="sender_email" style={{textAlign: "center"}}></span>
                                        </div>
                                        <div className="col-md-4">
                                            <span style={{float: "right"}} id="created_at"></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                    {/* <button type="button" className="btn btn-primary">Save changes</button> */}
                                </div>
                                </div>
                                {/* /.modal-content */}
                            </div>
                            {/* /.modal-dialog */}
                        </div>
                        {/* View Ticket modal */}

                    </section>

                </div>
            <Footer/>
        </div>
    )
}
