import { USER_DETAILS, GET_STATISTICS } from "../ActionTypes";

const INIT_VALUES = {
    user: [],
};

export const StudentReducer = (state = INIT_VALUES, action) => {
    
    switch (action.type) {
        case USER_DETAILS:
            return { ...state, user: action.payload.data };
        
        default:
            return state;
    }
}


