import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetManagers, GetCountries, GetInstitutions, UpdateManagerDetails } from "../../redux/actions";
import {Header} from '../../includes/Header';
import {Menu} from '../../includes/Menu';
import {Footer} from '../../includes/Footer';
import { AesEncrypt, AesDecrypt } from 'aes';
import { Success_Message, Error_Message } from "../../shared";
import { UploadProfilePicture, UploadFile } from "../../components";
import ReactInputDateMask from 'react-input-date-mask';
import { Row, Col, Button, ListGroupItem } from 'react-bootstrap';
import Loader from "react-loader-spinner";

export const ViewManagers = () => {
    let history = useHistory();
    if(localStorage.getItem('role') != "superadministrator"){
        history.goBack();
    }
    
    let user_email = localStorage.getItem('user');
    let user_token = localStorage.getItem('token');
    let user_role  = localStorage.getItem('role');
    let res = {};
    let x = 0;
    const [showLoaderDiv, setShowLoaderDiv] = useState("none");
    const [loaderVisible, setLoaderVisible] = useState(false);

    const dispatch = useDispatch();
    const Admin = useSelector((state) => state.Admin);

    const General = useSelector((state) => state.General);
    const { countries }     = General;
    const { institutions }  = General;

    const { managers }   = Admin;
    const { from }      = Admin;
    const { to }        = Admin;
    const { total }     = Admin;
    const { prev_page } = Admin;
    const { next_page } = Admin;

    const [manager_data, setManager] = useState({
        first_name: '',
        last_name: '',
        email: '',
        institution: '',
        zip_code: '',
        telephone: '',
        gender: '',
        dob: '',
        nationality: '',
        country_of_residence: '',
        district_province_state: '',
        address: ''
    });

    useEffect(async () => {
        setShowLoaderDiv("block"); setLoaderVisible(true);
        // get managers the number 1 is the page number to fetch
        await dispatch(GetManagers(user_email, user_token, user_role, "1"));
        await dispatch(GetCountries(user_token));
        await dispatch(GetInstitutions(user_token));
        setShowLoaderDiv("none"); setLoaderVisible(false);
    }, []);
    
    const viewManager = async (first_name, last_name, email, institution, zip_code, telephone, gender, nationality, country_of_residence, district_province_state, address, created_at) => {
        setManager(manager_data => ({ ...manager_data, first_name: first_name }));
        setManager(manager_data => ({ ...manager_data, last_name: last_name }));
        setManager(manager_data => ({ ...manager_data, email: email }));
        setManager(manager_data => ({ ...manager_data, institution: institution }));
        setManager(manager_data => ({ ...manager_data, zip_code: zip_code }));
        setManager(manager_data => ({ ...manager_data, telephone: telephone }));
        setManager(manager_data => ({ ...manager_data, gender: gender }));
        setManager(manager_data => ({ ...manager_data, nationality: nationality }));
        setManager(manager_data => ({ ...manager_data, country_of_residence: country_of_residence }));
        setManager(manager_data => ({ ...manager_data, district_province_state: district_province_state }));
        setManager(manager_data => ({ ...manager_data, address: address }));
    }

    const handleChange = async (e) => {
        const { name, value } = e.target;
        setManager(manager_data => ({ ...manager_data, [name]: value }));
    }

    const UpdateDetails = async (e) => {
        e.preventDefault();
        if (
            manager_data.first_name == "" || manager_data.last_name == "" ||  manager_data.institution == "select" ||
            manager_data.first_name == null || manager_data.last_name == null || manager_data.institution == null) 
        {
            Error_Message("Please fill all required fields")
        }else{
            setShowLoaderDiv("block"); setLoaderVisible(true);
            res = await dispatch(UpdateManagerDetails(manager_data, user_email, user_token, user_role));
            setShowLoaderDiv("none"); setLoaderVisible(false);
            alertMessage(res);
            // history.push('/add_manager')
            await dispatch(GetManagers(user_email, user_token, user_role, "1"));
        }
    }

    const alertMessage = async (res) => {
        if(res.status === "success"){ 
            Success_Message("Update successful") 
        }else{
            Error_Message(res.data)
        }
    }

    const handlePageChange = async (page_number) => {
        setShowLoaderDiv("block"); setLoaderVisible(true);
        await dispatch(GetManagers(user_email, user_token, user_role, page_number));
        setShowLoaderDiv("none"); setLoaderVisible(false);
    }

    return (
        <div className="wrapper">
            <Header/>
            <Menu/>
                <div className="content-wrapper">
                    {/* // ///////////////////////////////// LOADER /////////////////////////////////////// */}
                    <div style={{position: "fixed", height:"100%", width:"100%", display: showLoaderDiv, zIndex: "1500"}}>
                        <div style={{position: "fixed", top:"50%", left:"46%",backgroundColor: "#ffffffcf",padding:"15px", borderRadius:"20px" }}>
                            <Loader
                                type="MutatingDots"
                                color="#183287"
                                secondaryColor="#ff9700"
                                height={100}
                                width={100}
                                visible={loaderVisible}
                                // timeout={3000} //3 secs
                            />
                            <img src="images/logo.png" alt="DISH Logo" className="brand-image elevation-3" style={{maxHeight: "40px", width: "auto", marginBottom: "20px"}} />
                            <h6 style={{color: "#183287"}}>Loading...</h6>
                        </div>
                    </div>
                    {/* // ///////////////////////////////// LOADER /////////////////////////////////////// */}
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Managers</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <Link to="/dashboard" style={{color: "#ff9700"}}>Home</Link>
                                </li>
                                <li className="breadcrumb-item active">Managers</li>
                                </ol>
                            </div>
                            </div>
                        </div>{/* /.container-fluid */}
                    </section>

                    <section className="content">
                        <div className="row">
                            {/* /.col */}
                            <div className="col-md-12">
                                <div className="card card-primary card-outline">
                                    <div className="card-header">
                                        <h3 className="card-title">List of Managers</h3>
                                    </div>
                                    {/* /.card-header */}
                                    <div className="card-body p-0">
                                        <div className="mailbox-controls">

                                            <div className="float-right">
                                            {from}-{to}/{total} 
                                                <div className="btn-group"  style={{padding: "10px"}}>
                                                    <button type="button" className="btn btn-default btn-sm" onClick={() => handlePageChange(prev_page)}>
                                                        <i className="fas fa-chevron-left" />
                                                    </button>
                                                    <button type="button" className="btn btn-default btn-sm" onClick={() => handlePageChange(next_page)}>
                                                        <i className="fas fa-chevron-right" />
                                                    </button>
                                                </div>
                                                {/* /.btn-group */}
                                            </div>
                                            {/* /.float-right */}
                                        </div>
                                        <div className="table-responsive mailbox-messages">
                                            <table id="example1" className="table table-hover table-bordered table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>S/N</th>
                                                        <th>First Name</th>
                                                        <th>Last Name</th>
                                                        <th>Email</th>
                                                        <th>Institution</th>
                                                        <th>Date Added</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    
                                                    {managers && managers.map((manager) => {
                                                        if(manager.created_at){
                                                            manager.created_at = manager.created_at.split('T')[0]
                                                        }
                                                        
                                                        return(
                                                            <tr  
                                                                data-toggle="modal" 
                                                                data-target="#view-manager" 
                                                                style={{cursor: "pointer", fontSize: "14px"}} 
                                                                onClick={() => viewManager(manager.first_name, manager.last_name, manager.email, manager.institution, manager.zip_code, manager.telephone, manager.gender, manager.nationality, manager.country_of_residence, manager.district_province_state, manager.address, manager.created_at)}
                                                            >
                                                                <td className="mailbox-name">{++x}</td>
                                                                <td className="mailbox-name">{manager.first_name}</td>
                                                                <td className="mailbox-name">
                                                                    {manager.last_name}
                                                                </td>
                                                                <td className="mailbox-name">
                                                                    {manager.email}
                                                                </td>
                                                                <td className="mailbox-name">
                                                                    {manager.institution}
                                                                </td>
                                                                <td className="mailbox-date">{manager.created_at}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                    
                                                </tbody>
                                            </table>
                                            {/* /.table */}
                                        </div>
                                        {/* /.mail-box-messages */}
                                    </div>
                                    {/* /.card-body */}

                                    <div className="card-footer p-0">
                                        <div className="mailbox-controls">
                                            <div className="float-right">
                                            {from}-{to}/{total}
                                            <div className="btn-group" style={{padding: "10px"}}>
                                                <button type="button" className="btn btn-default btn-sm" onClick={() => handlePageChange(prev_page)}>
                                                <i className="fas fa-chevron-left" />
                                                </button>
                                                <button type="button" className="btn btn-default btn-sm" onClick={() => handlePageChange(next_page)}>
                                                <i className="fas fa-chevron-right" />
                                                </button>
                                            </div>
                                            {/* /.btn-group */}
                                            </div>
                                            {/* /.float-right */}
                                        </div>
                                    </div>
                                </div>
                                {/* /.card */}
                            </div>
                            {/* /.col */}
                        </div>

                        {/* View Manager modal */}
                        <div className="modal fade" id="view-manager">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                    </button>
                                    
                                </div>
                                <div className="modal-body">
                                    <form onSubmit={UpdateDetails}>
                                        <Row style={{marginBottom: "25px"}}>
                                            <Col xs={12}>
                                                {/* First name */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            <span className="asterisk">*&nbsp;</span>
                                                            First Name
                                                            </span>
                                                        </div>
                                                        <input 
                                                            type="text" 
                                                            name="first_name" 
                                                            className="form-control"
                                                            value={manager_data.first_name} 
                                                            onChange={handleChange} 
                                                        />
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Last name */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            <span className="asterisk">*&nbsp;</span>
                                                            Last Name
                                                            </span>
                                                        </div>
                                                        <input 
                                                            type="text" 
                                                            name="last_name" 
                                                            className="form-control"
                                                            value={manager_data.last_name} 
                                                            onChange={handleChange} 
                                                        />
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Email */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            <span className="asterisk">*&nbsp;</span>
                                                            Email
                                                            </span>
                                                        </div>
                                                        <input 
                                                            type="email" 
                                                            name="email" 
                                                            className="form-control"
                                                            value={manager_data.email} 
                                                            onChange={handleChange} 
                                                            disabled
                                                        />
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Institution */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            <span className="asterisk">*&nbsp;</span>
                                                            Institution
                                                            </span>
                                                        </div>
                                                        <select 
                                                            className="form-control" 
                                                            value={manager_data.institution}
                                                            name="institution" 
                                                            onChange={handleChange} 
                                                        >
                                                            <option value="select">Select</option>
                                                            {institutions && institutions.map((institution) => (
                                                                <option 
                                                                    key={institution.id}
                                                                    value={institution.name}
                                                                >{institution.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Phone number */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            Phone Number
                                                            </span>
                                                        </div>
                                                        <select 
                                                            className="form-control" 
                                                            value={manager_data.zip_code} 
                                                            name="zip_code" 
                                                            onChange={handleChange} 
                                                        >
                                                            <option value="select">Select</option>
                                                            {countries && countries.map((country) => (
                                                                <option 
                                                                    key={country.id}
                                                                    value={country.zip_code}
                                                                >{country.code} {country.zip_code}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <input 
                                                            type="text" 
                                                            name="telephone" 
                                                            className="form-control"
                                                            value={manager_data.telephone}
                                                            onChange={handleChange} 
                                                        />
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Gender */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            Gender
                                                            </span>
                                                        </div>
                                                        <select 
                                                            className="form-control" 
                                                            name="gender" 
                                                            value={manager_data.gender}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="select" >-- Select --</option>
                                                            <option value="male" >Male</option>
                                                            <option value="female" >Female</option>
                                                            <option value="prefer_not_to_say">Prefer not to say</option>
                                                            <option value="others" >Others</option>
                                                        </select>
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Nationality */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            Nationality
                                                            </span>
                                                        </div>
                                                        <select 
                                                            className="form-control" 
                                                            value={manager_data.nationality}
                                                            name="nationality" 
                                                            onChange={handleChange} 
                                                        >
                                                            {countries && countries.map((country) => (
                                                                <option 
                                                                    key={country.id}
                                                                    value={country.name}
                                                                >{country.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Country of Residence */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            Country of Residence
                                                            </span>
                                                        </div>
                                                        <select 
                                                            className="form-control" name="country_of_residence" 
                                                            value={manager_data.country_of_residence} 
                                                            onChange={handleChange}
                                                        >
                                                            {countries && countries.map((country) => (
                                                                <option 
                                                                    key={country.id}
                                                                    value={country.name}
                                                                >{country.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* District Province State */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            District Province State
                                                            </span>
                                                        </div>
                                                        <input 
                                                            type="text" 
                                                            name="district_province_state"
                                                            className="form-control"
                                                            value={manager_data.district_province_state}
                                                            onChange={handleChange} 
                                                        />
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                                {/* Address */}
                                                <div className="form-group row">
                                                    <div className="input-group input-group-sm">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                            Address
                                                            </span>
                                                        </div>
                                                        <textarea 
                                                            className="form-control" 
                                                            rows={3} 
                                                            name="address"
                                                            value={manager_data.address}
                                                            onChange={handleChange} 
                                                            placeholder="Enter ..." 
                                                            // defaultValue={""} 
                                                        />
                                                        <div className="input-group-append">
                                                            <div className="input-group-text"><i className="fas fa-user" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <button type="submit" class="btn btn-block bg-gradient-success btn-sm">Update Manager</button>
                                    </form>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                    {/* <button type="button" className="btn btn-primary">Save changes</button> */}
                                </div>
                                </div>
                                {/* /.modal-content */}
                            </div>
                            {/* /.modal-dialog */}
                        </div>
                        {/* View Manager modal */}

                    </section>

                </div>
            <Footer/>
        </div>
    )
}
