import { ApiEndpoints, SERVER_REQUEST, INSERT_UPDATE_OR_DELETE_REQUEST } from "../../shared";
import { GET_TEACHER_DETAILS, UPDATE_TEACHER_DETAILS, GET_STUDENTS, UPDATE_STUDENT_DETAILS } from "../ActionTypes";

export const GetTeacherDetails = (user_email, user_token, user_role) => async (dispatch, getState) => {
    const response = await SERVER_REQUEST(ApiEndpoints.GET_TEACHER_DETAILS+"/"+user_email+"/"+user_role, 'get', { user_email: user_email, user_token: user_token }, 'json' );

    await dispatch({
        type: GET_TEACHER_DETAILS,
        payload: response.data.data,
    });
    
    if (response.status === 200) {
        return { status: "success", data: response.data.data };
    } else {
        return { status: "error", data: "An error occured" };
    }
}

export const UpdateTeacherDetails = (user_data, user_email, user_token, user_role) => async (dispatch, getState) => {
    const response = await INSERT_UPDATE_OR_DELETE_REQUEST(ApiEndpoints.UPDATE_TEACHER_DETAILS+"/"+user_email+"/"+user_role, 'put', { user_email: user_email, user_token: user_token, user_data: user_data }, 'json' );

    await dispatch({
        type: UPDATE_TEACHER_DETAILS,
        payload: response.data,
    });
    
    if (response.status === 201) {
        return { status: "success", data: response.data };
    } else {
        return { status: "error", data: "An error occured" };
    }
}
