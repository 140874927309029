import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from 'redux-thunk';
import { TodoReducer, AuthReducer, StudentReducer, GeneralReducer, SupportReducer, AdminReducer, ManagerReducer, CourseReducer, TeacherReducer } from "./reducers";


const reducer = combineReducers({
    // this contains all reducers
    Todo: TodoReducer,
    Auth: AuthReducer,
    Student: StudentReducer,
    General: GeneralReducer,
    Supports: SupportReducer,
    Admin: AdminReducer,
    Manager: ManagerReducer,
    Course: CourseReducer,
    Teacher: TeacherReducer,
});

const initialState = {
    
};

const middleware = [thunk];

const store = createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
);

export default store;  